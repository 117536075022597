import React from 'react';
import { sessionToTokenAuth } from '../Network';

export default function useEnsureAuth() {
  const [authLoaded, setAuthLoaded] = React.useState(sessionStorage.getItem('auth'));
  const [user, setUser] = React.useState(JSON.parse(sessionStorage.getItem('user')));

  React.useEffect(() => {
    if (!authLoaded) {
      sessionToTokenAuth({
        onSuccess: () => {
          setAuthLoaded(true);
        }
      });
    }
  }, []);

  return { authLoaded, user };
}