import React from 'react';
import {
  getPdfDocuments,
  getPdfDocument,
  createPdfDocument,
  updatePdfDocument,
  testPdfDocument
} from '../shared/Network';

export default class PdfDocumentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfDocuments: [],
      pdfDocument: null,
      errors: [],
      params: {
        id: '',
        name: '',
        url: '',
        mlsOrgId: '86b3f50dc61748c3c538a2c4',
        primary: true,
        fileName: '',
        file: null,
        allowSearch: false,
        order: 0
      },
      testPdf: '#',
      showForm: false
    };
  }

  componentDidMount() {
    this.getPdfDocuments();
  }

  getPdfDocuments = (cb = null) => {
    getPdfDocuments().then((response) => {
      response.json().then((json) => {
        if (json['errors'] != null) {
          this.setState({ errors: json['errors'] });
        } else {
          this.setState({ pdfDocuments: json }, cb);
        }
      });
    });
  };

  updatePdfDocumentState = (key, value) => {
    let updateParams = Object.assign({}, this.state.params);
    updateParams[key] = value;
    this.setState({ params: updateParams });
  };

  clearParamState = () => {
    let params = {
      id: '',
      name: '',
      url: '',
      primary: true,
      allowSearch: false,
      mlsOrgId: '86b3f50dc61748c3c538a2c4',
      fileName: '',
      order: 0,
      file: null,
      testPdf: '#'
    };
    this.setState({ pdfDocument: null, params: params });
  };

  savePdfDocument = () => {
    let params = Object.assign({}, this.state.params);

    let data = new FormData();

    data.append('pdf_document[name]', params.name);
    if (this.state.params.file != null) {
      data.append('pdf_document[pdf_file]', params.file);
    }
    data.append('pdf_document[mls_org_id]', params.mlsOrgId);
    data.append('pdf_document[primary_document]', params.primary);
    data.append('pdf_document[allow_search]', params.allowSearch);
    data.append('pdf_document[order]', params.order);

    if (params.id === '') {
      console.log('Creating PdfDocument');
      createPdfDocument(data).then((response) => {
        response.json().then((json) => {
          if (json['errors']) {
            console.log('Error creating PdfDocument: ' + json['errors']);
          } else {
            console.log(json);
            this.getPdfDocuments(() => {
              this.setPdfDocument(json['id']);
            });
          }
        });
      });
    } else {
      console.log('Updating PdfDocument');
      updatePdfDocument(this.state.pdfDocument.external_id, data).then(
        (response) => {
          response.json().then((json) => {
            if (json['errors']) {
              console.log('Error saving PdfDocument: ' + json['errors']);
            } else {
              console.log(json);
              this.getPdfDocuments(() => {
                this.setPdfDocument(json['id']);
              });
            }
          });
        }
      );
    }
  };

  changePdfDocument = (e) => {
    let value = e.target.value;
    if (value === 'new') {
      this.clearParamState();
      this.props.setPdfDocument(null);
      console.log('going to create new ');
    } else {
      this.setPdfDocument(e.target.value);
    }
    this.setState({ showForm: true });
  };

  setPdfDocument(id) {
    let idx = this.state.pdfDocuments.findIndex((p) => p.external_id === id);
    let pdfDocument = Object.assign({}, this.state.pdfDocuments[idx]);
    this.setState({
      pdfDocument: pdfDocument,
      params: {
        id: pdfDocument.id,
        external_id: pdfDocument.external_id,
        mlsOrgId: pdfDocument['mls_org_id'],
        name: pdfDocument.name,
        url: pdfDocument.url,
        file: null,
        fileName: '',
        primary: pdfDocument['primary_document'],
        allowSearch: pdfDocument['allow_search'],
        order: pdfDocument['order']
      },
      testPdf: '#'
    });
    this.props.setPdfDocument(pdfDocument);
  }

  setFile = (e) => {
    let params = Object.assign({}, this.state.params);
    params.file = e.target.files[0];
    params.fileName = e.target.value;
    this.setState({ params: params });
  };

  documentForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input type="hidden" value={this.state.params.external_id} />
        <input
          type="hidden"
          name="mlsOrgId"
          value={this.state.params.mlsOrgId}
        />
        <label>PDF File:</label>
        <br />
        <input
          type="file"
          onChange={this.setFile}
          value={this.state.params.fileName}
        />
        <br />
        <label htmlFor="name">Name</label>
        <br />
        <input
          type="text"
          name="name"
          value={this.state.params.name}
          onChange={(e) => {
            this.updatePdfDocumentState('name', e.target.value);
          }}
        />
        <br />
        <label htmlFor="url">URL</label>
        <br />
        <input
          type="text"
          name="url"
          value={this.state.params.url}
          onChange={(e) => {
            this.updatePdfDocumentState('url', e.target.value);
          }}
        />
        <br />
        <label htmlFor="primary">Primary Document</label>
        <input
          type="checkbox"
          checked={this.state.params.primary}
          onChange={(e) => {
            this.updatePdfDocumentState('primary', e.target.checked);
          }}
        />
        <br />
        <label htmlFor="allowSearch">Allow Search</label>
        <input
          type="checkbox"
          checked={this.state.params.allowSearch}
          onChange={(e) => {
            this.updatePdfDocumentState('allowSearch', e.target.checked);
          }}
        />
        <br />
        <label htmlFor="order">Document Order</label>
        <input
          type="number"
          value={this.state.params.order || '0'}
          onChange={(e) => {
            this.updatePdfDocumentState('order', e.target.value);
          }}
        />
        <br />
        {this.state.params.id !== '' ? (
          <div>
            <button
              onClick={() => {
                this.setState({ testPdf: '#' }, () => {
                  testPdfDocument(this.state.params.external_id).then(
                    (response) => {
                      response.json().then((json) => {
                        this.setState({ testPdf: json.url });
                      });
                    }
                  );
                });
              }}
            >
              Generate PDF
            </button>
            <p>
              {this.state.testPdf !== '#' ? (
                <a href={this.state.testPdf}>Test Pdf</a>
              ) : (
                ' '
              )}
            </p>
          </div>
        ) : null}
        <button onClick={this.savePdfDocument}>Save</button>
        <button onClick={() => this.setState({ showForm: false })}>
          Cancel
        </button>
      </form>
    );
  };

  getDocumentOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a document ---
      </option>
    );
    this.state.pdfDocuments.forEach((doc) => {
      options.push(
        <option key={doc.external_id} value={doc.external_id}>
          {doc.name}
        </option>
      );
    });
    options.push(
      <option key="new" value="new">
        + Add New Pdf Document
      </option>
    );
    return options;
  };

  render() {
    return (
      <div>
        {this.state.errors.forEach((e) => (
          <div>{e}</div>
        ))}
        <select
          onChange={this.changePdfDocument}
          value={
            this.state.pdfDocument ? this.state.pdfDocument.external_id : ''
          }
        >
          {this.getDocumentOptions()}
        </select>
        {this.state.showForm ? this.documentForm() : ''}
      </div>
    );
  }
}
