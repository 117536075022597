import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['validationRule', 'defaultValue', 'form'];
  validationRuleTargetConnected(target) {
    if (target.dataset.settingsTarget === 'validationRule') {
      let form = target.querySelector('form');
      target
        .querySelector('input[type=checkbox]')
        .addEventListener('change', (evt) => {
          Rails.fire(form, 'submit');
        });
    }
  }

  defaultValueTargetConnected(target) {
    if (target.dataset.settingsTarget === 'defaultValue') {
      let form = target.querySelector('form');

      // disable regular submit
      form.addEventListener('submit', (e) => {
        if (e.detail && e.detail.dataChange) {
          return true;
        } else {
          e.preventDefault();
          return false;
        }
      });

      let textField = target.querySelector('input[type=text]');
      let selectField = target.querySelector('select');

      // find text fields, submit form on change
      if (textField) {
        textField.addEventListener('change', (evt) => {
          Rails.fire(form, 'submit', { dataChange: true });
        });
      }

      // find radio fields, submit form on change
      if (selectField) {
        selectField.addEventListener('change', (evt) => {
          Rails.fire(form, 'submit', { dataChange: true });
        });
      }
    }
  }
}
