import { put, call, all } from 'redux-saga/effects';
import { getHeaders, getApiUrl, parseErrors } from '../../../shared/Network';
import {
  signatureDownloaded,
  signatureDownloadRequested,
  signaturesFetched,
  signaturesFetchFailed
} from '../actions/signatureActions';
import { INTERNAL_ERROR } from '../../../shared/constants';
import { callApi } from './shared';

export function* getSignatures(action) {
  try {
    let url = getApiUrl() + '/signatures';

    const headers = yield call(getHeaders);
    const response = yield call(callApi, url, 'GET', headers, null, action);

    if (!response) {
      console.error(
        '[signatureSaga] getSignatures, null response',
        url,
        action
      );
      return;
    }

    const responseJson = yield response.json();
    let errors = parseErrors(responseJson);

    if (response.status >= 200 && response.status <= 299) {
      if (errors.length === 0) {
        let signatureUrl;
        let initialsUrl;
        responseJson.forEach((signature) => {
          if (signature.signature_type === 'initials') {
            initialsUrl = signature.signature.url;
          } else {
            signatureUrl = signature.signature.url;
          }
        });

        yield all([
          put(signatureDownloadRequested('signature', signatureUrl)),
          put(signatureDownloadRequested('initials', initialsUrl))
        ]);
      } else {
        yield put(signaturesFetchFailed(errors));
      }
    } else {
      yield put(signaturesFetchFailed(errors));
    }
  } catch (e) {
    console.log('Error getting PdfDocument: ' + e.message);
    yield put(signaturesFetchFailed([INTERNAL_ERROR]));
  }
}

export function* downloadSignature(action) {
  const { signatureType, url } = action.payload;
  const response = yield fetch(url);

  const blob = yield response.blob();
  yield put(signatureDownloaded(signatureType, blob));
}
