import { Box, Button, Card, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagIcon from '@mui/icons-material/Flag';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import { useNavigate, useParams } from 'react-router-dom';
import useClientAppStore from '../../shared/stores/ClientAppStore';
import PageLoader from '../../shared/components/PageLoader';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {Math.round(props.value) >= 100 ? <CheckCircleIcon sx={{ fontSize: '26px' }} color="primary" /> : `${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function Document() {
  const { documentId } = useParams();
  const { currentTransaction, currentDocument, setCurrentDocument } = useClientAppStore();
  const navigate = useNavigate();

  useEffect(() => {
    Request.get(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}`, (data) => {
      setCurrentDocument(data);
    }
  )}, [documentId])

  if(!currentDocument) {
    return <PageLoader />
  }

  return (
    <Stack spacing={2} mb={10}>
      <Stack>
        <Typography variant="h6">{currentTransaction.name}</Typography>
        <Typography variant="h4">{currentDocument.name}</Typography>
      </Stack>
      <Card>
        <Stack p={2} spacing={2} divider={<Divider />}>
          {currentDocument.pdf_sections.map((section, index) => (
            <Stack key={section.id} direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="h6" m={0}>{section.title}</Typography>
                <Stack direction="row" spacing={2}>
                  <Typography>{section.description}</Typography>
                  <Stack direction="row" spacing={0.5}>
                    <CircularProgress sx={{ my: 'auto !important' }} size="12px" thickness={8} variant='determinate' value={section.completed_fields / section.total_fields * 100} />
                    <Typography>{section.completed_fields}/{section.total_fields} questions answered</Typography>
                  </Stack>
                  {section.flagged_fields > 0 &&
                  <Stack direction="row" spacing={0.5}>
                    <FlagIcon sx={{ my: 'auto !important' }} color="warning" />
                    <Typography>{section.flagged_fields} questions flagged</Typography>
                  </Stack>
                  }
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box>
                  <Button onClick={() => navigate(`${section.id}`)} variant={section.completed_fields >= section.total_fields ? 'outlined' : 'contained'} color="primary">
                    {section.completed_fields > 0 ? section.completed_fields >= section.total_fields ? "Review" : "Continue" : "Begin"}
                  </Button>
                </Box>
                <Stack display={{ xs: 'none', sm: 'flex' }} direction="row" spacing={2}>
                  <CircularProgressWithLabel value={section.completed_fields / section.total_fields * 100} />
                </Stack>
              </Stack>
            </Stack>
          ))}
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography variant="h6" m={0}>Review and Sign</Typography>
              <Typography>Review your answers before sending to your agent</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" onClick={() => navigate('review')}>
                Review and Sign
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  )
}