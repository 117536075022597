import React from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import Pdf from './Pdf';
import FieldForm from './FieldForm';
import SectionForm from './SectionForm';
import PdfDocumentForm from './PdfDocumentForm';
import {
  newTextField,
  newSignatureField,
  updateCoord,
  newDateTimeField,
  newRadioField,
  newCompositeField,
  newCheckboxField
} from './Util';
import {
  getPdfDocument,
  getPdfFieldsForPdfDocument,
  deletePdfFieldFromPdfDocument
} from '../shared/Network';
import { store } from './redux/store';
import { Provider } from 'react-redux';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default class Viewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfDocument: null,
      pdf: null,
      field: null,
      fields: [],
      sections: [],
      loading: false
    };

    this.count = 0;
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  loadPdfFile = () => {
    //TODO Remove this when test documents go away
    if (this.state.pdfDocument.url.endsWith('pdf')) {
      pdfjsLib.getDocument(this.state.pdfDocument.url).then((doc) => {
        console.log('HTTP PDF loaded');
        this.setState({ pdf: doc }, () => this.loadPdfFields());
      });
    } else {
      getPdfDocument(this.state.pdfDocument.external_id).then((response) => {
        response.blob().then((data) => {
          data.arrayBuffer().then((arr) => {
            pdfjsLib.getDocument({ data: arr }).promise.then((doc) => {
              console.log('RAW PDF loaded');
              this.setState({ pdf: doc }, () => this.loadPdfFields());
            });
          });
        });
      });
    }
  };

  loadPdfFields = (id = null) => {
    getPdfFieldsForPdfDocument(this.state.pdfDocument.external_id).then(
      (response) => {
        response.json().then((json) => {
          let cb = null;
          if (id != null) {
            cb = () => this.setField(id);
          }
          this.setFields(json, cb);
        });
      }
    );
  };

  save = (fieldHash) => {
    // let fields = Object.assign([], this.state.fields);
    //
    // let idx = fields.findIndex((f) => { return f.id === fieldHash.id });
    //
    // let currentFieldCopy = Object.assign({}, fieldHash);
    //
    // if (idx !== -1) {
    //   //Perform update
    //   fields[idx] = currentFieldCopy
    // } else {
    //   //create new field
    //   fields.push(currentFieldCopy);
    // }
    // this.setState({fields: fields, field: currentFieldCopy})
  };

  newField = (type) => {
    let field = null;
    switch (type) {
      case 'text_area':
        field = newTextField(this.state.pdfDocument.external_id);
        field.field_type = type;
        field.id = 'u_' + this.count;
        field.external_id = 'u_' + this.count;
        field.name = 'field' + this.count;
        field.field.key = 'field' + this.count++;
        field.field.type = type;
        field.field.coords = [0, 0, 100, 20];
        field.field.line_height = 18;
        field.page = 1;
        break;
        break;
      case 'text':
        field = newTextField(this.state.pdfDocument.external_id);
        field.field_type = type;
        field.id = 'u_' + this.count;
        field.external_id = 'u_' + this.count;
        field.name = 'field' + this.count;
        field.field.key = 'field' + this.count++;
        field.field.type = type;
        field.field.coords = [0, 0, 100, 20];
        field.page = 1;
        break;
      case 'datetime':
        field = newDateTimeField(this.state.pdfDocument.external_id);
        field.id = 'u_' + this.count;
        field.external_id = 'u_' + this.count;
        field.name = 'date' + this.count;
        field.field.key = 'date' + this.count++;
        field.field.coords = [0, 0, 100, 20];
        field.page = 1;
        break;
      case 'radio':
        field = newRadioField(this.state.pdfDocument.external_id);
        field.id = 'u_' + this.count;
        field.external_id = 'u_' + this.count;
        field.name = 'field' + this.count;
        field.field.key = 'field' + this.count++;
        field.field.options.push({
          label: 'option0',
          value: 'value0',
          coords: [0, 0, 20, 20],
          page: 1
        }); // text_field: "name"=>"text_value1"})
        break;
      case 'checkbox':
        field = newCheckboxField(this.state.pdfDocument.external_id);
        field.id = 'u_' + this.count;
        field.external_id = 'u_' + this.count;
        field.name = 'field' + this.count;
        field.field.key = 'field' + this.count++;
        field.field.options.push({
          label: 'Option0',
          value: 'option0',
          coords: [0, 0, 20, 20],
          page: 1
        });
        break;
      case 'composite':
        field = newCompositeField(this.state.pdfDocument.external_id);
        field.id = 'u_' + this.count;
        field.external_id = 'u_' + this.count;
        field.name = 'composite' + this.count;
        field.field.key = 'field' + this.count++;
        field.field.template = 'hello $string';
        field.field.elements.push({
          replace: '$var',
          pattern: '(.*)',
          coords: [0, 0, 100, 20],
          page: 1
        });
        break;
      // case 'toggle_button':
      //   field = newToggleButtonField(this.state.pdfDocument.external_id);
      //   field.id = "u_" + this.count;
      //   field.external_id = "u_" + this.count;
      //   field.name = "toggle_button" + this.count;
      //   field.field.key = "toggle_button" + this.count++;
      //   field.field.toggle_fields = [];
      //   break;
      // case 'add_document_button':
      //   field = newAddDocumentButtonField(this.state.pdfDocument.external_id);
      //   field.id = "u_" + this.count;
      //   field.external_id = "u_" + this.count;
      //   field.name = "add_doc_button" + this.count;
      //   field.field.key = "add_doc_button" + this.count++;
      //   field.field.pdf_document = 'any';
      //   break;
      case 'signature':
        field = newSignatureField(this.state.pdfDocument.external_id);
        field.id = 'u_' + this.count;
        field.external_id = 'u_' + this.count;
        field.name = 'signer' + (this.count + 1);
        field.field.signer = 'signer' + (this.count + 1);
        field.field.title = 'Signer ' + this.count++;
        field.field.signatures.push({
          coords: [0, 0, 100, 20],
          pdf_coords: [0, 0, 100, 20],
          page: 1
        });
        break;
    }

    if (field != null) {
      let fields = Object.assign([], this.state.fields);
      fields.push(field);
      this.setState({ fields: fields, field: field });
    }
  };

  updateFieldCoord = (data) => {
    let fieldCopy = Object.assign({}, this.state.field);
    updateCoord(fieldCopy, data);
    this.setState({ field: fieldCopy });
  };

  updateField = (field) => {
    this.setState({ field: field });
  };

  setField = (id, callback) => {
    let idx = this.state.fields.findIndex((f) => f.external_id === id);
    if (idx === -1) {
      console.log('Field not found! ' + idx);
      return;
    }
    let fieldCopy = Object.assign({}, this.state.fields[idx]);
    this.setState({ field: fieldCopy }, () => {
      if (callback != null) {
        callback();
      }
    });
  };

  setFields = (fields, cb = null) => {
    this.setState({ fields: fields, loading: false }, cb);
  };

  close = () => {
    this.setState({ field: null });
  };

  delete = () => {
    let idx = this.state.fields.findIndex(
      (f) => f.external_id === this.state.field.external_id
    );

    if (this.state.fields[idx].external_id != null) {
      deletePdfFieldFromPdfDocument(
        this.state.pdfDocument.external_id,
        this.state.fields[idx].external_id
      ).then((response) => {
        response.json().then((json) => {
          if (json.id != null) {
            let fields = [
              ...this.state.fields.slice(0, idx),
              this.state.fields.slice(idx + 1)
            ];
            this.setState({ fields: fields });
          } else {
            console.log('Error deleting field: ' + response.errors);
          }
        });
      });
      return;
    }

    if (idx !== -1) {
      let fieldsCopy = Object.assign([], this.state.fields);

      fieldsCopy.splice(idx, 1);

      this.setState({ fields: fieldsCopy, field: null });
    }
  };

  setSections = (sections) => {
    this.setState({ sections: sections });
  };

  pdfElement = () => {
    return (
      <Pdf
        pdf={this.state.pdf}
        loading={this.state.loading}
        fields={this.state.fields}
        field={this.state.field}
        setField={this.setField}
        onChange={this.updateFieldCoord}
      />
    );
  };

  setPdfDocument = (document) => {
    if (document != null) {
      this.setState({ loading: true, pdfDocument: document }, this.loadPdfFile);
    } else {
      this.setState({
        pdfDocument: null,
        pdf: null,
        field: null,
        fields: [],
        loading: false
      });
    }
  };

  render() {
    return (
      <Provider store={store}>
        <div>
          <div>
            {this.state.pdf != null ? (
              this.pdfElement()
            ) : (
              <h3>No Pdf Document Selected</h3>
            )}
          </div>
          <div style={formStyle}>
            <h3>Editor</h3>
            <PdfDocumentForm
              save={this.reloadPdfDocuments}
              setPdfDocument={this.setPdfDocument}
            />
            {this.state.pdf != null ? (
              <SectionForm
                pdfDocument={this.state.pdfDocument}
                sections={this.state.sections}
                setSections={this.setSections}
                save={this.saveSection}
              />
            ) : null}
            {this.state.pdf != null ? (
              <FieldForm
                pdf={this.state.pdf}
                pdfDocument={this.state.pdfDocument}
                loadPdfFields={this.loadPdfFields}
                fields={this.state.fields}
                sections={this.state.sections}
                field={this.state.field}
                setField={this.setField}
                newField={this.newField}
                close={this.close}
                save={this.save}
                delete={this.delete}
                onChange={this.updateField}
              />
            ) : null}
          </div>
        </div>
      </Provider>
    );
  }
}

const formStyle = {
  top: 0,
  bottom: 0,
  right: 0,
  position: 'fixed',
  overflowY: 'scroll',
  width: '600px'
};

const borderStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000'
};
