import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['indicator'];

  connect() {
    this.hideLoading();

    this.element.addEventListener('turbo:submit-start', () => {
      this.showLoading();
    });

    this.element.addEventListener('turbo:submit-end', () => {
      this.hideLoading();
    });
  }

  showLoading() {
    this.indicatorTarget.classList.remove('hide');
  }

  hideLoading() {
    this.indicatorTarget.classList.add('hide');
  }
}
