import React from 'react';
import TextField from './textField';
import SignatureField from './signatureField';
import CheckboxField from './checkboxField';

export default function FieldContext(props) {
  const onChange = (value) => {
    props.updateField({
      id: props.activeField.id,
      value: value
    });
  };

  // Use a wrapper function for signature to check for 'user' value.
  // Could be an issue if a text field has the 'user' string in it if we don't
  const onChangeSignature = (value) => {
    // Handle the case where we go from self sign to a contact signature, change the height and widths back to defaults
    if (props.activeField.value === 'user' && value !== 'user') {
      console.log('Need to self sign');
      props.updateField({
        id: props.activeField.id,
        value: value,
        height: 20,
        width: 85
      });
    } else {
      onChange(value);
    }
  };

  let fieldComponent = <p>Please select a field</p>;

  if (props.activeField) {
    switch (props.activeField.field_type) {
      case 'text':
        fieldComponent = (
          <TextField
            value={props.activeField.value}
            onChange={onChange}
            onDelete={props.onDelete}
            contacts={props.contacts}
            contactDetails={props.contactDetails}
          />
        );
        break;
      case 'checkbox':
        fieldComponent = (
          <CheckboxField
            onDelete={props.onDelete}
            contacts={props.contacts}
            contactDetails={props.contactDetails}
          />
        );
        break;
      case 'signature':
      case 'initials':
      case 'date_signed':
        fieldComponent = (
          <SignatureField
            onChange={onChangeSignature}
            onDelete={props.onDelete}
            value={props.activeField.value}
            contacts={props.contacts}
            contactDetails={props.contactDetails}
            fieldType={props.activeField.field_type}
          />
        );
        break;
    }
  }

  return <div>{fieldComponent}</div>;
}
