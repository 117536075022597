import { combineReducers } from 'redux';

import pdfTemplates from './pdfTemplateReducer';
import mlsFields from './mlsFieldsReducer';

const rootReducer = combineReducers({
  pdfTemplates,
  mlsFields
});

export default rootReducer;
