import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import useComplianceReviewStore from '../../shared/stores/ComplianceReviewStore';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import { Alert, Box, Button, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { formatDateTime } from '../../shared/services/dates';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ComplianceStatusBadge from './ComplianceStatusBadge';

export default function RequiredDocuments() {
  const { currentDocument, documents, setCurrentDocument } = useComplianceReviewStore();

  const [ requiredDocuments, setRequiredDocuments ] = React.useState([]);

  useEffect(() => {
    Request.get(getApiUrl() + `/v2/compliances/complianceables/${currentDocument.id}/folders`, (response) => {
      setRequiredDocuments(response);
    })
  }, [currentDocument.id])

  const handleSetCurrentDocument = (documentable_id) => {
    const document = documents.find((document) => document.documentable_id == documentable_id);
    if(document) {
      setCurrentDocument(document);
    }
  }

  const getRemainingDocuments = (folder) => {
    const requiredDocumentIds = folder.required_documents.map((requiredDocument) => requiredDocument.documentable_id);

    return folder.folder_resources.filter((resource) => !requiredDocumentIds.includes(resource.folder_resourcable.id));
  }

  const getRequiredDocumentable = (folder, documentable_id) => {
    console.log(folder);
    console.log(documentable_id);
    return folder.folder_resources.find((folder_resource) => folder_resource.folder_resourcable.id == documentable_id);
  }

  console.log(currentDocument);
  return (
    <Stack spacing={2}>
      {requiredDocuments.map((folder) => (
        <Stack key={folder.id}>
          <Stack direction="row" spacing={2}>
            <FolderIcon fontSize='medium' sx={{ my: 'auto !important' }} />
            <Typography variant='h6' sx={{ my: 'auto' }}>{folder.name}</Typography>
          </Stack>
          <Stack pl={1.5}>
            {folder.required_documents.map((requiredDocument) => (
              <Stack
                bgcolor={requiredDocument.documentable_id == currentDocument.documentable_id ? 'primaryLight.main' : ''}
                borderRadius="3px"
                p={0.5}
                key={requiredDocument.id}
                direction="row"
                spacing={1}
                onClick={() => {
                  if(requiredDocument.documentable_id) {
                    handleSetCurrentDocument(requiredDocument.documentable_id)
                  }}
                }
                sx={{
                  cursor: requiredDocument.documentable_id && 'pointer',
                  '&:hover': {
                    bgcolor: requiredDocument.documentable_id &&
                      requiredDocument.documentable_id !== currentDocument.documentable_id &&
                      'primaryLight.light',
                  }
                }}
              >
                {requiredDocument.documentable_id ? (
                  <CheckCircle color="success" />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
                <Typography color={!requiredDocument.documentable_id && 'error'}>
                  {requiredDocument.name}
                </Typography>
                {getRequiredDocumentable(folder, requiredDocument.documentable_id) && (
                  <ComplianceStatusBadge
                    showPending={true}
                    compliance_status={getRequiredDocumentable(folder, requiredDocument.documentable_id).folder_resourcable.compliance_status}
                  />
                )}
              </Stack>
            ))}
            {
              getRemainingDocuments(folder).map((resource) => resource.folder_resourcable.latest_version && (
                <Stack
                  bgcolor={resource.folder_resourcable.id == currentDocument.documentable_id ? 'primaryLight.main' : ''}
                  p={0.5}
                  key={resource.id}
                  direction="row"
                  spacing={1}
                  onClick={() => {
                    handleSetCurrentDocument(resource.folder_resourcable.id)
                  }}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: resource.folder_resourcable.id &&
                        resource.folder_resourcable.id !== currentDocument.documentable_id &&
                        'primaryLight.light',
                    }
                  }}
                >
                  <InsertDriveFileOutlinedIcon />
                  <Typography>
                    {resource.folder_resourcable.name}
                  </Typography>
                  {resource.folder_resourcable.compliance_status !== 'pending' && (
                    <ComplianceStatusBadge compliance_status={resource.folder_resourcable.compliance_status} />
                  )}
                </Stack>
              ))
            }
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
};