export const CLEAR_PDF_DOCUMENT_STATE = 'pdfDocument/clearPdfDocumentState';

export const PDF_DOCUMENT_FETCH_REQUESTED =
  'pdfDocument/pdfDocumentFetchRequested';

export const pdfDocumentFetchRequested = (pdfDocumentId) => ({
  type: PDF_DOCUMENT_FETCH_REQUESTED,
  payload: { pdfDocumentId: pdfDocumentId }
});

export const PDF_DOCUMENT_FETCHED = 'pdfDocument/pdfDocumentFetched';

export const pdfDocumentFetched = (pdfDocument) => ({
  type: PDF_DOCUMENT_FETCHED,
  payload: { pdfDocument: pdfDocument }
});

export const PDF_DOCUMENT_FETCH_FAILED = 'pdfDocument/pdfDocumentFetchFailed';

export const pdfDocumentFetchFailed = (errors) => ({
  type: PDF_DOCUMENT_FETCH_FAILED,
  payload: {
    errors
  }
});
