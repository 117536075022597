import { create } from "zustand";
import { persist } from 'zustand/middleware';

const useClientAppStore = create(
  persist(
    (set) => ({
      currentTransaction: null,
      setCurrentTransaction: (currentTransaction) => set({ currentTransaction }),

      documents: [],
      setDocuments: (documents) => set({ documents }),
      updateDocument: (document) => set((state) => ({ documents: state.documents.map((d) => d.id === document.id ? document : d) })),

      currentDocument: null,
      setCurrentDocument: (currentDocument) => set({ currentDocument }),

      currentSection: null,
      setCurrentSection: (currentSection) => set({ currentSection }),

      values: [],
      setValues: (values) => set({ values }),
      updateValue: (value) => set((state) => ({ values: state.values.map((v) => v.id === value.id ? value : v) })),

      currentValue: null,
      setCurrentValue: (currentValue) => set({ currentValue }),
    }),
    {
      name: 'client-app-storage', // unique name
    }
  )
);

export default useClientAppStore;