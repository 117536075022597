import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'type',
    'id',
    'percentOf',
    'feeTypeSelect',
    'valueField',
    'cap'
  ];

  connect() {
    this.initialLoad = true;
    this.changeType();
    this.adjustValueTypeBasedOnSelectValue(this.feeTypeSelectTarget.value);
    this.initialLoad = false;
  }

  changeType() {
    const type = this.typeTarget.value;
    const idSelect = this.idTarget;
    idSelect.style.display = type ? 'block' : 'none';
    this.populateOptions(idSelect, type === 'User' ? 'users' : 'companies');
    if (this.initialLoad && idSelect.dataset.selectedValue) {
      idSelect.value = idSelect.dataset.selectedValue;
    } else {
      idSelect.selectedIndex = 0;
    }
  }

  adjustValueTypeBasedOnSelectValue(feeType) {
    const percentOfSelect = this.percentOfTarget;
    const capInput = this.capTarget;
    const valueInput = this.valueFieldTarget;

    if (feeType === 'percent') {
      percentOfSelect.style.display = 'inline-block';
      capInput.style.display = 'inline-block';
    } else {
      percentOfSelect.style.display = 'none';
      capInput.style.display = 'none';
    }
  }

  adjustValueType(event) {
    const feeType = event.target.value;
    const percentOfSelect = this.percentOfTarget;
    const capInput = this.capTarget;
    const valueInput = this.valueFieldTarget;

    if (feeType === 'percent') {
      percentOfSelect.style.display = 'inline-block';
      capInput.style.display = 'inline-block';
    } else {
      percentOfSelect.style.display = 'none';
      capInput.style.display = 'none';
    }
    valueInput.value = '';
    capInput.value = '';
    percentOfInput.value = '';
  }

  populateOptions(selectElement, dataType) {
    const data = JSON.parse(selectElement.dataset[dataType]);
    selectElement.innerHTML = '';
    const promptOption = document.createElement('option');
    promptOption.text =
      dataType === 'users' ? 'Select a user' : 'Select a company';
    promptOption.value = '';
    selectElement.appendChild(promptOption);
    data.forEach(([name, id]) => {
      const option = document.createElement('option');
      option.text = name;
      option.value = id;
      selectElement.appendChild(option);
    });
  }
}
