import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { defaultText: String };
  static targets = ['menu', 'button'];

  connect() {
    this.updateSelectButton(); // Update on connect if any options are pre-selected
    this.menuTarget.addEventListener('change', () => this.updateSelectButton());
  }

  updateSelectButton() {
    const selectedItems = this.getAllChecked();
    const selectedTexts = selectedItems
      .map((input) => input.labels[0].innerText)
      .join(', ');
    this.buttonTarget.children[0].textContent =
      selectedTexts || this.defaultTextValue;
  }

  getAllChecked() {
    return Array.from(
      this.menuTarget.querySelectorAll(
        'input[type="radio"]:checked, input[type="checkbox"]:checked'
      )
    );
  }
}
