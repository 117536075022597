import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["documentName", "inputContainer"];

  connect() {
    this.timeout = null;
  }

  edit(event) {
    event.preventDefault();
    this.documentNameTarget.style.display = "none";
    this.inputContainerTarget.style.display = "block";
    this.inputContainerTarget.querySelector('input').focus();
  }

  handleInput(event) {
    event.preventDefault();
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.submitName(event);
    }, 500);
  }

  submitName(event) {
    event.preventDefault();
    const documentName = this.inputContainerTarget.querySelector('input').value;
    const documentId = this.data.get('documentId');
    const documentType = this.data.get('documentType');

    fetch(`/transactions/${this.data.get('transactionId')}/rename_document`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        document_name: documentName,
        document_type: documentType,
        document_id: documentId,
        no_redirect: true
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.documentNameTarget.innerText = documentName;
        } else {
          alert("Error renaming document");
        }
      })
      .catch(error => console.error("Error:", error));
  }
}
