import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("input", this.formatNumber.bind(this));
  }

  formatNumber(event) {
    const input = event.target;
    let value = input.value.replace(/,/g, "");

    if (!isNaN(value) && value !== "") {
      value = this.addCommas(value);
    }

    input.value = value;
  }

  addCommas(value) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}