import { Controller } from 'stimulus';

/**
 * Description:
 * Enables multi-level filtering with objects.
 * Assumes the first level is always visible.
 * White-list filtering; to be filtered is to be allowed in.
 *
 * When a filter F is clicked on level n:
 *   All filterables below level n that are not filtered by F are hidden
 *   All objects filtered by F are shown
 *
 * Structure: stimulus-div -> filter -> filter (+ filterable) -> filterable
 * The number filter (+ filterable) levels is >=0
 *
 * Filters have data-level-filters-target = 'filter'
 * Those filtered hove data-level-filters-target = 'filterable'
 * Note you can be both by data-level-filters-target = 'filter filterable'
 *
 * filters and filterables must have:
 *   data-filter-level: int
 *     Used for the following logic:
 *       "When a filter F is clicked on level n:
 *         All objects below level n not filtered by F are hidden"
 *
 * filters must have:
 *   data-filter-scope: str
 *     Determines what filterables element can show
 *
 * filterables must have:
 *   data-filtered-by: str
 *     Determines which filter has the ability to show this element
 */
export default class extends Controller {
  static targets = ['filter', 'filterable'];
  observer = null;

  connect() {
    this.hideFilterables();
    this.setupFilterClickEventListeners();
    this.setupMutationObserver();
  }

  disconnect() {
    super.disconnect();
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  hideFilterables() {
    this.filterableTargets.forEach((el) => {
      el.classList.add('hide');
    });
  }

  setupFilterClickEventListeners() {
    // First, remove existing click event listeners
    this.filterTargets.forEach((filter) => {
      const onClick = filter.__onClick;
      if (onClick) {
        filter.removeEventListener('click', onClick);
        filter.__onClick = null;
      }
    });

    // Then add new click event listeners
    this.filterTargets.forEach((filter) => {
      const onClick = this.updateLevels.bind(this);
      filter.addEventListener('click', onClick);
      // Store the click event listener
      filter.__onClick = onClick;
    });
  }

  setupMutationObserver() {
    // Function to execute when mutations are observed
    const callback = (mutationsList, observer) => {
      // Check if the addedNodes property has a new Node
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          this.setupFilterClickEventListeners();
        }
      }
    };

    // Create a new instance of MutationObserver
    this.observer = new MutationObserver(callback);

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };

    // Start observing the controller's element for configured mutations
    this.observer.observe(this.element, config);
  }

  updateLevels(event) {
    let clickedFilter = event.target;
    let clickedFilterScope = clickedFilter.dataset.filterScope;
    let clickedLevel = parseInt(clickedFilter.dataset.filterLevel);

    // hide all filterables on levels greater than the clicked level
    this.filterableTargets.forEach((el) => {
      if (parseInt(el.dataset.filterLevel) > clickedLevel) {
        el.classList.add('hide');
      }
    });

    // remove .bg-secondary from all filters in levels >= clickedLevel
    this.filterTargets.forEach((filter) => {
      if (parseInt(filter.dataset.filterLevel) >= clickedLevel) {
        filter.classList.remove('bg-secondary');
        filter.classList.remove('on-load-accent-soft-to-secondary');
      }
    });

    // add .bg-color-secondary to the clicked filter
    clickedFilter.classList.add('bg-secondary');

    // show all filterables related to clicked filter
    this.filterableTargets.forEach((el) => {
      if (el.dataset.filteredBy === clickedFilterScope) {
        el.classList.remove('hide');
      }
    });
  }
}
