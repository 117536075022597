import React from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { v4 as uuidv4 } from 'uuid';
import HtmlFieldForm from './HtmlFieldForm';
import HtmlSectionForm from '../pdf_annotator/HtmlSectionForm';
import {
  newTextField,
  newSignatureField,
  newDateTimeField,
  newRadioField,
  newCompositeField,
  newCheckboxField
} from '../pdf_annotator/Util';
import { store } from './redux/store';
import { Provider, connect } from 'react-redux';
import {
  deletePdfField,
  pdfTemplateFetchRequested,
  pdfTemplateFieldsRequested,
  updatePdfField,
  downloadPdf
} from './redux/actions/pdfTemplateActions';
import moment from 'moment';
import { CloseButton, Button, Modal } from 'react-bootstrap';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

class ShowPdfModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.iframeRef = React.createRef();
  }

  base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      'data:application/pdf;base64,'.length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  componentDidUpdate(prevProps) {
    if (!this.props.rawPdf || !this.iframeRef.current) return;
    let pdfBlob = this.base64toBlob(this.props.rawPdf);
    const objUrl = URL.createObjectURL(pdfBlob);
    this.iframeRef.current.setAttribute('src', objUrl);
    URL.revokeObjectURL(objUrl);
  }

  render() {
    return (
      <Modal show={true} onHide={this.props.toggleModal} size={'lg'}>
        <Modal.Header>
          <Modal.Title>Pdf Preview</Modal.Title>
          <div onClick={this.props.toggleModal}>X</div>
        </Modal.Header>
        <Modal.Body>
          <iframe
            ref={this.iframeRef}
            style={{ height: '800px', width: '100%' }}
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class HtmlViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfTemplate: props.selectedTemplate,
      pdf: null,
      field: null,
      fields: [],
      loading: false,
      showModal: false
    };

    this.count = 0;
  }

  componentDidUpdate(prevProps, nextProps) {
    if (this.props.pdfTemplate?.id !== prevProps.pdfTemplate?.id) {
      // this.props.fetchPdfFieldsForPdfTemplate(this.props.pdfTemplate?.id)
      this.loadPdfFields();
    }
  }

  componentDidMount() {
    // let pdfTemplates = useSelector((state) => state.pdfTemplates.pdfTemplates)
  }

  loadPdfFields = (id = null) => {
    // console.error("Calling loadPdfFields")
    this.props.fetchPdfFieldsForPdfTemplate(this.props.pdfTemplate.id);
  };

  save = (fieldHash) => {
    let fields = Object.assign([], this.props.pdfFields);

    let idx = fields.findIndex((f) => {
      return f.id === fieldHash.id;
    });

    let currentFieldCopy = Object.assign({}, fieldHash);

    if (idx !== -1) {
      //Perform update
      fields[idx] = currentFieldCopy;
    } else {
      //create new field
      fields.push(currentFieldCopy);
    }
    this.setState({ fields: fields, field: currentFieldCopy });
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  newField = (type) => {
    let field = null;
    let external_id = uuidv4();
    switch (type) {
      case 'text_area':
        field = newTextField(this.props.pdfTemplate.id, external_id);
        field.field_type = type;
        field.id = 'u_' + external_id;
        field.external_id = 'u_' + external_id;
        field.name = 'field' + external_id;
        field.field.key = 'field' + this.count++;
        field.field.type = type;
        field.field.line_height = 18;
        break;
        break;
      case 'text':
        field = newTextField(this.props.pdfTemplate.id, external_id);
        field.field_type = type;
        field.id = 'u_' + external_id;
        field.external_id = 'u_' + external_id;
        field.name = 'field' + external_id;
        field.field.key = 'field' + this.count++;
        field.field.type = type;
        break;
      case 'datetime':
        field = newDateTimeField(this.props.pdfTemplate.id, external_id);
        field.id = 'u_' + external_id;
        field.external_id = 'u_' + external_id;
        field.name = 'date' + external_id;
        field.field.key = 'date' + this.count++;
        break;
      case 'radio':
        field = newRadioField(this.props.pdfTemplate.id, external_id);
        field.id = 'u_' + external_id;
        field.external_id = 'u_' + external_id;
        field.name = 'field' + external_id;
        field.field.key = 'field' + this.count++;
        field.field.options.push({ label: 'option0', value: 'value0' }); // text_field: "name"=>"text_value1"})
        break;
      case 'checkbox':
        field = newCheckboxField(this.props.pdfTemplate.id, external_id);
        field.id = 'u_' + external_id;
        field.external_id = 'u_' + external_id;
        field.name = 'field' + external_id;
        field.field.key = 'field' + this.count++;
        field.field.options.push({ label: 'Option0', value: 'option0' });
        break;
      case 'composite':
        field = newCompositeField(this.props.pdfTemplate.id, external_id);
        field.id = 'u_' + external_id;
        field.external_id = 'u_' + external_id;
        field.name = 'composite' + external_id;
        field.field.key = 'field' + this.count++;
        field.field.template = 'hello $string';
        field.field.elements.push({ replace: '$var', pattern: '(.*)' });
        break;
      case 'signature':
      case 'initials':
      case 'date_signed':
        field = newSignatureField(this.props.pdfTemplate.id, external_id, type);
        field.id = 'u_' + external_id;
        field.external_id = 'u_' + external_id;
        field.name = 'signer1';
        field.field.signer = 'signer' + (this.count + 1);
        field.field.title = 'Signer ' + this.count++;
        break;
    }

    if (field != null) {
      let fields = Object.assign([], this.props.pdfFields);
      fields.push(field);
      this.props.updatePdfField(field);
      //Set this.props.pdfField to field and pdfFields to fields
      // this.setState({fields: fields, field: field});
    }
  };

  updateField = (field) => {
    this.props.updatePdfField(field);
  };

  inject = (a) => {
    this.props.inject(a);
  };

  // https://stackoverflow.com/questions/15523514/find-by-key-deep-in-a-nested-array
  getObject = (theObject, idValue) => {
    var result = null;
    if (theObject instanceof Array) {
      for (var i = 0; i < theObject.length; i++) {
        result = this.getObject(theObject[i], idValue);
        if (result) {
          break;
        }
      }
    } else {
      for (var prop in theObject) {
        if (prop == 'id') {
          if (theObject[prop] == idValue) {
            return theObject;
          }
        }
        if (
          theObject[prop] instanceof Object ||
          theObject[prop] instanceof Array
        ) {
          result = this.getObject(theObject[prop], idValue);
          if (result) {
            break;
          }
        }
      }
    }
    return result;
  };

  setFields = (fields, cb = null) => {
    this.setState({ fields: fields, loading: false }, cb);
  };

  close = () => {
    this.setState({ field: null });
  };

  delete = () => {
    let idx = this.props.pdfFields.findIndex(
      (f) => f.id === this.props.pdfField.id
    );

    if (this.props.pdfFields[idx].id != null) {
      this.props.deletePdfField(
        this.props.pdfTemplate.id,
        this.props.pdfField.id
      );
      this.props.fetchPdfTemplate(this.props.pdfTemplate.id);
      // setTimeout(() => {  this.props.fetchPdfFieldsForPdfTemplate(this.props.pdfTemplate.id) }, 2000);
      return;
    }
    if (idx !== -1) {
      console.error(
        'The currently selected pdfField does not have an id attribute'
      );
    }
  };

  downloadPdfJson = (empty) => {
    const { pdfTemplate, pdfFields } = this.props;
    let fields = {};

    if (!empty) {
      pdfFields.forEach((field) => {
        switch (field.field_type) {
          case 'text':
          case 'textarea':
            fields[field.id] = btoa(Math.random().toString()).substr(10, 20);
            break;
          case 'datetime':
            fields[field.id] = moment().format('L');
            break;
          case 'checkbox':
          case 'radio':
            fields[field.id] = {};

            field.field.options.forEach((option) => {
              fields[field.id][option.value] = {
                value: option.value,
                checked: true
              };
            });
            break;
          case 'composite':
            fields[field.id] = {};

            field.field.elements.forEach((element) => {
              let str = '';
              if (element.replace === '$month_day') {
                str = moment().format('MMMM DD');
              } else if (element.replace === '$month') {
                str = moment().format('MMMM');
              } else if (element.replace === '$day') {
                str = moment().format('DD');
              } else if (element.replace === '$year') {
                str = moment().format('YYYY');
              } else if (element.replace === '$time') {
                str = moment().format('HH:mm');
              } else if (element.replace === '$am_pm') {
                str = moment().format('A');
              }

              fields[field.id][element.replace] = {
                value: str,
                checked: false
              };
            });
            break;
          default:
            console.log('Not pre-filling data for: ' + field.field_type);
            break;
        }
      });
    }

    return {
      name: pdfTemplate.document,
      pdf_document_id: pdfTemplate.id,
      user_id: 'test',
      document_id: pdfTemplate.id,
      signature_ids: Object.keys(pdfTemplate.signatures),
      field_values: fields,
      html_template: btoa(pdfTemplate.html_template)
    };
  };

  render() {
    return (
      <Provider store={store}>
        {this.state.showModal ? (
          <ShowPdfModal
            toggleModal={this.toggleModal}
            rawPdf={this.props.rawPdf}
          />
        ) : (
          ''
        )}
        <div>
          {this.props.pdfTemplate != null ? (
            <div>
              <button
                onClick={() => {
                  this.toggleModal();
                  this.props.downloadPdf(this.downloadPdfJson(true));
                }}
              >
                Generate Test PDF
              </button>
              <button
                onClick={() => {
                  this.toggleModal();
                  this.props.downloadPdf(this.downloadPdfJson(false));
                }}
              >
                Generate Test PDF (With Test Data)
              </button>
              <button
                onClick={() => {
                  var newWindow = window.open();
                  newWindow.document.write(
                    this.props.pdfTemplate.html_template
                  );
                }}
              >
                View Current HTML
              </button>
            </div>
          ) : null}
          <div>
            {/*<PdfTemplateForm save={this.reloadPdfDocuments} setPdfTemplate={this.setPdfDocument} />*/}
            {/*setPdfDocument isn't getting called because I took out this form. So I'll need to add it to the function where we set the pdf template*/}
            {this.props.pdfTemplate != null ? (
              <HtmlSectionForm pdfTemplate={this.props.pdfTemplate} />
            ) : (
              ''
            )}
            {this.props.pdfTemplate != null ? (
              <HtmlFieldForm //pdf={this.state.pdf}
                pdfTemplate={this.props.pdfTemplate}
                loadPdfFields={this.loadPdfFields}
                // fields={this.props.pdfFields}
                sections={this.props.pdfTemplate?.sections || []}
                newField={this.newField}
                close={this.close}
                save={this.save}
                delete={this.delete}
                onChange={this.updateField}
                inject={this.props.inject}
              />
            ) : null}
          </div>
        </div>
      </Provider>
    );
  }
}

const formStyle = {
  top: 0,
  bottom: 0,
  right: 0,
  position: 'fixed',
  overflowY: 'scroll',
  width: '600px'
};

const borderStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000'
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPdfFieldsForPdfTemplate: (pdfTemplateId) =>
      dispatch(pdfTemplateFieldsRequested(pdfTemplateId)),
    updatePdfField: (pdfField) => dispatch(updatePdfField(pdfField)),
    deletePdfField: (pdfTemplateId, pdfFieldId) =>
      dispatch(deletePdfField(pdfTemplateId, pdfFieldId)),
    fetchPdfTemplate: (pdfTemplateId) =>
      dispatch(pdfTemplateFetchRequested(pdfTemplateId)),
    downloadPdf: (fields) => dispatch(downloadPdf(fields))
  };
};

const mapStateToProps = (state) => {
  return {
    pdfFields: state.pdfTemplates.pdfFields,
    pdfField: state.pdfTemplates.pdfField,
    rawPdf: state.pdfTemplates.rawPdf
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HtmlViewer);
