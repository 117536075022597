import React from 'react';

export default class DateTimeField extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = (data) => {
    let field = JSON.parse(JSON.stringify(this.props.field));

    let dataField = data.field;
    delete data.field;

    Object.assign(field.field, dataField);
    Object.assign(field, data);

    this.props.onChange(field);
  };

  getSectionOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a Section ---
      </option>
    );
    {
      this.props.sections.forEach((section) => {
        options.push(
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        );
      });
    }
    return options;
  };

  render() {
    let field = this.props.field;

    return (
      <div>
        <input
          type="hidden"
          value={this.props.subField ? '' : field.external_id}
        />
        <label>Name</label>
        <br />
        <input
          type="text"
          name="name"
          value={field.name}
          onChange={(e) =>
            this.onChange({
              name: e.target.value,
              field: { key: e.target.value }
            })
          }
          disabled={this.props.subField}
        />
        <br />
        <label>Mode</label>
        <br />
        <select
          onChange={(e) => this.onChange({ field: { mode: e.target.value } })}
          value={field.field.mode}
        >
          <option value="datetime">datetime</option>
          <option value="date">date</option>
          <option value="time">time</option>
        </select>
        <br />
        <label>Field Type</label>
        <br />
        <input
          type="text"
          name="field_type"
          value={field.field_type}
          readOnly={true}
        />
        <br />
        <label>Title</label>
        <br />
        <input
          type="text"
          value={field.field.title || ''}
          onChange={(e) => this.onChange({ field: { title: e.target.value } })}
        />
        <br />
        <label>Format</label>
        <br />
        <input
          type="text"
          value={field.field.format || ''}
          onChange={(e) => this.onChange({ field: { format: e.target.value } })}
        />
        <br />
        <label>Header Text</label>
        <br />
        <input
          type="text"
          value={field.field['header_text'] || ''}
          onChange={(e) =>
            this.onChange({ field: { header_text: e.target.value } })
          }
        />
        <br />
        <label>Header Icon</label>
        <br />
        <input
          type="text"
          value={field.field['header_icon'] || ''}
          onChange={(e) =>
            this.onChange({ field: { header_icon: e.target.value } })
          }
        />
        <br />
        <label>hidden</label>
        <br />
        <input
          type="checkbox"
          checked={field.field.hidden || false}
          onChange={(e) =>
            this.onChange({ field: { hidden: e.target.checked } })
          }
        />
        <br />
        <label>Default Value</label>
        <br />
        <input
          type="text"
          value={field.field.value || ''}
          placeholder="Date() + 1"
          onChange={(e) => this.onChange({ field: { value: e.target.value } })}
        />
        <br />

        <label htmlFor="context">Context</label>
        <br />
        <textarea
          onChange={(e) =>
            this.onChange({ field: { context: e.target.value } })
          }
          value={field.field.context || ''}
        />
        <br />
        <label>pdf section</label>
        <br />
        <select
          value={field['pdf_section_id'] || ''}
          onChange={(e) => this.onChange({ pdf_section_id: e.target.value })}
        >
          {this.getSectionOptions()}
        </select>
        <br />
        <label>Order</label>
        <br />
        <input
          type="number"
          value={field.order || ''}
          onChange={(e) => this.onChange({ order: e.target.value })}
        />
        <br />
      </div>
    );
  }
}

const borderStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000'
};
