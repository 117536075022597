import React from 'react';
import { useState, useEffect } from 'react';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import Login from '../shared/Login';
import PdfOverlay from './pdfOverlay';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'react-bootstrap';
import theme from '../shared/theme';

Sentry.init({
  dsn: 'https://bf7b63b2116f583bb7346c1c5e3abddb@o768676.ingest.sentry.io/4506547652919296',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/app.useoffer\.ai\/transactions\/details\//,
        /^https:\/\/offer-web-dev.herokuapp\.com\/transactions\/details\//
      ]
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App(props) {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setLoggedIn(window.sessionStorage.getItem('auth') != null);
  }, []);

  if (loggedIn) {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PdfOverlay pdfDocumentId={props.pdfDocumentId} flowId={props.flowId} />
        </Provider>
      </ThemeProvider>
    );
  } else {
    return <Login setLoggedIn={() => setLoggedIn(true)} />;
  }
}
