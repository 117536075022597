import React from 'react';

export default class PdfPage extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = null;
  }

  componentDidMount() {
    let ctx = this.canvas.getContext('2d');

    let viewport = this.props.page.getViewport({ scale: 1.0 });
    this.canvas.height = viewport.height;
    this.canvas.width = viewport.width;

    this.props.page
      .render({ canvasContext: ctx, viewport: viewport })
      .promise.then(() => {
        this.props.pageRendered();
      });
  }

  render() {
    return (
      <canvas
        ref={(ref) => {
          this.canvas = ref;
        }}
      ></canvas>
    );
  }
}
