import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#2A6047',
      contrastText: '#FFFFFF',
      light: '#EAEFED',
    },
    gold: {
      main: '#E8BC3B',
      light: '#F9F5E8',
      contrastText: '#000000'
    },
    primaryLight: {
      main: '#D7EFDC',
      light: '#D7EFDC40',
      contrastText: '#000000'
    },
    secondary: {
      main: '#1A1A1A',
      contrastText: "#FFFFFF"
    },
    error: {
      main: '#DA252D',
      contrastText: '#FFFFFF'
    },
    grey: {
      main: '#F1F1F1',
      light: '#F5F5F5',
      dark: '#33333360',
      contrastText: '#000000'
    },
    info: {
      main: '#4264ea',
      light: '#4264ea40',
    },
    background: {
      default: "#F6F6F4"
    }
  },
  typography: {
    htmlFontSize: 20,
    fontFamily: [
      'Inter',
      'sans-serif'
    ].join(','),
    button: {
      textTransform: 'none',
      fontSize: '14px'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          letterSpacing: "1px",
        },
        contained: {
          "&:hover": {
            color: '#FFFFFF',
            bgcolor: '#2A6047'
          }
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        contained: {
          "&:hover": {
            color: '#FFFFFF',
            bgcolor: '#2A6047'
          }
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
          borderRadius: '12px'
        }
      }
    }
  },
});

export default theme;
