import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Avatar, Card, Stack } from '@mui/material';
import { useEffect } from 'react';
import HouseIcon from '@mui/icons-material/House';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import useTransactionStore from '../../shared/stores/TransactionsStore';
import useClientAppStore from '../../shared/stores/ClientAppStore';

const drawerWidth = 240;

function Sidebar(props) {
  const { window, children } = props;
  const { transactions, setTransactions } = useTransactionStore();
  const { setCurrentTransaction, currentTransaction } = useClientAppStore();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    if(!currentTransaction) {
        Request.get(`${getApiUrl()}/v2/client_apps/transactions`, (data) => {
        console.log(data);
        setTransactions(data);
        setCurrentTransaction(data[0]);
      })
    }
  }, [])

  const drawer = (
    <Stack>
      <Toolbar />
      <Divider />
      <List>
        <ListItem key={'home'} disablePadding>
          <ListItemButton onClick={() => navigate('')}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItemButton>
        </ListItem>
        {
          transactions.map((transaction) => (
            <ListItem key={transaction.id} disablePadding>
              <Stack
                width="100%"
                sx={{
                  bgcolor: currentTransaction?.id === transaction.id ? 'primary.main' : 'inherit',
                  color: currentTransaction?.id === transaction.id ? 'white' : 'inherit'
                }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate('/v2/client_apps')
                    setCurrentTransaction(transaction)
                  }}
                >
                  <ListItemIcon>
                    <HouseIcon />
                  </ListItemIcon>
                  <ListItemText primary={transaction.name} />
                </ListItemButton>
              </Stack>
            </ListItem>
          ))
        }
      </List>
      <Divider />
      <List>
        <ListItem key={'settings'} disablePadding>
          <Stack
            width="100%"
            sx={{
              bgcolor: location.pathname === '/v2/client_apps/settings' ? 'primary.light' : 'inherit',
              color: location.pathname === '/v2/client_apps/settings' ? 'white' : 'inherit',
            }}
          >
            <ListItemButton
              onClick={() => {
                setCurrentTransaction(null);
                navigate('settings')
              }}
            >
              <ListItemIcon>
                {<AccountBoxIcon />}
              </ListItemIcon>
              <ListItemText primary={'Account'} />
            </ListItemButton>
          </Stack>
        </ListItem>
        <ListItem key={'logout'} disablePadding>
          <ListItemButton onClick={() => navigate('logout')}>
            <ListItemIcon>
              {<LogoutIcon />}
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItemButton>
        </ListItem>
      </List>
      <Stack position="fixed" bottom={0} width={drawerWidth}>
        <Divider />
        <Stack p={2}>
          <Stack spacing={1}>
            <Stack direction="row" spacing={2}>
              <Stack>
                <Avatar />
              </Stack>
              <Stack>
                <Typography variant="h6">{currentTransaction?.agent?.full_name}</Typography>
                <Typography variant="body2">Your agent</Typography>
              </Stack>
            </Stack>
            <Card>
              <Stack p={1}>
                <Typography fontWeight="bold" variant="body2">
                  Email me
                </Typography>
                <Typography>
                  {currentTransaction?.agent?.email}
                </Typography>
              </Stack>
            </Card>
            <Card>
              <Stack p={1}>
                <Typography fontWeight="bold" variant="body2">
                  Call me
                </Typography>
                <Typography>
                  {currentTransaction?.agent?.phone}
                </Typography>
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: { sm: 'none' }
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            { currentTransaction?.name || "Offer" }
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        pt={{ xs: 7, sm: 0 }}
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {children}
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default Sidebar;
