import React from 'react';
import { connect } from 'react-redux';

class HtmlDocumentTree extends React.Component {
  onClick = (id, e) => {
    this.props.onClick(id);
    e.preventDefault();
  };

  listElement = (field) => {
    if (
      field['field_type'] === 'signatures' ||
      field['field_type'] === 'initials' ||
      field['field_type'] === 'signature' ||
      field['field_type'] === 'date_signed'
    ) {
      return (
        <li key={field.id}>
          <a href="#" onClick={(e) => this.onClick(field.id, e)}>
            <b>
              {field.field.title}-{field.field_type}
            </b>
          </a>
        </li>
      );
    } else {
      return (
        <li key={field.id}>
          <a href="#" onClick={(e) => this.onClick(field.id, e)}>
            {field.name || field.field.title}
          </a>
        </li>
      );
    }
  };

  render() {
    if (this.props.pdfFields == null) return null;

    let filteredFields = this.props.pdfFields.filter((field) =>
      [
        'text',
        'text_area',
        'datetime',
        'radio',
        'checkbox',
        'composite',
        'signatures',
        'signature',
        'date_signed',
        'initials'
      ].includes(field['field_type'])
    );
    return (
      <div>
        <ul>
          {this.props.pdfTemplate.sections.map((s) => {
            // let fields = s.data
            let fields = filteredFields.filter(
              (field) => field['pdf_section_id'] === s.id
            ).sort((a, b) => a.order - b.order);

            return (
              <li key={s.id}>
                {s.title} <ul>{fields.map((i) => this.listElement(i))}</ul>
              </li>
            );
          })}
          <li>
            No Section{' '}
            <ul key="no_section">
              {filteredFields
                .filter((field) => field['pdf_section_id'] == null)
                .map((i) => this.listElement(i))}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pdfFields: state.pdfTemplates.pdfFields,
    pdfField: state.pdfTemplates.pdfField,
    pdfTemplate: state.pdfTemplates.pdfTemplate
  };
};

export default connect(mapStateToProps, null)(HtmlDocumentTree);
