// submit_on_action_controller.js
import { Controller } from 'stimulus';
import { debounce } from 'lodash';
import Rails from '@rails/ujs';

/**
 * Structure: Any
 *
 * form -> the form to be submitted
 * trigger -> the element to have an event listener on
 *   event -> Which event causes the trigger to submit the form
 *
 * Useful events:
 *   key(up/down/press)
 *   (dbl)click
 *   mouse(down/up/over/out/move)
 *   submit
 *   change
 *   focus
 *   input
 *   (un)load
 *   resize
 *   scroll
 *   drag(end/enter/exit/leave/over/start)
 *   drop
 */
export default class extends Controller {
  static targets = ['form', 'trigger'];
  static values = { debounce: Number };

  connect() {
    this.debounceValue = this.debounceValue || 100;
    this.submitForm = debounce(this.submitForm, this.debounceValue);
    if (this.hasFormTarget) {
      this.form = this.formTarget;
    } else if (this.element.tagName === 'FORM') {
      this.form = this.element;
    } else {
      this.form = this.element.closest('form');
    }

    this.triggerTargets.forEach((trigger) => {
      let event = trigger.dataset.submitoneventEvent;
      if (event) {
        trigger.addEventListener(event, this.submitForm);
      } else {
        console.error('Event is not provided for SubmitOnEvent controller');
      }
    });
  }

  submitForm = () => {
    Rails.fire(this.form, 'submit');
  };

  disconnect() {
    this.triggerTargets.forEach((trigger) => {
      let event = trigger.dataset.submitoneventEvent;
      if (event) {
        trigger.removeEventListener(event, this.handleEvent);
      }
    });
    this.submitForm.cancel(); // cancel any pending debounced calls
  }
}
