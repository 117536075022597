import axios from 'axios';
import { getHeaders } from '../Network';

const config = (contentType) => {
  const headers = getHeaders();

  if (contentType) {
    headers['Content-Type'] = contentType || 'application/json';
  }

  return {
    headers: headers,
  };
};

const Request = {
  get: async (url, callback, failureCallback) => {
    axios
      .get(url, config())
      .then((response) => {
        callback(response.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          localStorage.clear();
          window.location = '/';
        } else {
          failureCallback && failureCallback(err);
        }
      });
  },
  download: (url, callback) => {
    fetch(url, config()).then((response) => response.blob()).then((blob) => {
      callback(blob);
    });
  },
  post: async (url, body, callback, failureCallback, contentType, unauthCallback) => {
    axios
      .post(url, body, config(contentType))
      .then((response) => {
        callback(response.data, response.headers);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          if(unauthCallback) {
            unauthCallback();
          } else {
            localStorage.clear();
            window.location = '/';
          }
        } else {
          failureCallback && failureCallback(err);
        }
      });
  },
  patch: async (url, body, callback, failureCallback) => {
    axios
      .patch(url, body, config())
      .then((response) => {
        callback(response.data, response.headers);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          localStorage.clear();
          window.location = '/';
        } else {
          failureCallback && failureCallback(err);
        }
      });
  },
  delete: async (url, callback, failureCallback) => {
    axios
      .delete(url, config())
      .then((response) => {
        callback(response);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          localStorage.clear();
          window.location = '/';
        } else {
          failureCallback && failureCallback(err);
        }
      });
  },
};

export default Request;
