import React from 'react';
import { coordsToArray, validCoords } from './Util';
import { connect } from 'react-redux';
import { getMlsFields } from './redux/actions/textFieldActions';

class TextField extends React.Component {
  constructor(props) {
    super(props);

    //TODO: figure out if we actually need this.
    this.props.dispatch(getMlsFields());

    // this.state = {
    //   id: null,
    //   pdf_document_id: pdfDocumentId,
    //   name: "",
    //   field_type: "text",
    //   field: {
    //     type: "text",
    //     key: "",
    //     title: "",
    //     placeholder: "",
    //     "header_text": "",
    //     "header_icon": "",
    //     "is_detail": false,
    //     currency: false,
    //     coords: [],
    //     page: 0,
    //     value: "",
    //     context: ""
    //   },
    //   pdf_section_id: pdfSectionId,
    //   related_pdf_document_id: "",
    //   order: ""
    // };
  }

  onChange = (data) => {
    // debugger
    // let field = Object.assign({}, this.props.field);
    //We're making a deep copy here
    //I actually don't think this will break anything.
    let field = JSON.parse(JSON.stringify(this.props.field));
    let dataField = data.field;
    delete data.field;

    //I need an action to update the field
    Object.assign(field.field, dataField);
    Object.assign(field, data);

    this.props.onChange(field);
  };

  getSectionOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a Section ---
      </option>
    );
    {
      this.props.sections.forEach((section) => {
        options.push(
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        );
      });
    }
    return options;
  };

  getMlsFieldOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a field ---
      </option>
    );
    {
      this.props.mlsFields.forEach((field) => {
        options.push(
          <option key={field} value={field}>
            {field}
          </option>
        );
      });
    }
    return options;
  };

  render() {
    let field = this.props.field;

    return (
      <div>
        <input
          type="hidden"
          value={this.props.subField ? '' : field.external_id}
        />
        <label>Name</label>
        <br />
        <input
          type="text"
          name="name"
          value={field.name}
          onChange={(e) =>
            this.onChange({
              name: e.target.value,
              field: { key: e.target.value }
            })
          }
          disabled={this.props.subField}
        />
        <br />

        <label>Field Type</label>
        <br />
        <input
          type="text"
          name="field_type"
          value={field.field_type}
          readOnly={true}
        />
        <br />
        <label>Title</label>
        <br />
        <input
          type="text"
          value={field.field.title || ''}
          onChange={(e) => this.onChange({ field: { title: e.target.value } })}
        />
        <br />
        <label>MLS Field</label>
        <br />
        <select
          value={field.field.mls_field || ''}
          onChange={(e) =>
            this.onChange({ field: { mls_field: e.target.value } })
          }
        >
          {this.getMlsFieldOptions()}
        </select>
        <br />
        <label>Currency</label>
        <br />
        <input
          type="checkbox"
          checked={field.field.currency || false}
          onChange={(e) =>
            this.onChange({ field: { currency: e.target.checked } })
          }
        />
        <br />
        <label>Placeholder</label>
        <br />
        <input
          type="text"
          value={field.field['placeholder'] || ''}
          onChange={(e) =>
            this.onChange({ field: { placeholder: e.target.value } })
          }
        />
        <br />
        <label>Header Text</label>
        <br />
        <input
          type="text"
          value={field.field['header_text'] || ''}
          onChange={(e) =>
            this.onChange({ field: { header_text: e.target.value } })
          }
        />
        <br />
        <label>Header Icon</label>
        <br />
        <input
          type="text"
          value={field.field['header_icon'] || ''}
          onChange={(e) =>
            this.onChange({ field: { header_icon: e.target.value } })
          }
        />
        <br />
        <label>Phone Number</label>
        <br />
        <input
          type="checkbox"
          checked={field.field['is_phone_number'] || false}
          onChange={(e) =>
            this.onChange({ field: { is_phone_number: e.target.checked } })
          }
        />
        <br />
        <label>Is Detail</label>
        <br />
        <input
          type="checkbox"
          checked={field.field['is_detail'] || false}
          onChange={(e) =>
            this.onChange({ field: { is_detail: e.target.checked } })
          }
        />
        <br />
        <label>hidden</label>
        <br />
        <input
          type="checkbox"
          checked={field.field.hidden || false}
          onChange={(e) =>
            this.onChange({ field: { hidden: e.target.checked } })
          }
        />
        <br />
        <label htmlFor="context">Context</label>
        <br />
        <textarea
          onChange={(e) =>
            this.onChange({ field: { context: e.target.value } })
          }
          value={field.field.context || ''}
        />
        <br />
        <label htmlFor="value">Default Value</label>
        <br />
        <input
          type="text"
          value={field.field.value || ''}
          onChange={(e) => this.onChange({ field: { value: e.target.value } })}
        />
        <br />
        <label>Picker Value</label>
        <br />
        <select
          value={field.field.picker || ''}
          onChange={(e) => this.onChange({ field: { picker: e.target.value } })}
        >
          <option key="select" value="">
            --- Select a field ---
          </option>
          <option value="Contact.email">Contact.email</option>
          <option value="Contact.full_name">Contact.full_name</option>
          <option value="Contact.first_name">Contact.first_name</option>
          <option value="Contact.last_name">Contact.last_name</option>
          <option value="Contact.company">Contact.company</option>
          <option value="Contact.phone">Contact.phone</option>
        </select>
        <br />
        <label>formula</label>
        <br />
        <input
          type="text"
          value={field.field.formula || ''}
          onChange={(e) =>
            this.onChange({ field: { formula: e.target.value } })
          }
        />
        <br />
        <label>pdf section</label>
        <br />
        <select
          value={field['pdf_section_id'] || ''}
          onChange={(e) => this.onChange({ pdf_section_id: e.target.value })}
        >
          {this.getSectionOptions()}
        </select>
        <br />
        <label>Order</label>
        <br />
        <input
          type="number"
          value={field.order || ''}
          onChange={(e) => this.onChange({ order: e.target.value })}
        />
        <br />
      </div>
    );
  }
}

const borderStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000'
};

const mapStateToProps = (state) => {
  return {
    mlsFields: state.mlsFields.mlsFields
  };
};

export default connect(mapStateToProps)(TextField);
