import React from 'react';
import DateTimeField from './DateTimeField';
import TextField from './TextField';
import { newTextField, newDateTimeField } from './Util';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

class CompositeFieldElement extends React.Component {
  constructor(props) {
    super(props);
  }

  updateField = (field) => {
    // keep name and key fields in sync
    field.field.name = this.props.field.name;
    field.field.key = this.props.field.name;

    this.props.onChange({ field: field });
  };

  setField = (type) => {
    let field;
    let newExternalId = uuidv4();
    switch (type) {
      case 'datetime':
        field = newDateTimeField(this.props.pdfTemplate.id, newExternalId);
        break;
      case 'text':
        field = newTextField(this.props.pdfTemplate.id, newExternalId);
        break;
    }

    this.updateField(field);
  };

  render() {
    if (this.props.field.field.field != null) {
      let element;

      switch (this.props.field.field.field['type']) {
        case 'datetime':
          element = (
            <DateTimeField
              field={this.props.field.field}
              name={this.props.field.name}
              mode={'date'}
              sections={[]}
              onChange={this.updateField}
              subField={true}
            />
          );
          break;
        case 'text':
          element = (
            <TextField
              field={this.props.field.field}
              name={this.props.field.name}
              sections={[]}
              onChange={this.updateField}
              subField={true}
            />
          );
      }

      return <div>{element}</div>;
    } else {
      return (
        <div>
          <button onClick={(e) => this.setField('text')}>Text</button>
          <button onClick={(e) => this.setField('datetime')}>Datetime</button>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    pdfTemplate: state.pdfTemplates.pdfTemplate
  };
};

export default connect(mapStateToProps)(CompositeFieldElement);
