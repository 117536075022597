import React from 'react';
import TextField from '../pdf_annotator/TextField';
import DateTimeField from '../pdf_annotator/DateTimeField';
import SignatureField from '../pdf_annotator/SignatureField';
import {
  createPdfFieldForPdfTemplate,
  updatePdfFieldForPdfTemplate
} from '../shared/Network';
import HtmlDocumentTree from './HtmlDocumentTree';
import RadioField from '../pdf_annotator/RadioField';
import CompositeField from '../pdf_annotator/CompositeField';
import InitialField from '../pdf_annotator/InitialField';
import SignatureDateField from '../pdf_annotator/SignatureDateField';
import CheckboxField from '../pdf_annotator/CheckboxField';
import {
  clearPdfField,
  pdfTemplateFieldRequested,
  pdfTemplateFetchRequested,
  updatePdfTemplateRequest,
  pdfTemplateFieldsRequested
} from './redux/actions/pdfTemplateActions';
import { connect } from 'react-redux';

class HtmlFieldForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      params: {},
      showJsonPreview: false,
      formField: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.pdf !== prevProps.pdf) {
      this.props.clearPdfField();
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
  };

  save = () => {
    let field = this.getFieldParams();

    if (field.id == null) {
      createPdfFieldForPdfTemplate(this.props.pdfTemplate.id, field).then(
        (response) => {
          response.json().then(this.handleResponse);
        }
      );
    } else {
      updatePdfFieldForPdfTemplate(
        this.props.pdfTemplate.id,
        field.id,
        field
      ).then((response) => {
        response.json().then(this.handleResponse);
      });
    }
  };

  previewJson = () => {
    this.setState({ showJsonPreview: true });
  };

  handleResponse = (json) => {
    if (json.errors == null) {
      this.props.updateHtmlTemplate(
        this.props.pdfTemplate.id,
        this.props.pdfTemplate.html_template
      );
      this.props.clearPdfField();
      this.props.fetchPdfTemplate(this.props.pdfTemplate.id);
      this.props.updatePdfFields(this.props.pdfTemplate.id);
    } else {
      console.log('Errors: ' + JSON.stringify(json.errors));
    }
  };

  getFieldParams = () => {
    let field = JSON.parse(JSON.stringify(this.props.pdfField));
    if (field == null) field = {};

    // remove the id from new fields
    if (typeof field.id === 'string' && field.external_id.startsWith('u_')) {
      delete field.external_id;
    }

    if (typeof field.id === 'string' && field.id.startsWith('u_')) {
      delete field.id;
    }

    if (field.field != null && field.field.value == '') {
      delete field.field.value;
    }

    return field;
  };

  close = () => {
    this.props.clearPdfField(this.props.close);
  };

  setField = (e) => {
    let value = e.target.value;
    if (value !== '') {
      this.props.setPdfField(this.props.pdfTemplate.id, value);
    }
  };

  getFieldOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a field ---
      </option>
    );
    {
      this.props.pdfFields.forEach((field) => {
        options.push(
          <option key={field.id} value={field.id}>
            {field.name}
          </option>
        );
      });
    }
    return options;
  };

  getFieldActions = () => {
    return (
      <div>
        <button onClick={this.save}>Save</button>
        <button onClick={this.close}>Cancel</button>
        <button onClick={this.props.delete}>Delete</button>
        <button onClick={this.previewJson}>Preview JSON</button>
      </div>
    );
  };

  getTextElement = () => {
    return (
      <TextField
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getSignatureElement = () => {
    return (
      <SignatureField
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getInitialElement = () => {
    return (
      <InitialField
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getSignatureDateElement = () => {
    return (
      <SignatureDateField
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getDateTimeElement = () => {
    return (
      <DateTimeField
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getCheckboxElement = () => {
    return (
      <CheckboxField
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
        inject={this.props.inject}
      />
    );
  };

  getRadioElement = () => {
    return (
      <RadioField
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
        inject={this.props.inject}
      />
    );
  };

  getCompositeElement = () => {
    return (
      <CompositeField
        pdfDocument={this.props.pdfDocument}
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
        inject={this.props.inject}
      />
    );
  };

  getFieldElement = () => {
    if (this.props.pdfField == null) {
      return null;
    }
    switch (this.props.pdfField.field_type) {
      case 'text':
      case 'text_area':
        return this.getTextElement();
      case 'datetime':
        return this.getDateTimeElement();
      case 'radio':
        return this.getRadioElement();
      case 'checkbox':
        return this.getCheckboxElement();
      case 'composite':
        return this.getCompositeElement();
      case 'signature':
        return this.getSignatureElement();
      case 'initials':
        return this.getInitialElement();
      case 'date_signed':
        return this.getSignatureDateElement();
      default:
        return null;
    }
  };

  injectField = () => {
    // TODO: Differentiate between different field types
    let injectableText = '';
    switch (this.props.pdfField.field_type) {
      case 'text_area': //Keep this the same as a text for now. Also datetime will be the same.
      case 'text':
      case 'datetime':
        injectableText = `<span class="field" style="border-bottom: 1px solid #000;" th:if="\${fields.containsKey('${this.props.pdfField.id}')}">
    <span th:text="\${fields['${this.props.pdfField.id}']}"></span>
</span>
<span style="border-bottom: 1px solid #000; min-width: 250px; display: inline-block;" th:unless="\${fields.containsKey('${this.props.pdfField.id}')}"></span>`;
        break;
      case 'signature':
      case 'initials':
        injectableText = `<span style="border-bottom: 1px solid #000; display:block;">
  <form style="margin: 0; padding: 0; display: block;">
    <input class="field" type="text" name="${this.props.pdfField.id}" value="${this.props.pdfField.name}" style="border: none;" />
  </form>
</span>`;
        break;
      case 'date_signed':
        injectableText = `<span style="border-bottom: 1px solid #000; display:block;">
  <form style="margin: 0; padding: 0; display: block;">
    <input class="field" type="text" name="${this.props.pdfField.id}" value="${this.props.pdfField.name}_date" style="border: none;" />
  </form>
</span>`;
      default:
        break;
    }
    this.props.inject(injectableText);
  };

  showInjectFieldButton() {
    return (
      this.props.pdfField &&
      [
        'text',
        'text_area',
        'datetime',
        'signature',
        'date_signed',
        'initials'
      ].includes(this.props.pdfField.field_type) &&
      !this.props.pdfField.id.startsWith('u_')
    );
  }

  render() {
    const previewJson = (
      <div style={jsonPreview}>
        <pre>{JSON.stringify(this.getFieldParams(), undefined, 4)}</pre>
        <button onClick={() => this.setState({ showJsonPreview: false })}>
          Close
        </button>
      </div>
    );

    return (
      <div>
        <div>
          <button
            onClick={() => {
              this.props.newField('text');
            }}
          >
            Text Field
          </button>
          <button
            onClick={() => {
              this.props.newField('text_area');
            }}
          >
            Text Area Field
          </button>
          <button
            onClick={() => {
              this.props.newField('datetime');
            }}
          >
            DateTime Field
          </button>
          <button
            onClick={() => {
              this.props.newField('radio');
            }}
          >
            Radio Field
          </button>
          <button
            onClick={() => {
              this.props.newField('checkbox');
            }}
          >
            Checkbox Field
          </button>
          <button
            onClick={() => {
              this.props.newField('composite');
            }}
          >
            Composite Field
          </button>
          <button
            onClick={() => {
              this.props.newField('signature');
            }}
          >
            Signature Field
          </button>
          <button
            onClick={() => {
              this.props.newField('initials');
            }}
          >
            Initial Field
          </button>
          <button
            onClick={() => {
              this.props.newField('date_signed');
            }}
          >
            Signature Date Field
          </button>
        </div>
        <form onSubmit={this.onSubmit}>
          {/*<select onChange={this.setField} value={this.props.pdfField == null ? "" : this.props.pdfField.id}>{this.getFieldOptions()}</select>*/}
          <br />
          {this.showInjectFieldButton() ? (
            <button onClick={() => this.injectField()}>Inject Field</button>
          ) : (
            ''
          )}
          <br />
          <div>
            <br />
            {this.props.pdfField != null ? this.props.pdfField.id : ''}
          </div>
          {/*This is where it's not working. For some reason, the field element isn't showing up.*/}
          {this.getFieldElement()}
          {this.state.showJsonPreview ? previewJson : null}
          {this.props.pdfField != null ? this.getFieldActions() : null}
        </form>
        <h3>Fields</h3>
        <HtmlDocumentTree
          onClick={(id) =>
            this.props.setPdfField(this.props.pdfTemplate.id, id)
          }
        />
      </div>
    );
  }
}

const jsonPreview = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000'
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPdfField: (pdfTemplateId, pdfFieldId = null) =>
      dispatch(pdfTemplateFieldRequested(pdfTemplateId, pdfFieldId)),
    fetchPdfTemplate: (pdfTemplateId) =>
      dispatch(pdfTemplateFetchRequested(pdfTemplateId)),
    clearPdfField: () => dispatch(clearPdfField()),
    updateHtmlTemplate: (pdfTemplateId, htmlTemplate) =>
      dispatch(updatePdfTemplateRequest(pdfTemplateId, htmlTemplate)),
    updatePdfFields: (pdfTemplateId) =>
      dispatch(pdfTemplateFieldsRequested(pdfTemplateId))
  };
};

const mapStateToProps = (state) => {
  return {
    pdfFields: state.pdfTemplates.pdfFields,
    pdfField: state.pdfTemplates.pdfField
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HtmlFieldForm);
