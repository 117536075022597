import { Controller } from '@hotwired/stimulus';
import * as dragula from 'dragula';

/**
 * Given containers, can move their 'immediate-children' from one to the other.
 * Reordering within a container is also possible.
 *
 * All immediate children used by default.
 * Use childIgnore to exclude child from drag and drop.
 *
 * Sends a Request after each drop.
 *
 * data attributes used:
 *
 * div.data-controller='reorder': this div becomes stimulus-div
 * stimulus-div.data-reorder-url: where the patch request is sent.
 *
 * container.data-reorder-target='container': Become a container for Dragula
 * container.data-reorder-container-id: Container's backend ID
 *
 * child.data-reorder-child-id: child's backend ID
 * child.data-reorder-target='childIgnore': Will not get dragged or reordered
 *
 * Sends a request to data-reorder-url in the form of:
 * {<container's id>: {<child's id>: order}}
 * {container_id: {child_id: order}}
 */
export default class extends Controller {
  static targets = ['container', 'childIgnore', 'form'];

  static values = {
    nested: Boolean
  }

  connect() {
    this.setupDrake();
  }

  disconnect() {
    super.disconnect();
    this.drake.destroy();
  }

  setupDrake() {
    const childIgnoreTargets = this.childIgnoreTargets; // const reference to use in drake
    // rewrite functions drake uses
    let options = {
      invalid: function (el, handle) {
        // If to be ignored, then it's invalid and won't drag
        return childIgnoreTargets.includes(el); // Else false (i.e. valid)
      }
    };
    this.drake = dragula(this.containerTargets, options);
    this.addDropCallback();
  }

  addDropCallback() {
    this.drake.on('drop', (el) => {
      this.updateOrders(el);
    });
  }

  // {new_order: [{class_name:, id:, children: [{class_name:, id:, order:}]}]}
  updateOrders(el) {
    let new_order = this.getNewOrder(); // Assuming this method prepares your order data

    // Clear any 'new_order' hidden inputs
    const hiddenInputs = this.formTarget.querySelectorAll(
      'input[type="hidden"][name="new_order"]'
    );
    hiddenInputs.forEach((input) => input.remove());

    // Create hidden input
    let hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = 'new_order';
    hiddenInput.value = JSON.stringify(new_order);

    // Append the hidden input to the form
    this.formTarget.appendChild(hiddenInput);

    // Find and click the submit button
    // Do it this way for Turbo

    let method = el.querySelector('input[type="hidden"]')

    if (this.nestedValue === true && method.value !== 'delete') {
      let previousItem = el.previousElementSibling;
      const previousOrder = previousItem ? parseInt(previousItem.getAttribute('data-order')) : 0;

      let input = el.querySelector("[name='document_template_pdf[order]']")

      input.value = previousOrder + 1;

      let submit = el.querySelector('input[type="submit"]')
      submit.click();
    } else {
      let submitButton = this.formTarget.querySelector(
        'button[type="submit"], input[type="submit"]'
      );
      submitButton.click();
    }
  }

  getNewOrder() {
    // new_order: [{<parent_data>, children: [{<child_data>}, ]}, ]
    let new_order = [];

    this.containerTargets.forEach((container, containerIndex) => {
      let parent_data = {};

      parent_data['class_name'] = container.dataset.reorderContainerType;
      parent_data['id'] = container.dataset.reorderContainerId;
      parent_data['order'] = containerIndex;
      parent_data['children'] = [];

      Array.from(container.children).forEach((child, index) => {
        let child_data = {};

        const childType = child.dataset.reorderChildType;
        const childId = child.dataset.reorderChildId;

        if (childId === undefined || childType === undefined) {
          return;
        }

        child_data['class_name'] = childType;
        child_data['id'] = childId;
        child_data['order'] = index;

        parent_data['children'].push(child_data);
      });

      new_order.push(parent_data);
    });

    return new_order;
  }
}
