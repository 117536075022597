import React, { useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { Button, CircularProgress, ThemeProvider } from '@mui/material';
import theme from '../shared/theme';
import { Stack } from '@mui/material';
import useEnsureAuth from '../shared/hooks/ensureAuth';
import Request from '../shared/services/Request';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function App(props) {
  const { flowId } = props;
  const [open, setOpen] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const authLoaded = useEnsureAuth();

  useEffect(() => {
    if (open) {
      submitForm();
    }
  }, [open, flowId]);

  const submitForm = async () => {
    const form = document.getElementById('document_input_form');

    if (form) {
      const formData = new FormData(form);
      formData.append('preview', 'true');
      const actionUrl = form.action;

      setLoading(true);

      await Request.post(actionUrl, formData,
        (data, headers) => {
          setPdfUrl(data.pdf_url);
          setLoading(false);

          const documentId = data.document_id;
          form.action = `/documents/${documentId}`;

          const methodInput = form.querySelector('input[name="_method"]');
          if (methodInput) {
            methodInput.value = 'put';
          } else {
            form.appendChild(Object.assign(document.createElement('input'), {
              type: 'hidden',
              name: '_method',
              value: 'put'
            }));
          }
        },
        (error) => {
          setLoading(false);
        },
        'multipart/form-data'
      );
    }
  };

  if (!authLoaded) {
    return <CircularProgress />;
  }

  const onPreview = () => {
    setOpen(true);
  };

  const handleOnCloseModal = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Button variant="outlined" onClick={onPreview}>
        <Stack direction="row" gap={1}>
          <VisibilityIcon style={{marginTop: '2px'}}/>
          Preview Document
        </Stack>
      </Button>
      <Dialog
        open={open}
        onClose={handleOnCloseModal}
        maxWidth="lg"
      >
        <Stack direction="row" justifyContent="center" alignItems="center" style={{ height: '85vh' }}>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '850px' }}>
              <CircularProgress />
            </div>
          ) : (
            <div style={{ width: '850px', height: '100%' }} className="p-2">
              <iframe src={pdfUrl} style={{ border: 0, height: '100%', width: '100%' }} />
            </div>
          )}
        </Stack>
      </Dialog>
    </ThemeProvider>
  );
}