import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['cancel', 'form', 'input'];
  connect() {
    this.cancelTarget.addEventListener('click', (evt) => {
      this.inputTarget.value = '';
      Rails.fire(this.formTarget, 'submit');
    });
  }
}
