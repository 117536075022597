import React from 'react';
import { Outlet } from "react-router-dom";
import Sidebar from '../components/Sidebar';
import { Container, Stack } from '@mui/material';

export default function Root() {
  return (
    <>
      <Sidebar>
        <Container disableGutters maxWidth={false}>
          <Stack py={{ xs: 2, sm: 4 }}>
            <Outlet />
          </Stack>
        </Container>
      </Sidebar>
    </>
  )
}