import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static values = {
    id: String
  };

  connect() {}

  close(event) {
    if (this.idValue === event.detail.id) {
      const modal = Modal.getInstance(document.getElementById(this.idValue));
      modal.hide();
    }
  }
}
