import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { EMAIL_SOURCE_FETCH_REQUESTED } from '../actions/emailSourceActions';

import { getHeaders, getApiBaseUrl } from '../../../shared/Network';

export function* getMessageView(action) {
  try {
    let { emailSourceId, callback } = action.payload;

    let url = getApiBaseUrl() + '/email_sources/' + emailSourceId;

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield call(getHeaders)
    });

    if (response.status >= 400) {
      console.log('Error getting PdfTemplates: ' + JSON.stringify(response));
    } else {
      const responseJson = yield response.json();
      callback(responseJson);
    }
  } catch (e) {
    console.log('Error getting Message ' + e.message);
  }
}

export default function* rootSaga() {
  yield takeLatest(EMAIL_SOURCE_FETCH_REQUESTED, getMessageView);
}
