import React from 'react';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, FormControl, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import Request from '../../shared/services/Request';

export default function RequestDocument({ toggleModal, showRequestModal, contact }) {
  const defaultValues = {
    email_recipients: `${contact.email},`,
    email_subject: '',
    email_body: '',
  };

  const { handleSubmit, control, setError } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    const requestData = {
      ...data,
      email_recipients: data.email_recipients.split(',').map(email => email.trim()),
    };

    const onSuccess = () => {
      toggleModal();
    };

    const onFailure = () => {
      setError('email_recipients', {
        type: 'manual',
        message: 'An error occurred while requesting documents. Please try again.',
      });
    };

    Request.post(`/v2/contacts/${contact.id}/request_documents`, requestData, onSuccess, onFailure);
  };

  return (
    <Dialog open={showRequestModal || false} onClose={toggleModal} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          Request Documents
          <IconButton
            edge="end"
            color="inherit"
            onClick={toggleModal}
            aria-label="close"
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <Controller
              name="email_recipients"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Recipients (comma separated)"
                    multiline
                    rows={2}
                    {...field}
                  />
                  <FormHelperText>{fieldState.error?.message}</FormHelperText>
                </>
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Controller
              name="email_subject"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Subject"
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Controller
              name="email_body"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Message"
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            type="submit"
          >
            Request Documents
          </Button>
          <Button onClick={toggleModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
