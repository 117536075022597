import { create } from "zustand";
import { persist } from 'zustand/middleware';

const useAlertStore = create(
  persist((set) => ({
    alertMessage: null,
    alertType: null,
    sideBarAlert: null,
    setAlertMessage: (message, alertType) => set({ alertMessage: message, alertType: alertType || "info"}),
    setAlertType: (alertType) => set({ alertType: alertType }),
    clearAlerts: () => set({ alertMessage: null, alertType: null }),
    clearSideBarAlert: () => set({ sideBarAlert: null }),
    setSideBarAlert: (message) => set({ sideBarAlert: message }),
  }),
  {
    name: 'alert-storage', // unique name
  })
);

export default useAlertStore;