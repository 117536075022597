import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import Canvas from './canvas';
import { useCanvas } from './hooks/useCanvas';
import {
  onMouseDown,
  onMouseUp,
  onMouseMove,
  onRightClick,
  onKeyDown,
  drawFields
} from './pdf_utils/actions';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import SignatureIcon from 'images/icon-signature.svg';
import InitialsIcon from 'images/icon-initials.svg';
import TextIcon from 'images/icon-text.svg';
import CalendarIcon from 'images/icon-calendar.svg';
import CheckboxIcon from 'images/icon-checkbox.svg';

export default function Body(props) {
  const [pdf, setPdf] = useState(null);
  const [canvases, setCanvases] = useState([]);
  const [loadingCanvas, setLoadingCanvas] = useState(true);
  const [canvasState, _setCanvasState] = useState({
    canvas: null,
    dragging: false,
    resizing: false,
    resizeCorner: null,
    deleting: false,
    dragOffX: 0,
    dragOffY: 0,
    fields: [],
    field: null,
    pages: [],
    width: 0,
    setActiveField: null,
    activeField: null,
    updateField: null,
    onDelete: null,
    startCoord: null,
    changeActiveAction: null,
    activeAction: null,
    actionImage: null,
    actionMouse: null,
    signatureImage: null,
    initialsImage: null,
    contactDetails: null
  });

  const dispatch = useDispatch();

  const canvasRef = useRef(null);
  const canvasStateRef = useRef(canvasState);
  const setCanvasState = (data) => {
    canvasStateRef.current = data;
    _setCanvasState(data);
  };

  const apiFields = useSelector((state) => state.pdfOverlay.entities);

  // Load the PDF Canvas objects
  useEffect(() => {
    if (pdf === null || pdf === undefined) {
      return [];
    } else {
      let canvasArr = [];
      let pages = [];
      for (let i = 0; i < pdf.numPages; i++) {
        pdf.getPage(i + 1).then((page) => {
          let viewPort = page.getViewport({ scale: 1.0 });
          let canvas = (
            <Canvas
              canvasRef={null}
              key={'page' + i}
              width={viewPort.width}
              height={viewPort.height}
              draw={(context) => {
                page.render({ canvasContext: context, viewport: viewPort });
              }}
            />
          );
          canvasArr.push(canvas);
          // Keep track of the start pixel of the current page
          pages[i] = i === 0 ? viewPort.height : pages[i - 1] + viewPort.height;

          if (i + 1 === pdf.numPages) {
            setCanvases(canvasArr);
            // setPageSizes(pages);
            // setPageWidth(viewPort.width);
            updateCanvasState(
              { width: viewPort.width, pages: pages },
              'Body#useEffect'
            );
          }
        });
      }
    }
  }, [pdf]);

  useEffect(() => {
    if (props.pdfUrl !== null && props.pdfUrl !== undefined) {
      pdfjsLib.getDocument(props.pdfUrl).promise.then((doc) => {
        setPdf(doc);
      });
    } else {
      console.log('props.pdfUrl is undefined');
    }
  }, [props.pdfUrl]);

  // Add fields and canvas to state
  useEffect(() => {
    setCanvasState({
      ...canvasState,
      fields: apiFields,
      canvas: canvasRef.current,
      updateField: props.updateField,
      onDelete: props.onDelete,
      activeField: props.activeField,
      setActiveField: props.setActiveField,
      activeAction: props.activeAction,
      changeActiveAction: props.changeActiveAction,
      createField: props.createField,
      contactDetails: props.contactDetails,
      actionImage: actionImage,
      signatureImage: props.signatureImage,
      initialsImage: props.initialsImage,
      log: props.log
    });
  }, [
    apiFields,
    canvasRef,
    props.activeAction,
    props.activeField,
    props.contactDetails,
    props.log,
    props.signatureImage,
    props.initialsImage
  ]);

  useEffect(() => {
    if (
      canvasState.fields !== null &&
      canvasState.fields !== undefined &&
      canvasRef !== null &&
      canvasState.pages.length > 0 &&
      props.contactDetails !== undefined &&
      props.contactDetails !== null
    ) {
      // TODO: Figure out something better than waiting 1s before drawing the first time
      if (loadingCanvas) {
        setLoadingCanvas(false);

        canvasRef.current.addEventListener(
          'touchstart',
          (e) => {
            updateCanvasState(
              onMouseDown(e, canvasStateRef.current),
              'touchstart'
            );
          },
          { passive: false }
        );
        canvasRef.current.addEventListener(
          'touchmove',
          (e) => {
            updateCanvasState(
              onMouseMove(e, canvasStateRef.current),
              'touchmove'
            );
          },
          { passive: false }
        );
        canvasRef.current.addEventListener(
          'touchend',
          (e) => {
            updateCanvasState(onMouseUp(e, canvasStateRef.current), 'touchend');
          },
          { passive: false }
        );
        canvasRef.current.addEventListener(
          'contextmenu',
          (e) => {
            updateCanvasState(onRightClick(e, canvasStateRef.current), 'contextmenu');
          }
        )
        // Remove actionImg if on the
        canvasRef.current.addEventListener('mouseleave', (e) => {
          updateCanvasState({ actionMouse: null }, 'mouseleave');
        });
        canvasRef.current.addEventListener(
          'keydown',
          (e) => {
            console.log(e.key);
            updateCanvasState(onKeyDown(e, canvasStateRef.current), 'keydown');
            if (
              e.key === 'ArrowLeft' ||
              e.key === 'ArrowUp' ||
              e.key === 'ArrowDown' ||
              e.key === 'ArrowRight'
            ) {
              e.preventDefault();
            } else if (e.key === 'Backspace' || e.key === 'Delete') {
              e.preventDefault();
            }
          },
          { passive: false }
        );
      }
      let changedFields = drawFields(canvasState);

      // If fields have changed in drawFields then update the state
      if (
        changedFields !== undefined &&
        changedFields !== null &&
        Object.keys(changedFields).length > 0
      ) {
        updateCanvasState({ fields: changedFields });
      }
    }
  }, [
    canvasRef,
    canvasState.fields,
    canvasState.pages,
    canvasState.actionMouse,
    canvasState.activeField,
    props.contactDetails
  ]);

  const updateCanvasState = (partialState, source) => {
    const newState = { ...canvasStateRef.current, ...partialState };
    setCanvasState(newState);
  };

  const actionImage = (action) => {
    let img = new Image();

    switch (action) {
      case 'signature':
        img.src = InitialsIcon;
        return img;
      case 'initials':
        img.src = InitialsIcon;
        return img;
      case 'date_signed':
        img.src = InitialsIcon;
        return img;
      case 'text':
        img.src = InitialsIcon;
        return img;
      case 'checkbox':
        img.src = InitialsIcon;
        return img;
    }
  };

  return (
    <Container
      style={{
        position: 'relative',
        overflowY: 'scroll',
        height: '900px',
        border: '1px solid #000',
        padding: 0
      }}
    >
      <div
        style={{
          width: canvasStateRef.current.width,
          margin: '0 auto',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0
        }}
      >
        <Canvas
          id="pdfOverlayCanvas"
          // style={{touchAction: 'none'}}
          width={canvasStateRef.current.width}
          height={
            canvasStateRef.current.pages[
              canvasStateRef.current.pages.length - 1
            ]
          }
          canvasRef={canvasRef}
          onMouseDown={(e) => {
            updateCanvasState(
              onMouseDown(e, canvasStateRef.current),
              'onMouseDown'
            );
          }}
          onMouseUp={(e) => {
            updateCanvasState(
              onMouseUp(e, canvasStateRef.current),
              'onMouseUp'
            );
          }}
          onMouseMove={(e) => {
            updateCanvasState(
              onMouseMove(e, canvasStateRef.current),
              'onMouseMove'
            );
          }}
          onKeyDown={(e) => {
            updateCanvasState(
              onKeyDown(e, canvasStateRef.current),
              'onKeyDown'
            );
            if (
              e.key === 'ArrowLeft' ||
              e.key === 'ArrowUp' ||
              e.key === 'ArrowDown' ||
              e.key === 'ArrowRight'
            )
              e.preventDefault();
          }}
          onFocus={(e) => {
            e.target.style.outline = 'none';
          }}
          tabIndex={0}
        />
      </div>
      {canvases.map((canvas, index) => {
        return (
          <Row
            key={'row' + index}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            <Col
              key={'col' + index}
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingTop: 0,
                paddingBottom: 0
              }}
            >
              {canvas}
            </Col>
          </Row>
        );
      })}
    </Container>
  );
}
