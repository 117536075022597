import { Controller } from 'stimulus';

export default class extends Controller {
  deleteCommissionPlanFee(event) {
    event.preventDefault();

    const commissionPlanFeeElement = event.target.closest(
      '.commission-plan-fee'
    );
    const destroyElement = commissionPlanFeeElement.querySelector('.destroy');

    if (confirm('Are you sure you want to delete this deduction?')) {
      destroyElement.value = true;
      commissionPlanFeeElement.style.display = 'none';
    }
  }
}
