/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '@popperjs/core';
import * as bootstrap from 'bootstrap';
import Rails from '@rails/ujs';
import { Turbo, StreamActions } from '@hotwired/turbo';
import { cable } from '@hotwired/turbo-rails';
import TurboPower from 'turbo_power';
import 'controllers';
import 'channels';
import trimCanvas from 'trim-canvas';
import 'dom-autoscroller';
import './turbo_react';

import posthog from 'posthog-js'

const postHogKey = $('meta[name="posthog-key"]').attr('content');

posthog.init(postHogKey,
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

const images = require.context('../images', true);
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

require('@rails/activestorage').start();
Rails.start();

TurboPower.initialize(StreamActions);

$(document).ready(function () {});
