import { createReducer } from '@reduxjs/toolkit';
import {
  PDF_TEMPLATES_FETCHED,
  CLEAR_PDF_TEMPLATE_STATE,
  PDF_TEMPLATE_FETCHED,
  PDF_TEMPLATE_UPDATED,
  PDF_TEMPLATE_FIELDS_FETCHED,
  UPDATE_PDF_FIELD,
  PDF_TEMPLATE_DELETED,
  PDF_TEMPLATE_FIELD_FETCHED,
  CLEAR_PDF_FIELD,
  PDF_DOWNLOADED
} from '../actions/pdfTemplateActions';

const initialState = {
  pdfTemplates: [],
  pdfTemplate: null,
  pdfTemplatesReady: false,
  pdfFields: [],
  pdfField: null,
  rawPdf: null
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PDF_TEMPLATES_FETCHED, (state, action) => {
      state.pdfTemplates = action.payload.pdfTemplates;
    })
    .addCase(PDF_TEMPLATE_FETCHED, (state, action) => {
      state.pdfTemplate = action.payload.pdfTemplate;
    })
    .addCase(PDF_TEMPLATE_UPDATED, (state, action) => {
      if (state.pdfTemplate)
        state.pdfTemplate.html_template =
          action.payload.pdfTemplate.html_template;
    })
    .addCase(PDF_TEMPLATE_FIELDS_FETCHED, (state, action) => {
      state.pdfFields = action.payload.fields;
    })
    .addCase(UPDATE_PDF_FIELD, (state, action) => {
      state.pdfField = action.payload.pdfField;
    })
    .addCase(PDF_TEMPLATE_DELETED, (state, action) => {
      if (action.payload.pdfField?.id != null) {
        let fields = state.pdfFields.filter((field) => {
          return field.id !== action.payload.pdfField.id;
        });
        state.pdfFields = fields;
        state.pdfField = null;
      } else {
        console.log('Error deleting field');
      }
    })
    .addCase(PDF_TEMPLATE_FIELD_FETCHED, (state, action) => {
      state.pdfField = action.payload.fields;
    })
    .addCase(CLEAR_PDF_FIELD, (state, action) => {
      state.pdfField = null;
    })
    .addCase(PDF_DOWNLOADED, (state, action) => {
      state.rawPdf = action.payload.pdf;
    })
    .addCase(CLEAR_PDF_TEMPLATE_STATE, (state, action) => initialState);
});

export default reducer;
