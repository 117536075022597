import React, { useEffect } from 'react';
import { getApiBaseUrl, getApiUrl, getHeaders, sessionToTokenAuth } from '../shared/Network';

export default function App(props) {
  const { transactions, pdfDocument, currentTransaction, transactionFolders, folderDocuments} = props;

  const [transactionId, setTransactionId] = React.useState(currentTransaction?.id || transactions[0]?.id);
  const [folders, setFolders] = React.useState(transactionFolders || []);
  const [folderId, setFolderId] = React.useState(null);
  const [documents, setDocuments] = React.useState(folderDocuments || []);
  const [documentId, setDocumentId] = React.useState(null);

  useEffect(() => {
    if(sessionStorage.getItem('auth')) {
      fetchFolders()
    } else {
      sessionToTokenAuth(fetchFolders)
    }
  }, [transactionId])

  const fetchFolders = () => {
    let url =
      getApiUrl() +
      `/transactions/${transactionId}/documents`;

    fetch(url, {
      headers: getHeaders()
    })
      .then((response) => response.json())
      .then((data) => {
        setFolders(data);
        setFolderId(data[0]?.id);
      }
    );
  }

  useEffect(() => {
    const documents = folders.filter((folder) => folder.id === folderId)[0]?.folder_resources;
    console.log(documents)
    if(documents) {
      setDocuments(documents);
    }
  }, [folderId])

  const handleSubmit = () => {
    let url =
      getApiUrl() +
      `/new_documents/${pdfDocument.id}`;
    fetch(url, {
      headers: getHeaders(),
      method: 'PATCH',
      body: JSON.stringify({
        transaction_id: transactionId,
        folder_id: folderId,
        folder_resource_id: documentId
      })
    })
      .then((response) => response.json())
      .then((data) => {
        window.location = getApiUrl() + '/transactions/' + transactionId + '/documents' + `#${pdfDocument.hashid}`;
      });
  }

  return (
    <div>
      <h5>Document Assigner</h5>
      <div>
        <div>
          <label>
            <b>
              Select Transaction
            </b>
          </label>
        </div>
        <div>
          <select value={transactionId} onChange={(e) => setTransactionId(e.target.value)} className='form-control'>
            {
              transactions?.map((transaction) => (
                <option key={transaction.id} value={transaction.id}>
                  #{transaction.company_transaction_id} - {transaction.name}
                </option>
              ))
            }
          </select>
        </div>
      </div>
      {folders?.length > 0 && <div>
        <div>
          <label>
            <b>
              Select Folder
            </b>
          </label>
        </div>
        <div>
          <select value={folderId} className='form-control' onChange={(e) => setFolderId(e.target.value)}>
            {
              folders?.map((folder) => (
                <option key={folder.id} value={folder.id}>
                  {folder.name}
                </option>
              ))
            }
          </select>
        </div>
      </div>}
      {documents?.length > 0 && <div className='mb-2'>
        <div>
          <label>
            <b>
              Select Document to Replace
            </b>
          </label>
        </div>
        <div>
          <select value={documentId} className='form-control' onChange={(e) => setDocumentId(e.target.value)}>
            <option value={0}>Create new document</option>
            {
              documents?.map((document) => (
                <option key={document.id} value={document.id}>
                  {document?.folder_resourcable?.name}
                </option>
              ))
            }
          </select>
        </div>
      </div>}
      <button className="btn btn-success" onClick={handleSubmit}>
        Confirm
      </button>
    </div>
  )
}
