import React from 'react';
import { connect } from 'react-redux';
import { getMlsFields } from './redux/actions/textFieldActions';
import ToggleFields from './ToggleFields';

class CheckboxOption extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(getMlsFields());

    this.state = {
      showFields: true
    };
  }

  getMlsFieldOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a field ---
      </option>
    );
    {
      this.props.mlsFields.forEach((field) => {
        options.push(
          <option key={field} value={field}>
            {field}
          </option>
        );
      });
    }
    return options;
  };

  getTextField = () => {
    let textField = this.props.option.text_field;

    if (textField != null) {
      return (
        <div>
          <p>
            <b>Text Field</b>
          </p>
          <input
            type="text"
            value={textField.name}
            onChange={(e) =>
              this.onChange('text_field', { name: e.target.value })
            }
          />
          <br />
          <label>MLS Field</label>
          <br />
          <select
            value={textField.mls_field || ''}
            onChange={(e) =>
              this.onChange('text_field', { mls_field: e.target.value })
            }
          >
            {this.getMlsFieldOptions()}
          </select>
          <br />
          <label>placeholder</label>
          <br />
          <input
            type="text"
            value={textField.placeholder || ''}
            onChange={(e) =>
              this.onChange('text_field', { placeholder: e.target.value })
            }
          />
          <br />
          <label>Checkbox</label>
          <input
            type="checkbox"
            checked={textField.checkbox || ''}
            onChange={this.updateCheckbox}
          />
        </div>
      );
    } else {
      return (
        <a
          href="#"
          onClick={(e) => {
            let option = Object.assign({}, this.props.option);
            option.text_field = {
              name: 'option_text_field' + this.props.index,
              checkbox: true
            };
            this.props.onChange(this.props.index, option);
            e.preventDefault();
          }}
        >
          + Add text field
        </a>
      );
    }
  };

  updateCheckbox = (event) => {
    let option = { ...this.props.option };
    option.text_field = { ...option.text_field }; //deep copy of data, getting read only error

    let checked = event.target.checked;

    if (checked) {
      option.text_field.checkbox = checked;
      this.props.onChange(this.props.index, option);
      this.setState({ showFields: checked });
    } else {
      this.setState({ showFields: checked }, () => {
        option.text_field.checkbox = checked;
        this.props.onChange(this.props.index, option);
      });
    }
  };

  onChange = (key, data) => {
    let option = { ...this.props.option };
    option.text_field = { ...option.text_field }; //deep copy of data, getting read only error

    let textField = option.text_field;

    if (key === 'text_field') {
      textField = Object.assign(textField, data);
      option['text_field'] = textField;
    } else {
      option[key] = data;
    }

    this.props.onChange(this.props.index, option);
  };

  onToggleChange = (toggleFields) => {
    this.onChange('toggle_fields', toggleFields);
  };

  render() {
    return (
      <div>
        <label>label</label>
        <br />
        <input
          type="text"
          value={this.props.option.label || ''}
          onChange={(e) => this.onChange('label', e.target.value)}
        />
        <br />
        <label>value</label>
        <br />
        <input
          type="text"
          value={this.props.option.value || ''}
          onChange={(e) => this.onChange('value', e.target.value)}
        />
        <br />
        {this.getTextField()}
        <br />
        <ToggleFields
          toggleFields={this.props.option['toggle_fields']}
          fields={this.props.fields}
          onChange={this.onToggleChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mlsFields: state.mlsFields.mlsFields,
    pdfField: state.pdfTemplates.pdfField
  };
};

export default connect(mapStateToProps)(CheckboxOption);
