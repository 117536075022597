import React, { useState } from 'react';
import TextField from '../pdf_annotator/TextField';
import DateTimeField from '../pdf_annotator/DateTimeField';
import SignaturesField from './signaturesField';
import {
  createPdfFieldForPdfTemplate,
  getApiBaseUrl,
  getApiUrl,
  getHeaders,
  updatePdfFieldForPdfTemplate
} from '../shared/Network';
import { connect, useDispatch, useSelector } from 'react-redux';
import { newFieldCopy } from '../shared/pdf_field_utils';

export default function DocumentDescriptionField(props) {
  const pdfTemplates = useSelector((state) => state.pdfTemplates.pdfTemplates);
  const pdfTemplate = pdfTemplates.filter((pdfTemplate) => pdfTemplate.id === props.pdfTemplate.id)[0];
  const [ addendedPdfTemplateId, setAddendedPdfTemplateId ] = useState(pdfTemplate.addended_pdf_template_id);
  const [ description, setDescription ] = useState(pdfTemplate.description);

  const handleUpdate = () => {
    const url = getApiBaseUrl() + '/pdf_templates/' + props.pdfTemplate.id
    fetch(
      url,
      {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify({
          description: description
        })
      }
    )
  }

  console.log(addendedPdfTemplateId)

  return (
    <div>
      <div>
        <b>Document</b>
      </div>
      <div>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)}/>
      </div>
      <button className='btn btn-primary' onClick={handleUpdate}>Update</button>
    </div>
  );
}

