import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link'];

  connect() {
    this.element.classList.add('hover-link');
    this.element.addEventListener('click', this.clickLink.bind(this));
  }

  clickLink(event) {
    let element = event.target;

    // Check if we have clicked another link.
    // If we have, don't trigger this controller's link
    while (element && element !== document) {
      if (
        element.tagName.toLowerCase() === 'a' ||
        element.tagName.toLowerCase() === 'button'
      ) {
        // Clicked inside a link or button, stop here
        return;
      }
      element = element.parentNode;
    }

    this.linkTarget.click();
  }
}
