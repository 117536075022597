import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

/**
 * Structure: form -> requiredInput, requiredMessage, submit
 *
 * This controller is designed to ensure all required fields in a form are filled
 * out before allowing the form to be submitted. If a required input is empty,
 * a corresponding message will be shown to the user. If all required inputs
 * are filled, the form submits normally.
 *
 * data attributes used:
 *
 * form.data-controller='form-validation': The form to be validated.
 * form.data-action="submit->form-validation#validate": Action binding to validate form upon submission.
 *
 * input.data-formValidation-target="requiredInput": Marks an input field as required.
 * input.data-formValidation-name="<name>": Associates this input with a specific validation message.
 *
 * span.data-formValidation-target="requiredMessage": Marks an element as a validation message.
 * span.data-formValidation--id="<ID>": The validation message for the input with matching ID.
 *
 * button.data-formValidation-target="submit": The button that submits the form.
 *
 * When a form is submitted:
 * - All input fields marked with 'requiredInput' are checked for values.
 * - If an input is empty, its associated 'requiredMessage' is displayed.
 * - If all inputs have values, the form is submitted.
 * - If not, submission is prevented and the user is notified.
 */
export default class extends Controller {
  static targets = ['requiredInput', 'requiredMessage', 'submit'];

  connect() {
    this.hideAllMessages();
    this.addEventListeners();
  }

  hideAllMessages() {
    this.requiredMessageTargets.forEach((message) =>
      message.classList.add('hide')
    );
  }

  addEventListeners() {
    this.submitTarget.addEventListener('click', (event) =>
      this.validate(event)
    );
  }

  validate(event) {
    let validCheckbox = this.validateCheckboxes();
    let validText = this.validateText();
    let validRadio = this.validateRadios();
    let allFieldsValid = validCheckbox && validText && validRadio;

    if (!allFieldsValid) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.onSuccess();
    }
  }

  validateText() {
    let allFieldsValid = true;

    this.requiredInputTargets.forEach((inputElement, index) => {
      if (inputElement.type === 'checkbox') {
        return;
      }

      const messageId = inputElement.getAttribute('data-formValidation-name');
      const messageElement = this.requiredMessageTargets.find(
        (message) =>
          message.getAttribute('data-formValidation-name') === messageId
      );

      if (inputElement.value.trim() === '') {
        allFieldsValid = false;
        messageElement.classList.remove('hide');
        this.updateElement(false, inputElement);
      } else {
        messageElement.classList.add('hide');
        this.updateElement(true, inputElement);
      }
    });
    return allFieldsValid;
  }

  validateCheckboxes() {
    let allFieldsValid = true;

    // Find all checkboxes
    const checkboxes = this.requiredInputTargets.filter(
      (input) => input.type === 'checkbox'
    );

    // Find unique names for checkboxes
    const uniqueCheckboxNames = [
      ...new Set(checkboxes.map((chk) => chk.dataset.formvalidationName))
    ];

    // Validate each group of checkboxes by their name
    uniqueCheckboxNames.forEach((name) => {
      const checkboxesInGroup = checkboxes.filter(
        (chk) => chk.dataset.formvalidationName === name
      );
      const anyChecked = checkboxesInGroup.some((chk) => chk.checked);

      const messageId = checkboxesInGroup[0].getAttribute(
        'data-formValidation-name'
      );
      const messageElement = this.requiredMessageTargets.find(
        (message) =>
          message.getAttribute('data-formValidation-name') === messageId
      );

      if (!anyChecked) {
        allFieldsValid = false;
        messageElement.classList.remove('hide');
        checkboxesInGroup.forEach((chk) => this.updateElement(false, chk));
      } else {
        messageElement.classList.add('hide');
        checkboxesInGroup.forEach((chk) => this.updateElement(true, chk));
      }
    });

    return allFieldsValid;
  }

  validateRadios() {
    let allFieldsValid = true;

    // Find all radio buttons
    const radios = this.requiredInputTargets.filter(
      (input) => input.type === 'radio'
    );

    // Find unique names for radio buttons
    const uniqueRadioNames = [...new Set(radios.map((radio) => radio.name))];

    // Validate each group of radio buttons by their name
    uniqueRadioNames.forEach((name) => {
      const radiosInGroup = radios.filter((radio) => radio.name === name);
      const anySelected = radiosInGroup.some((radio) => radio.checked);

      const messageName = radiosInGroup[0].getAttribute(
        'data-formValidation-name'
      );
      const messageElement = this.requiredMessageTargets.find(
        (message) =>
          message.getAttribute('data-formValidation-name') === messageName
      );

      if (!anySelected) {
        allFieldsValid = false;
        messageElement.classList.remove('hide');
        radiosInGroup.forEach((radio) => this.updateElement(false, radio));
      } else {
        messageElement.classList.add('hide');
        radiosInGroup.forEach((radio) => this.updateElement(true, radio));
      }
    });

    return allFieldsValid;
  }

  updateElement(isValid, inputElement) {
    if (isValid) {
      inputElement.classList.remove('border-color-danger');
    } else {
      inputElement.classList.add('border-color-danger');
    }
  }

  onSuccess() {
    let modalElement = this.element.closest('.modal');
    const modalInstance = Modal.getInstance(modalElement);
    if (modalInstance) {
      modalInstance.hide();
    }
  }
}
