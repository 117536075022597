import { Button, CircularProgress, Link, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { formatDate } from '../../shared/services/dates';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';

export default function Touchpoint({ contact, touchpoint }) {
  const [loading, setLoading] = React.useState(false);
  const [subject, setSubject] = React.useState('');
  const [body, setBody] = React.useState('');

  useEffect(() => {
    if(touchpoint?.key === 'email') {
      generateEmail()
    }
  }, [])

  const generateEmail = () => {
    setLoading(true);
    Request.get(getApiUrl() + `/v2/contacts/${contact.id}/touchpoints/generate_email`, (response) => {
      setSubject(response.subject);
      setBody(response.body);
      setLoading(false);
    })
  }

  if(!touchpoint) {
    return null;
  }

  if(touchpoint.key === 'email') {
    if(loading) {
      return <CircularProgress />
    }
    return (
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Stack>
            <Typography variant="body2">
              To: {contact.email}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body1" fontWeight="bold">
              {subject}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body1">
              {body}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Stack>
            <Stack direction="row" spacing={2}>
              <Button onClick={() => window.location = `mailto:${contact.email}?subject=${subject}&body=${body}`} variant="contained">
                Open email
              </Button>
              <Button onClick={generateEmail} variant='outlined'>
                Regenerate
              </Button>
            </Stack>
            <Typography variant="caption">
              This email is scheduled to be sent on {formatDate(touchpoint.scheduled_at)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  if(touchpoint.key === 'sms') {
    return (
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Stack>
            <Typography variant="body2">
              To: {contact.phone}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body1">
              {touchpoint.body}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Stack>
            <Button onClick={() => window.location = `sms:${contact.phone}?body=${touchpoint.body}`} variant="contained">
              Open SMS
            </Button>
            <Typography variant="caption">
              This text message is scheduled to be sent on {formatDate(touchpoint.scheduled_at)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack>
      Touchpoint
    </Stack>
  );
} 
