import React from 'react';

export default class DocumentTree extends React.Component {
  onClick = (id, e) => {
    this.props.onClick(id);
    e.preventDefault();
  };

  render() {
    if (this.props.sections == null || this.props.fields == null) return null;

    let filteredFields = this.props.fields.filter((field) =>
      [
        'text',
        'text_area',
        'datetime',
        'radio',
        'checkbox',
        'composite',
        'toggle_button',
        'signature',
        'add_document_button'
      ].includes(field['field_type'])
    );

    return (
      <div>
        <ul>
          {this.props.sections.map((s) => {
            let fields = filteredFields.filter(
              (field) => field['pdf_section_id'] === s.external_id
            );

            return (
              <li key={s.external_id}>
                {s.title}{' '}
                <ul>
                  {fields.map((i) => (
                    <li key={i.external_id}>
                      <a
                        href="#"
                        onClick={(e) => this.onClick(i.external_id, e)}
                      >
                        {i.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
          <li>
            No Section{' '}
            <ul key="no_section">
              {filteredFields
                .filter((field) => field['pdf_section_id'] == null)
                .map((i) => (
                  <li key={i.external_id}>
                    <a href="#" onClick={(e) => this.onClick(i.external_id, e)}>
                      {i.name}
                    </a>
                  </li>
                ))}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}
