import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';

export default function Logout() {
  useEffect(() => {
    Request.delete(`${getApiUrl()}/users/sign_out`, () => {
      localStorage.clear()
      sessionStorage.clear()
      window.location = '/users/sign_in'
    })
  }, [])
  return (
    <Stack>

    </Stack>
  )
}