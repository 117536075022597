import React from 'react';
import { fieldNameFromKey, fieldKeyFromName } from './Util';

export default class InitialField extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = (data) => {
    let field = JSON.parse(JSON.stringify(this.props.field));

    let dataField = data.field;
    delete data.field;

    Object.assign(field.field, dataField);
    Object.assign(field, data);

    this.props.onChange(field);
  };

  getSectionOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a Section ---
      </option>
    );
    {
      this.props.sections.forEach((section) => {
        options.push(
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        );
      });
    }
    return options;
  };

  updateSignature = (field, index) => {
    let signatures = Object.assign([], this.props.field.field.signatures);
    signatures[index] = field;
    this.onChange({ field: { signatures: signatures } });
  };

  updateName = (name) => {
    let fields = this.props.field.field.fields || [];
    let updatedFields = [];

    fields.forEach((field) => {
      let tmpField = Object.assign({}, field);
      tmpField.key = fieldKeyFromName(name, fieldNameFromKey(field.key));
      updatedFields.push(tmpField);
    });

    this.onChange({
      name: name,
      field: { fields: updatedFields, key: name, signer_id: name, signer: name }
    });
  };

  render() {
    let field = this.props.field;

    return (
      <div>
        <input type="hidden" value={field.external_id} />
        <label>Field Type</label>
        <br />
        <input
          type="text"
          name="field_type"
          value={field.field_type}
          readOnly={true}
        />
        <br />
        <label>Title</label>
        <br />
        <input
          type="text"
          name="title"
          value={field.field['title'] || ''}
          onChange={(e) => {
            this.onChange({ field: { title: e.target.value } });
          }}
        />
        <br />
        <label>Signer</label>
        <br />
        <select
          value={field.field['signer_id'] || ''}
          onChange={(e) => this.updateName(e.target.value)}
        >
          <option value="">-- Select a signer --</option>
          <option value="user">user</option>
          <option value="signer1">signer1</option>
          <option value="signer2">signer2</option>
          <option value="signer3">signer3</option>
          <option value="signer4">signer4</option>
          <option value="signer5">signer5</option>
        </select>
        <br />
        <label>pdf section</label>
        <br />
        <select
          value={field['pdf_section_id'] || ''}
          onChange={(e) => this.onChange({ pdf_section_id: e.target.value })}
        >
          {this.getSectionOptions()}
        </select>
        <br />
        <label>Required</label>
        <br />
        <input
          type="checkbox"
          name="required"
          checked={field.field.required || false}
          onChange={(e) => {
            this.onChange({ field: { required: e.target.checked } });
          }}
        />
        <br />
        <br />
      </div>
    );
  }
}

const borderStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000'
};
