import { put, call } from 'redux-saga/effects';
import {
  getHeaders,
  getApiBaseUrl,
  parseErrors
} from '../../../shared/Network';
import {
  pdfDocumentFetched,
  pdfDocumentFetchFailed
} from '../actions/pdfDocumentActions';
import { INTERNAL_ERROR } from '../../../shared/constants';

import { callApi } from './shared';

export function* getPdfDocument(action) {
  try {
    let url =
      getApiBaseUrl() + '/pdf_documents/' + action.payload.pdfDocumentId;

    const headers = yield call(getHeaders);
    const response = yield call(callApi, url, 'GET', headers, null, action);

    if (!response) {
      console.error(
        '[pdfDocumentSaga] getPdfDocument, null response',
        url,
        action
      );
      return;
    }

    const responseJson = yield response.json();
    let errors = parseErrors(responseJson);

    if (response.status >= 200 && response.status <= 299) {
      if (errors.length === 0) {
        yield put(pdfDocumentFetched(responseJson));
      } else {
        yield put(pdfDocumentFetchFailed(errors));
      }
    } else {
      yield put(pdfDocumentFetchFailed(errors));
    }
  } catch (e) {
    console.log('Error getting PdfDocument: ' + e.message);
    yield put(pdfDocumentFetchFailed([INTERNAL_ERROR]));
  }
}
