export const CLEAR_CONTACT_STATE = 'contacts/clearContactState';

export const CONTACTS_FETCH_REQUESTED = 'contacts/contactsFetchRequest';

export const contactsFetchRequested = (flowId) => {
  return {
    type: CONTACTS_FETCH_REQUESTED,
    payload: { flowId }
  };
};

export const CONTACTS_FETCHED = 'contacts/contactsFetched';

export const contactsFetched = (contacts) => ({
  type: CONTACTS_FETCHED,
  payload: { contacts }
});

export const CONTACTS_FETCH_FAILED = 'contacts/contactsFetchFailed';

export const contactsFetchFailed = (errors) => ({
  type: CONTACTS_FETCH_FAILED,
  payload: {
    errors: errors
  }
});
