import React from 'react';
import StyleSheet from 'react';

export default class Shape extends React.Component {
  constructor(props) {
    super(props);

    this.shape = null;

    this.drag = false;
    this.state = {
      x: null,
      y: null,
      h: null,
      w: null,
      drag: false
    };
  }

  componentDidMount() {
    this.updateShape();
  }

  // componentDidUpdate(props) {
  //   this.setState({
  //     x: props.x(),
  //     y: props.y,
  //     h: props.height,
  //     w: props.width
  //   }, this.updateShape)
  //
  // }

  updateShape = () => {
    this.shape.style.top = this.props.x() + 'px';
    this.shape.style.left = this.props.y + 'px';
    this.shape.style.height = this.props.height + 'px';
    this.shape.style.width = this.props.width + 'px';
  };

  render() {
    return (
      <div
        style={floatingDiv}
        ref={(ref) => {
          this.shape = ref;
        }}
        onMouseDown={(e) => {
          this.setState({ drag: true });
        }}
        onMouseUp={(e) => {
          this.setState({ drag: false });
        }}
        onMouseMove={(e) => {
          if (this.state.drag) {
            console.log('dragging ' + e.clientX + ':' + e.clientY);
            this.shape.style.top = e.clientX + 'px';
            this.shape.style.left = e.clientY + 'px';
          }
        }}
      ></div>
    );
  }

  contains = (mx, my) => {
    // All we have to do is make sure the Mouse X,Y fall in the area between
    // the shape's X and (X + Width) and its Y and (Y + Height)
    return (
      this.x <= mx &&
      this.x + this.w >= mx &&
      this.y <= my &&
      this.y + this.h >= my
    );
  };

  equals = (other) => {
    if (other === null) return false;
    // == vs === ??
    return (
      this.x === other.x &&
      this.y === other.y &&
      this.w === other.w &&
      this.h === other.h
    );
  };

  toCoords = function () {
    // The y value needs to be adjusted for the document and the field height
    var yValue = this.docHeight == null ? this.y : this.docHeight - this.y; //- this.h);
    return this.x + ' ' + yValue + ' ' + this.w + ' ' + this.h;
  };
}

const floatingDiv = {
  position: 'absolute',
  backgroundColor: '#FFFF00'
};
