import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

const application = Application.start();

// Configure Stimulus development experience
application.debug = true;

const context = require.context('controllers', true, /\.js$/);
const contextComponents = require.context(
  '../../components',
  true,
  /_controller\.js$/
);
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
);

window.Stimulus = application;

$('#sign-out').on('click', function () {
  sessionStorage.clear();
  localStorage.clear();
});

export { application };
