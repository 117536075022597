import { createReducer } from '@reduxjs/toolkit';
import {
  PDF_OVERLAYS_FETCH_REQUESTED,
  PDF_OVERLAYS_FETCHED,
  PDF_OVERLAYS_FETCH_FAILED,
  PDF_OVERLAY_FETCHED,
  PDF_OVERLAY_FETCH_FAILED,
  PDF_OVERLAY_CREATED,
  PDF_OVERLAY_CREATE_FAILED,
  PDF_OVERLAY_UPDATED,
  PDF_OVERLAY_UPDATE_FAILED,
  PDF_OVERLAY_DELETED,
  PDF_OVERLAY_DELETE_FAILED,
  PDF_OVERLAY_ACTIVE_FIELD_CHANGED
} from '../actions/pdfOverlayActions';

const initialState = {
  entities: null,
  activePdfOverlay: null,
  errors: [],
  ready: false
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PDF_OVERLAYS_FETCH_REQUESTED, (state, action) => {
      state.ready = false;
    })
    .addCase(PDF_OVERLAYS_FETCHED, (state, action) => {
      let entities = {};
      // normalize pdfOverlays, use id attr
      action.payload.pdfOverlays.forEach((pdfOverlay) => {
        entities[pdfOverlay.id] = pdfOverlay;
      });
      state.entities = entities;
      state.ready = true;
    })
    .addCase(PDF_OVERLAYS_FETCH_FAILED, (state, action) => {
      state.errors = action.payload.errors;
    })
    .addCase(PDF_OVERLAY_FETCHED, (state, action) => {
      state.entities[action.payload.pdfOverlay.id] = action.payload.pdfOverlay;
    })
    .addCase(PDF_OVERLAY_FETCH_FAILED, (state, action) => {
      state.errors = action.payload.errors;
    })
    .addCase(PDF_OVERLAY_CREATED, (state, action) => {
      state.entities[action.payload.pdfOverlay.id] = action.payload.pdfOverlay;
    })
    .addCase(PDF_OVERLAY_CREATE_FAILED, (state, action) => {
      state.errors = action.payload.errors;
    })
    .addCase(PDF_OVERLAY_UPDATED, (state, action) => {
      // state.entities[action.payload.pdfOverlay.id] = action.payload.pdfOverlay;
      let entities = (state.entities = { ...state.entities });
      entities[action.payload.pdfOverlay.id] = action.payload.pdfOverlay;
      state.entities = entities;
    })
    .addCase(PDF_OVERLAY_UPDATE_FAILED, (state, action) => {
      state.errors = action.payload.errors;
    })
    .addCase(PDF_OVERLAY_DELETED, (state, action) => {
      console.log("removing")
      let entities = { ...state.entities };
      delete entities[action.payload.pdfOverlayId];
      state.entities = entities;
    })
    .addCase(PDF_OVERLAY_DELETE_FAILED, (state, action) => {
      state.errors = action.payload.errors;
    })
    .addCase(PDF_OVERLAY_ACTIVE_FIELD_CHANGED, (state, action) => {
      let { pdfOverlayId } = action.payload;

      if (pdfOverlayId === null || pdfOverlayId === undefined) {
        state.activePdfOverlay = null;
      } else {
        state.activePdfOverlay = {
          ...state.entities[action.payload.pdfOverlayId]
        };
      }
    });
});

export default reducer;
