import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('dragover', (event) => this.dragover(event));
    this.element.addEventListener('drop', (event) => this.drop(event));
  }

  dragover(event) {
    event.preventDefault();
  }

  drop(event) {
    event.preventDefault();
    const dt = event.dataTransfer;
    const files = dt.files;

    // Assign files to the input
    this.element.files = files;

    // Trigger a 'change' event on the input to indicate files were added
    this.element.dispatchEvent(new Event('change'));
  }
}
