import { createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_CONTACT_STATE,
  CONTACTS_FETCH_REQUESTED,
  CONTACTS_FETCHED
} from '../actions/contactActions';
import { CONTACT_COLORS, getInitials } from '../../pdf_utils/contacts';

const initialState = {
  entities: [],
  contactDetails: {},
  ready: false
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CLEAR_CONTACT_STATE, (state, action) => initialState)
    .addCase(CONTACTS_FETCH_REQUESTED, (state, action) => {
      state.ready = false;
    })
    .addCase(CONTACTS_FETCHED, (state, action) => {
      state.entities = action.payload.contacts;
      let contactDetails = {};
      action.payload.contacts.forEach((contact, index) => {
        contactDetails[contact.id] = {
          color: CONTACT_COLORS[index],
          initials: getInitials(contact.full_name)
        };
      });
      state.contactDetails = contactDetails;
      state.ready = true;
    });
});

export default reducer;
