import React from 'react';
import { fieldNameFromKey, fieldKeyFromName } from '../pdf_annotator/Util';

export default class SignatureSField extends React.Component {
  constructor(props) {
    super(props);
  }

  getSectionOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a Section ---
      </option>
    );
    {
      this.props.sections.forEach((section) => {
        options.push(
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        );
      });
    }
    return options;
  };

  onChange = (data) => {
    let field = JSON.parse(JSON.stringify(this.props.field));

    let dataField = data.field;
    delete data.field;

    Object.assign(field.field, dataField);
    Object.assign(field, data);

    this.props.onChange(field);
  };

  updateSignature = (field, index) => {
    let signatures = Object.assign([], this.props.field.field.signatures);
    signatures[index] = field;
    this.onChange({ field: { signatures: signatures } });
  };

  updateName = (name) => {
    let fields = this.props.field.field.fields || [];
    let updatedFields = [];

    fields.forEach((field) => {
      let tmpField = Object.assign({}, field);
      tmpField.key = fieldKeyFromName(name, fieldNameFromKey(field.key));
      updatedFields.push(tmpField);
    });

    this.onChange({
      name: name,
      field: { fields: updatedFields, key: name, signer_id: name, signer: name }
    });
  };

  changeSelectedOptions = (e) => {
    let selected = [...e.target.options]
      .filter((option) => option.selected)
      .map((option) => option.value)
      .sort((v1, v2) => {
        if (v1 < v2) {
          return -1;
        }
        if (v2 > v1) {
          return 1;
        }
        return 0;
      });

    this.onChange({ field: { signers: selected } });
  };

  render() {
    let field = this.props.field;

    return (
      <div>
        <input type="hidden" value={field.external_id} />
        <label>Field Type</label>
        <br />
        <input
          type="text"
          name="field_type"
          value={field.field_type}
          readOnly={true}
        />
        <br />
        <label>Title</label>
        <br />
        <input
          type="text"
          value={field.field.title || ''}
          onChange={(e) => this.onChange({ field: { title: e.target.value } })}
        />
        <br />
        <label>Signature Type</label>
        <br />
        <select
          value={field.field['signature_type']}
          onChange={(e) =>
            this.onChange({ field: { signature_type: e.target.value } })
          }
        >
          <option value="signature">signature</option>
          <option value="initials">initials</option>
          <option value="date_signed">date_signed</option>
        </select>
        <br />
        <label>Signers</label>
        <br />
        <select onChange={(e) => this.changeSelectedOptions(e)} multiple="true">
          <option value="">-- Select signers in this group --</option>
          <option
            value="user"
            selected={field.field['signers'].includes('user')}
          >
            user
          </option>
          <option
            value="broker"
            selected={field.field['signers'].includes('broker')}
          >
            broker
          </option>
          <option
            value="signer1"
            selected={field.field['signers'].includes('signer1')}
          >
            signer1
          </option>
          <option
            value="signer2"
            selected={field.field['signers'].includes('signer2')}
          >
            signer2
          </option>
          <option
            value="signer3"
            selected={field.field['signers'].includes('signer3')}
          >
            signer3
          </option>
          <option
            value="signer4"
            selected={field.field['signers'].includes('signer4')}
          >
            signer4
          </option>
          <option
            value="signer5"
            selected={field.field['signers'].includes('signer5')}
          >
            signer5
          </option>
          <option
            value="signer6"
            selected={field.field['signers'].includes('signer6')}
          >
            signer6
          </option>
        </select>
        <br />
        <label>Repeat Direction</label>
        <br />
        <select
          value={field.field['repeat_direction']}
          onChange={(e) =>
            this.onChange({ field: { repeat_direction: e.target.value } })
          }
        >
          <option value="up">up</option>
          <option value="down">down</option>
          <option value="left">left</option>
          <option value="right">right</option>
        </select>
        <br />
        <label>Repeat on all pages (i.e. footer)</label>
        <br />
        <input
          type="checkbox"
          checked={field.field['repeat_all_pages']}
          onChange={(e) =>
            this.onChange({ field: { repeat_all_pages: e.target.checked } })
          }
        />
        <br />
        <label>Height</label>
        <br />
        <input
          type="text"
          value={field.field['height'] || '20'}
          onChange={(e) => this.onChange({ field: { height: e.target.value } })}
        />
        <br />
      </div>
    );
  }
}

const borderStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000'
};
