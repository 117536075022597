import { put, takeLatest } from 'redux-saga/effects';
import { GET_MLS_FIELDS } from './actions/textFieldActions';
import { API_URL } from '../../shared/Network';
import { addMlsFieldsEvent } from './reducers/textFieldReducer';
import { getHeaders } from '../../shared/Network';

function* getMlsFields() {
  try {
    const response = yield retryFetch(
      3,
      API_URL + '/properties/annotation_fields',
      {
        method: 'GET',
        headers: getHeaders()
      }
    );
    if (response.status >= 400) {
      console.log('Error in getting MLS fields', response);
    } else {
      const responseJson = yield response.json();
      yield put(addMlsFieldsEvent(responseJson));
    }
  } catch (e) {
    console.log('Error in getting MLS fields', e);
  }
}

function* retryFetch(repeatNumber, url, params) {
  let response = {};
  for (const i = 0; i <= repeatNumber; i++) {
    response = yield fetch(url, params);
    if (response.status < 400) {
      return response;
    }
    yield delay(1000);
  }

  return response;
}

export default function* rootSaga() {
  yield takeLatest(GET_MLS_FIELDS, getMlsFields);
}
