export const PDF_TEMPLATES_FETCH_REQUESTED =
  'pdfTemplate/pdfTemplatesFetchRequested';

export const pdfTemplatesFetchRequested = (mlsOrgId) => ({
  type: PDF_TEMPLATES_FETCH_REQUESTED,
  payload: { mlsOrgId: mlsOrgId }
});

export const PDF_TEMPLATES_FETCHED = 'pdfTemplate/pdfTemplatesFetched';

export const pdfTemplatesFetched = (pdfTemplates) => ({
  type: PDF_TEMPLATES_FETCHED,
  payload: { pdfTemplates: pdfTemplates }
});

export const PDF_TEMPLATE_FETCH_REQUESTED =
  'pdfTemplate/pdfTemplateFetchRequested';

export const pdfTemplateFetchRequested = (pdfTemplateId) => ({
  type: PDF_TEMPLATE_FETCH_REQUESTED,
  payload: { pdfTemplateId: pdfTemplateId }
});

export const PDF_TEMPLATE_FETCHED = 'pdfTemplate/pdfTemplateFetched';

export const pdfTemplateFetched = (pdfTemplate) => ({
  type: PDF_TEMPLATE_FETCHED,
  payload: { pdfTemplate: pdfTemplate }
});

export const PDF_TEMPLATE_FIELDS_REQUESTED =
  'pdfTemplate/pdfTemplateFieldsRequested';

export const pdfTemplateFieldsRequested = (pdfTemplateId) => ({
  type: PDF_TEMPLATE_FIELDS_REQUESTED,
  payload: { pdfTemplateId: pdfTemplateId }
});

export const PDF_TEMPLATE_FIELDS_FETCHED =
  'pdfTemplate/pdfTemplateFieldsFetched';

export const pdfTemplateFieldsFetched = (fields) => ({
  type: PDF_TEMPLATE_FIELDS_FETCHED,
  payload: { fields: fields }
});

export const PDF_TEMPLATE_FIELD_REQUESTED =
  'pdfTemplate/pdfTemplateFieldRequested';

export const pdfTemplateFieldRequested = (
  pdfTemplateId,
  pdfFieldId = null
) => ({
  type: PDF_TEMPLATE_FIELD_REQUESTED,
  payload: { pdfTemplateId: pdfTemplateId, pdfFieldId: pdfFieldId }
});

export const PDF_TEMPLATE_FIELD_FETCHED = 'pdfTemplate/pdfTemplateFieldFetched';

export const pdfTemplateFieldFetched = (fields, pdfFieldId) => ({
  type: PDF_TEMPLATE_FIELD_FETCHED,
  payload: { fields: fields, pdfFieldId: pdfFieldId }
});

export const CLEAR_PDF_TEMPLATE_STATE = 'pdfTemplate/clearPdfTemplateState';

export const UPDATE_PDF_TEMPLATE_REQUEST =
  'pdfTemplate/updatePdfTemplateRequest';

export const updatePdfTemplateRequest = (pdfTemplateId, htmlTemplate) => ({
  type: UPDATE_PDF_TEMPLATE_REQUEST,
  payload: {
    pdfTemplateId: pdfTemplateId,
    htmlTemplate: htmlTemplate
  }
});

export const UPDATE_PDF_FIELD = 'pdfTemplate/updatePdfField';

export const updatePdfField = (pdfField) => ({
  type: UPDATE_PDF_FIELD,
  payload: {
    pdfField: pdfField
  }
});

export const PDF_TEMPLATE_UPDATED = 'pdfTemplate/pdfTemplateUpdated';

export const pdfTemplateUpdated = (pdfTemplate) => ({
  type: PDF_TEMPLATE_UPDATED,
  payload: {
    pdfTemplate: pdfTemplate
  }
});

export const DELETE_PDF_FIELD_REQUEST = 'pdfTemplate/deletePdfFieldRequest';

export const deletePdfField = (pdfTemplateId, pdfFieldId) => ({
  type: DELETE_PDF_FIELD_REQUEST,
  payload: {
    pdfTemplateId: pdfTemplateId,
    pdfFieldId: pdfFieldId
  }
});

//Maybe I can just call pdf_template_updated for this one.

export const PDF_TEMPLATE_DELETED = 'pdfTemplate/pdfTemplateDeleted';

export const pdfTemplateDeleted = (pdfField) => ({
  type: PDF_TEMPLATE_DELETED,
  payload: {
    pdfField: pdfField
  }
});

export const CLEAR_PDF_FIELD = 'pdfTemplates/clearPdfField';

export const clearPdfField = () => ({
  type: CLEAR_PDF_FIELD
});

export const DOWNLOAD_PDF = 'pdfTemplates/downloadPdf';

export const downloadPdf = (params) => ({
  type: DOWNLOAD_PDF,
  payload: { params: params }
});

export const PDF_DOWNLOADED = 'pdfTemplates/pdfDownloaded';

export const pdfDownloaded = (pdf) => ({
  type: PDF_DOWNLOADED,
  payload: { pdf: pdf }
});
