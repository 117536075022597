import React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CodeMirror from 'rodemirror';
import { oneDark } from '@codemirror/theme-one-dark';
import { html } from '@codemirror/lang-html';
import { EditorSelection } from '@codemirror/state';
import {
  pdfTemplatesFetchRequested,
  pdfTemplateFetchRequested,
  updatePdfTemplateRequest,
  pdfTemplateUpdated
} from './redux/actions/pdfTemplateActions';
import HtmlViewer from './HtmlViewer';

function PdfTemplates(props) {
  let pdfTemplates = useSelector((state) => state.pdfTemplates.pdfTemplates);
  let elements = [];

  elements.push(
    <option key="none" value="NONE">
      ---
    </option>
  );

  pdfTemplates.forEach((template) => {
    elements.push(
      <option key={template.id} value={template.id}>
        {template.name}
      </option>
    );
  });
  return (
    <select onChange={(e) => props.onChange(e.target.value)}>{elements}</select>
  );
}

const MLS_ORG_ID = '86b3f50dc61748c3c538a2c4';

export default function Editor() {
  const [selection, setSelection] = useState(
    EditorSelection.create([EditorSelection.range(0, 0)])
  );
  const [selectedPdfTemplate, setSelectedPdfTemplate] = useState(null);
  const [htmlValue, setHtmlValue] = useState('');
  const [editorView, setEditorView] = useState(null);

  let pdfTemplate = useSelector((state) => state.pdfTemplates.pdfTemplate);

  const dispatch = useDispatch();

  // set default HTML value for editor
  useEffect(() => {
    if (pdfTemplate !== null) {
      setHtmlValue(pdfTemplate['html_template']);
    } else {
      setHtmlValue('');
    }
  }, [pdfTemplate]);

  // fetch the selected PdfTemplate
  useEffect(() => {
    dispatch(pdfTemplatesFetchRequested(MLS_ORG_ID));
  }, [dispatch]);

  // reset the editor to the new PdfTemplate HTML
  useEffect(() => {
    if (editorView) {
      editorView.dispatch({
        changes: {
          from: 0,
          to: editorView.state.doc.length,
          insert: ''
        }
      });
    }

    if (selectedPdfTemplate !== 'NONE' && selectedPdfTemplate !== null) {
      dispatch(pdfTemplateFetchRequested(selectedPdfTemplate));
    }
  }, [selectedPdfTemplate, dispatch]);

  const extensions = useMemo(() => [oneDark, html()], []);

  const getSaveHtmlButton = () => {
    if (pdfTemplate) {
      return (
        <button onClick={() => saveHtmlTemplate()}>Save Html Template</button>
      );
    }
  };

  // update the html with the field being injected
  const updateCode = (injectionString) => {
    editorView.dispatch({
      changes: {
        from: editorView.viewState.state.selection.ranges[0].from,
        to: editorView.viewState.state.selection.ranges[0].to,
        insert: injectionString
      }
    });
  };

  // save the current HTML in the editor to the PdfTemplate on server
  const saveHtmlTemplate = () => {
    console.log('going to save!');
    let htmlString = editorView.viewState.state.doc.toString();
    dispatch(updatePdfTemplateRequest(selectedPdfTemplate, htmlString));
  };

  return (
    <div>
      <PdfTemplates
        onChange={(pdfTemplate) => {
          setSelectedPdfTemplate(pdfTemplate);
        }}
      />
      {getSaveHtmlButton()}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '60%' }}>
          <CodeMirror
            extensions={extensions}
            selection={selection}
            value={htmlValue}
            height="200px"
            onUpdate={(value) => {
              if (value.docChanged) {
                dispatch(
                  pdfTemplateUpdated({
                    html_template: value.state.doc.toString()
                  })
                );
              }
            }}
            onEditorViewChange={(view) => {
              setEditorView(view);
            }}
          />
        </div>
        <div style={{ width: '40%' }}>
          <HtmlViewer pdfTemplate={pdfTemplate} inject={updateCode} />
        </div>
      </div>
    </div>
  );
}
