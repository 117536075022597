import { Controller } from "stimulus";
import { debounce } from 'lodash';
import { getApiUrl, getHeaders, sessionToTokenAuth } from '../components/shared/Network';

export default class extends Controller {
  static targets = ["input", "dropdown", "close"];

  connect() {
    // Initialize the list of contacts (you'll need to replace this with your own data)
    this.debouncedSearch = debounce(this.searchContacts.bind(this), 300);

    // Add event listener for input changes
    this.inputTarget.addEventListener("input", this.debouncedSearch);
    this.closeTarget.addEventListener("click", () => {
      this.hideDropdown();
    })
  }

  async searchContacts() {
    const inputValue = this.inputTarget.value.toLowerCase();
    const inputValueParts = inputValue.split(",");
    const lastInputValuePart = inputValueParts[inputValueParts.length - 1].trim();
    if (lastInputValuePart.length === 0) {
      return;
    }

    await sessionToTokenAuth({
      onSuccess: () => {
        console.log("Logged in on stimulus")
      }
    });
    const headers = await getHeaders();
    const response = await fetch(getApiUrl() + `/contacts/search?query=${lastInputValuePart}`, { headers: headers} )
    const contacts = await response.json();

    // Filter out duplicate emails
    contacts.filter(contact =>
      !inputValue.includes(contact.email)
    );

    let matchingContacts = contacts.slice(0, 5);

    // Show or hide the dropdown based on matching contacts
    if (matchingContacts.length > 0) {
      this.showDropdown(contacts);
    } else {
      this.hideDropdown();
    }
  }

  handleInput() {
    const inputValue = this.inputTarget.value.toLowerCase();
    const inputValueParts = inputValue.split(",");
    const lastInputValuePart = inputValueParts[inputValueParts.length - 1].trim();

    // Restart the search if the input ends with a comma
    if (lastInputValuePart === "") {
      this.hideDropdown();
      return;
    }

    let matchingContacts = this.contacts.filter(contact =>
      contact.name.toLowerCase().includes(lastInputValuePart) ||
      contact.email.toLowerCase().includes(lastInputValuePart)
    );

    // Limit the results to 5 contacts
    matchingContacts = matchingContacts.slice(0, 5);

    // Filter out duplicate emails
    matchingContacts = matchingContacts.filter(contact =>
      !inputValue.includes(contact.email)
    );

    // Show or hide the dropdown based on matching contacts
    if (matchingContacts.length > 0) {
      this.showDropdown(matchingContacts);
    } else {
      this.hideDropdown();
    }
  }

  showDropdown(contacts) {
    // Clear existing dropdown items
    this.dropdownTarget.innerHTML = "";

    const close = document.createElement('div');
    const x = document.createElement('i');
    x.classList.add('fa-solid');
    x.classList.add('fa-x');

    close.style.position = 'absolute';
    close.style.right = '10px';
    close.style.top = '10px';
    close.appendChild(x);
    close.addEventListener('click', (event) => {
      this.hideDropdown();
      event.stopPropagation();
      event.preventDefault();
    })

    this.dropdownTarget.appendChild(close)


    // Create dropdown items for each matching contact
    contacts.forEach(contact => {
      const item = document.createElement("div");
      item.textContent = `${contact.first_name} ${contact.last_name} <${contact.email}>`;
      item.classList.add("dropdown-item");
      item.addEventListener("click", (event) => {
        this.addEmailToTextArea(contact.email)
      });
      this.dropdownTarget.appendChild(item);
    });

    // Show the dropdown
    this.dropdownTarget.classList.add("show");
  }

  hideDropdown() {
    // Hide the dropdown
    this.dropdownTarget.classList.remove("show");
  }

  addEmailToTextArea(email) {
    // Split the input value by commas and trim each part
    const inputValueParts = this.inputTarget.value.split(",").map(part => part.trim());
    // Replace the last part of the input value with the selected email
    inputValueParts[inputValueParts.length - 1] = email;
    // Join the parts back together with commas and update the input value
    this.inputTarget.value = inputValueParts.join(", ") + ",";
    // Hide the dropdown
    this.hideDropdown();
  }
}