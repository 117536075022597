export const PDF_OVERLAYS_FETCH_REQUESTED =
  'pdfOverlay/pdfOverlaysFetchRequest';

// Index
export const pdfOverlaysFetchRequested = (pdfDocumentId) => ({
  type: PDF_OVERLAYS_FETCH_REQUESTED,
  payload: {
    pdfDocumentId: pdfDocumentId
  }
});

export const PDF_OVERLAYS_FETCHED = 'pdfOverlay/pdfOverlaysFetched';

export const pdfOverlaysFetched = (pdfOverlays) => ({
  type: PDF_OVERLAYS_FETCHED,
  payload: {
    pdfOverlays: pdfOverlays
  }
});

export const PDF_OVERLAYS_FETCH_FAILED = 'pdfOverlay/pdfOverlaysFetchFailed';

export const pdfOverlaysFetchFailed = (errors) => ({
  type: PDF_OVERLAYS_FETCH_FAILED,
  payload: {
    errors: errors
  }
});

// Show
export const PDF_OVERLAY_FETCH_REQUESTED =
  'pdfOverlay/pdfOverlayFetchRequested';

export const pdfOverlayFetchRequested = (pdfDocumentId, pdfOverlayId) => ({
  type: PDF_OVERLAY_FETCH_REQUESTED,
  payload: {
    pdfDocumentId: pdfDocumentId,
    pdfOverlayId: pdfOverlayId
  }
});

export const PDF_OVERLAY_FETCHED = 'pdfOverlay/pdfOverlayFetched';

export const pdfOverlayFetched = (pdfOverlay) => ({
  type: PDF_OVERLAY_FETCHED,
  payload: {
    pdfOverlay: pdfOverlay
  }
});

export const PDF_OVERLAY_FETCH_FAILED = 'pdfOverlay/pdfOverlayFetchFailed';

export const pdfOverlayFetchFailed = (errors) => ({
  type: PDF_OVERLAY_FETCH_FAILED,
  payload: {
    errors: errors
  }
});

// Create
export const PDF_OVERLAY_CREATE_REQUESTED =
  'pdfOverlay/pdfOverlayCreateRequested';

export const pdfOverlayCreateRequested = (
  pdfDocumentId,
  pdfOverlayParams,
  callback
) => ({
  type: PDF_OVERLAY_CREATE_REQUESTED,
  payload: {
    pdfDocumentId: pdfDocumentId,
    pdfOverlay: pdfOverlayParams,
    callback: callback
  }
});

export const PDF_OVERLAY_CREATED = 'pdfOverlay/pdfOverlayCreated';

export const pdfOverlayCreated = (pdfOverlay) => ({
  type: PDF_OVERLAY_CREATED,
  payload: {
    pdfOverlay: pdfOverlay
  }
});

export const PDF_OVERLAY_CREATE_FAILED = 'pdfOverlay/pdfOverlayCreateFailed';

export const pdfOverlayCreateFailed = (errors) => ({
  type: PDF_OVERLAY_CREATE_FAILED,
  payload: {
    errors: errors
  }
});

// Update
export const PDF_OVERLAY_UPDATE_REQUESTED =
  'pdfOverlay/pdfOverlayUpdateRequested';

export const pdfOverlayUpdateRequested = (
  pdfDocumentId,
  pdfOverlayParams,
  callback
) => ({
  type: PDF_OVERLAY_UPDATE_REQUESTED,
  payload: {
    pdfDocumentId: pdfDocumentId,
    pdfOverlay: pdfOverlayParams,
    callback: () => callback()
  }
});

export const PDF_OVERLAY_UPDATED = 'pdfOverlay/pdfOverlayUpdated';

export const pdfOverlayUpdated = (pdfOverlay) => ({
  type: PDF_OVERLAY_UPDATED,
  payload: {
    pdfOverlay: pdfOverlay
  }
});

export const PDF_OVERLAY_UPDATE_FAILED = 'pdfOverlay/pdfOverlayUpdateFailed';

export const pdfOverlayUpdateFailed = (errors) => ({
  type: PDF_OVERLAY_UPDATE_FAILED,
  payload: {
    errors: errors
  }
});

// Delete

export const PDF_OVERLAY_DELETE_REQUESTED =
  'pdfOverlay/pdfOverlayDeleteRequested';

export const pdfOverlayDeleteRequested = (
  pdfDocumentId,
  pdfOverlayId,
  callback
) => ({
  type: PDF_OVERLAY_DELETE_REQUESTED,
  payload: {
    pdfDocumentId: pdfDocumentId,
    pdfOverlayId: pdfOverlayId,
    callback: callback
  }
});

export const PDF_OVERLAY_DELETED = 'pdfOverlay/pdfOverlayDeleted';

export const pdfOverlayDeleted = (pdfOverlayId) => ({
  type: PDF_OVERLAY_DELETED,
  payload: {
    pdfOverlayId: pdfOverlayId
  }
});

export const PDF_OVERLAY_DELETE_FAILED = 'pdfOverlay/pdfOverlayDeleteFailed';

export const pdfOverlayDeleteFailed = (errors) => ({
  type: PDF_OVERLAY_DELETE_FAILED,
  payload: {
    errors: errors
  }
});

export const CLEAR_PDF_OVERLAY_STATE = 'pdfOverlay/clearPdfOverlayState';

// Set Active PdfOverlay

export const PDF_OVERLAY_ACTIVE_FIELD_CHANGED =
  'pdfOverlay/pdfOverlayActiveChanged';

export const pdfOverlayActiveFieldChanged = (pdfOverlayId) => ({
  type: PDF_OVERLAY_ACTIVE_FIELD_CHANGED,
  payload: {
    pdfOverlayId: pdfOverlayId
  }
});
