import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getPdfOverlays,
  getPdfOverlay,
  createPdfOverlay,
  updatePdfOverlay,
  deletePdfOverlay
} from './pdfOverlaySaga';
import { getPdfDocument } from './pdfDocumentSaga';
import { getContacts } from './contactSaga';
import { downloadSignature, getSignatures } from './signaturesSaga';
import { log } from './shared';
import {
  PDF_OVERLAYS_FETCH_REQUESTED,
  PDF_OVERLAY_FETCH_REQUESTED,
  PDF_OVERLAY_CREATE_REQUESTED,
  PDF_OVERLAY_UPDATE_REQUESTED,
  PDF_OVERLAY_DELETE_REQUESTED
} from '../actions/pdfOverlayActions';
import { PDF_DOCUMENT_FETCH_REQUESTED } from '../actions/pdfDocumentActions';
import { CONTACTS_FETCH_REQUESTED } from '../actions/contactActions';
import { LOG_REQUESTED } from '../actions/logActions';
import {
  SIGNATURE_DOWNLOAD_REQUESTED,
  SIGNATURES_FETCH_REQUESTED
} from '../actions/signatureActions';

export default function* rootSaga() {
  yield takeLatest(CONTACTS_FETCH_REQUESTED, getContacts);
  yield takeLatest(SIGNATURES_FETCH_REQUESTED, getSignatures);
  yield takeEvery(SIGNATURE_DOWNLOAD_REQUESTED, downloadSignature);
  yield takeLatest(PDF_DOCUMENT_FETCH_REQUESTED, getPdfDocument);
  yield takeLatest(PDF_OVERLAYS_FETCH_REQUESTED, getPdfOverlays);
  yield takeLatest(PDF_OVERLAY_FETCH_REQUESTED, getPdfOverlay);
  yield takeLatest(PDF_OVERLAY_CREATE_REQUESTED, createPdfOverlay);
  yield takeLatest(PDF_OVERLAY_UPDATE_REQUESTED, updatePdfOverlay);
  yield takeLatest(PDF_OVERLAY_DELETE_REQUESTED, deletePdfOverlay);
  yield takeEvery(LOG_REQUESTED, log);
}
