import React from 'react';
import { getTextFieldNamesFromFields } from './Util';
import { connect } from 'react-redux';

class ToggleFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onChange = (e) => {
    let toggleFields = Object.assign([], this.props.toggleFields);

    this.props.onChange(toggleFields.concat(e.target.value));
  };

  render() {
    let toggleFields = Object.assign([], this.props.toggleFields);
    let fields = [];

    toggleFields.forEach((tf) => {
      let field = this.props.pdfFields.find((f) => f.external_id == tf);
      fields.push(
        <p>
          {field.name} {field.external_id || ''}
        </p>
      );
    });

    return (
      <div>
        <label>Toggle Fields</label>
        <br />
        {fields}
        <select value="" onChange={this.onChange}>
          {this.getFieldOptions()}
        </select>
      </div>
    );
  }

  getFieldOptions = () => {
    let fields = [];
    fields.push(
      <option key="empty" value="">
        --- Select a Field ---
      </option>
    );
    this.props.pdfFields.forEach((field) => {
      if (field.external_id != null && field.external_id !== '') {
        fields.push(
          <option key={field.external_id} value={field.external_id}>
            {field.name}
          </option>
        );
      }
    });
    return fields;
  };
}

const mapStateToProps = (state) => {
  return {
    pdfFields: state.pdfTemplates.pdfFields
  };
};

export default connect(mapStateToProps)(ToggleFields);
