import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useComplianceReviewStore from '../../shared/stores/ComplianceReviewStore';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import { Box, Button, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { formatDateTime } from '../../shared/services/dates';
import CommentThread from './CommentThread';

export default function Comments() {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const theme = useTheme();

  const { currentDocument, comments, setComments, addComment } = useComplianceReviewStore();

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    Request.get(getApiUrl() + `/v2/compliances/complianceables/${currentDocument.id}/comments`, (response) => {
      setComments(response);
    })
  }, [currentDocument.id])

  const onSubmit = (data) => {
    if(!data.comment) return;

    Request.post(getApiUrl() + `/v2/compliances/complianceables/${currentDocument.id}/comments`, { comment: data }, (response) => {
      addComment(response);
      reset();
    })
  }

  return (
    <Box height="65vh" overflow="scroll">
      {comments?.length > 0 ? comments.map((comment) => (
        <CommentThread key={comment.id} comment={comment} />
      )) : <Box>
        <Typography variant="h6">
          No comments yet
        </Typography>
      </Box>}
      <Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register('comment')}
            label="Comment"
            fullWidth
            autoFocus
            InputProps={{
              endAdornment: (
                <IconButton variant="contained" color="primary" type="submit">
                  <AddCircleIcon />
                </IconButton>
              )
            }}
          />
        </form>
      </Stack>
    </Box>
  )
}