import { Button, Stack, TextField } from '@mui/material';
import React from 'react';
import { getApiBaseUrl, getHeaders } from '../shared/Network';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

export default function DocumentInfoFields(props) {
  const pdfTemplates = useSelector((state) => state.pdfTemplates.pdfTemplates);
  const pdfTemplate = pdfTemplates.filter((pdfTemplate) => pdfTemplate.id === props.pdfTemplate.id)[0];
  const { register, handleSubmit } = useForm();

  const handleUpdate = (values) => {
    const url = getApiBaseUrl() + '/pdf_templates/' + pdfTemplate.id
    fetch(
      url,
      {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(values)
      }
    )
  }

  return (
    <Stack>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <Stack spacing={1}>
          <Stack>
            <TextField {...register('name')} label="Name" defaultValue={pdfTemplate.name} />
          </Stack>
          <Stack>
            <TextField multiline rows={3} {...register('description')} label="Description" defaultValue={pdfTemplate.description} />
          </Stack>
          <Stack direction="row">
            <Button type="submit" variant="contained">Update</Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  )
}