import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const selectBox = document.querySelector('.select-box-class');

    selectBox.addEventListener('change', function () {
      const selectedOption = selectBox.options[selectBox.selectedIndex];
      let collaboratorType =
        selectedOption.parentNode.label === 'Users' ? 'User' : 'Team';

      if (!selectedOption.value) {
        collaboratorType = '';
      }

      document.querySelector(
        '[name="transaction_collaboration[transaction_collaboration_type]"]'
      ).value = collaboratorType;
    });
  }
}
