// app/javascript/controllers/filter_indicator_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'indicator', 'input'];
  initialState = null;

  connect() {
    this.initialState = this.getCurrentState();

    this.inputTargets.forEach((input) => {
      input.addEventListener('change', () => this.compareState());
    });

    this.formTargets.forEach((form) => {
      form.addEventListener('change', () => this.compareState());
    });

    this.toggleIndicator(true);
  }

  getCurrentState() {
    // Generate the current state as a hash
    const state = {};

    // Get the current state from input targets
    this.inputTargets.forEach((input) => {
      if (input.type !== 'submit') {
        state[input.id] = this.getValue(input);
      }
    });

    // Get the current state from form targets
    this.formTargets.forEach((form) => {
      Array.from(form.elements).forEach((element) => {
        if (element.type !== 'submit' && element.id) {
          state[element.id] = this.getValue(element);
        }
      });
    });

    return state;
  }

  getValue(element) {
    return element.type === 'checkbox' || element.type === 'radio'
      ? element.checked
      : element.value;
  }

  compareState() {
    // Compare the current state to the initial state
    const currentState = this.getCurrentState();
    const isStateChanged = Object.keys(this.initialState).some((key) => {
      return currentState[key] !== this.initialState[key];
    });

    // Toggle the indicator based on whether any state has changed
    this.toggleIndicator(!isStateChanged);
  }

  toggleIndicator(hide) {
    this.indicatorTargets.forEach((indicator) => {
      indicator.classList.toggle('hide', hide);
    });
  }

  disconnect() {
    this.inputTargets.forEach((input) => {
      input.removeEventListener('change', this.compareState);
    });

    this.formTargets.forEach((form) => {
      form.removeEventListener('change', this.compareState);
    });
  }
}
