import React from 'react';
import { Row, Form, Button, Dropdown } from 'react-bootstrap';
import CheckIcon from 'images/check-solid.svg';

export default function SignatureField(props) {
  const getContactOptions = () => {
    let options = [];
    {
      props.contacts.map((contact) => {
        let checkIcon = null;
        if (contact.id === props.value) {
          checkIcon = (
            <span style={{ width: '14px', display: 'inline-block' }}>
              <img style={{ verticalAlign: 'unset' }} src={CheckIcon} />
            </span>
          );
        } else {
          checkIcon = (
            <span style={{ width: '14px', display: 'inline-block' }}></span>
          );
        }

        options.push(
          <Dropdown.Item
            key={contact.id}
            onClick={(e) => {
              if (props.value !== contact.id) {
                props.onChange(contact.id);
              }
              //Close it
            }}
          >
            {checkIcon}
            <span style={contactIndicatorStyle(contact.id)}></span>
            {contact.full_name}
          </Dropdown.Item>
        );
      });
      // Add 'myself' as an option for agent signing now

      let checkIcon;
      if (props.value === 'user') {
        checkIcon = (
          <span style={{ width: '14px', display: 'inline-block' }}>
            <img style={{ verticalAlign: 'unset' }} src={CheckIcon} />
          </span>
        );
      } else {
        checkIcon = (
          <span style={{ width: '14px', display: 'inline-block' }}></span>
        );
      }
      options.push(
        <Dropdown.Item
          key={'user'}
          onClick={(e) => {
            if (props.value !== 'user') {
              props.onChange('user');
            }
          }}
        >
          {checkIcon}
          <span></span>
          Myself
        </Dropdown.Item>
      );
    }
    return options;
  };

  let label;

  switch (props.fieldType) {
    case 'signature':
      label = 'Signature';
      break;
    case 'initials':
      label = 'Initials';
      break;
    default:
      label = 'Date Signed';
      break;
  }

  const selectedContact = () => {
    return props.contacts.find((c) => {
      return c.id === props.value;
    });
  };

  const contactIndicatorStyle = (id) => {
    return {
      width: '15px',
      height: '15px',
      display: 'inline-block',
      borderRadius: '15px',
      marginRight: '5px',
      backgroundColor: id === null ? 'inherit' : props.contactDetails[id]?.color
    };
  };

  return (
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="formTextField">
        <h5>{label}</h5>
        <Dropdown>
          <div>
            <strong>Signer</strong>
          </div>
          <Dropdown.Toggle>
            <span
              style={contactIndicatorStyle(
                props.value === 'user' ? null : selectedContact()?.id
              )}
            ></span>
            {props.value === 'user' ? 'Myself' : selectedContact()?.full_name}
          </Dropdown.Toggle>
          <Dropdown.Menu>{getContactOptions()}</Dropdown.Menu>
        </Dropdown>
        <br />
        <br />
        <Button
          size="sm"
          style={{ marginTop: 200 }}
          variant="danger"
          onClick={props.onDelete}
        >
          Delete
        </Button>
      </Form.Group>
    </Form>
  );
}
