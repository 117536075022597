import React, { useState } from 'react';
import TextField from '../pdf_annotator/TextField';
import DateTimeField from '../pdf_annotator/DateTimeField';
import SignaturesField from './signaturesField';
import {
  createPdfFieldForPdfTemplate,
  updatePdfFieldForPdfTemplate
} from '../shared/Network';
import HtmlDocumentTree from './HtmlDocumentTree';
import RadioField from '../pdf_annotator/RadioField';
import CompositeField from '../pdf_annotator/CompositeField';
import CheckboxField from '../pdf_annotator/CheckboxField';
import {
  clearPdfField,
  pdfTemplateFieldRequested,
  pdfTemplateFetchRequested,
  updatePdfTemplateRequest,
  pdfTemplateFieldsRequested, pdfTemplateCopyFieldsRequested,
  updatePdfField
} from './redux/actions/pdfTemplateActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { newFieldCopy } from '../shared/pdf_field_utils';

export default function CopyField(props) {
  const [fieldsFetched, setfieldsFetched] = useState(false);
  const pdfTemplate = useSelector((state) => state.pdfTemplates.pdfTemplate);
  const pdfTemplates = useSelector((state) => state.pdfTemplates.pdfTemplates);
  const copyPdfFields = useSelector((state) => state.pdfTemplates.copyPdfFields);

  const dispatch = useDispatch();
  // copyPdfFields: state.pdfTemplates.copyPdfFields,
  //   pdfFields: state.pdfTemplates.pdfFields,
  //   pdfField: state.pdfTemplates.pdfField,
  //   pdfTemplates: state.pdfTemplates.pdfTemplates,
  //   pdfTemplate: state.pdfTemplates.pdfTemplate,

  function getPdfTemplateOptions(){
    let sortedPdfTemplates = [...pdfTemplates];
    sortedPdfTemplates.sort((t1, t2) => {
      if (t1.name < t2.name) {
        return -1;
      }
      if (t2.name > t1.name) {
        return 1;
      }
      return 0;
    });

    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a PdfTemplate ---
      </option>
    );
    {
      sortedPdfTemplates.forEach((pdfTemplate) => {
        options.push(
          <option key={pdfTemplate.id} value={pdfTemplate.id}>
            {pdfTemplate.name}
          </option>
        );
      });
    }
    return options;
  }

  function getPdfFieldOptions(){
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a PdfField ---
      </option>
    );
    {
      copyPdfFields.forEach((pdfField) => {
        options.push(
          <option key={pdfField.id} value={pdfField.id}>
            {pdfField.name}
          </option>
        );
      });
    }
    return options;
  }
  
  function getPdfFieldSelect() {
    if (copyPdfFields !== undefined && copyPdfFields !== null) {
      return (<select
        onChange={(e) => {
          let value = e.target.value;
          if (value !== '') {
            let copyPdfField = copyPdfFields.find((field) => {
              return field.id === value;
            });

            let newField = newFieldCopy(copyPdfField, pdfTemplate.id);
            dispatch(updatePdfField(newField));
          }
        }}
      >
        {getPdfFieldOptions()}
      </select>);
    }
    else {
      return null;
    }
  }

  return (
    <div>
      <p>Copy from another PDF</p>
      <select
        onChange={(e) => {
          let value = e.target.value;
          console.log(value);
          if (value !== '') {
            dispatch(pdfTemplateCopyFieldsRequested(e.target.value));
          }
        }}
      >
        {getPdfTemplateOptions()}
      </select>
      {getPdfFieldSelect()}
    </div>
  );
}

