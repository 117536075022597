import React from 'react';
import { login, sessionToTokenAuth } from '../shared/Network';

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };

    sessionToTokenAuth({
      onSuccess: () => {
        this.props.setLoggedIn();
      }
    });
  }

  login = () => {
    login(this.state).then(async (response) => {
      let json = await response.json();
      window.sessionStorage.setItem('auth', JSON.stringify(json.data.auth));
      window.sessionStorage.setItem('user', JSON.stringify(json.data.user));

      this.props.setLoggedIn();
      console.log('Response: ' + JSON.stringify(json));
    });
  };

  render() {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
        />
        <input
          type="password"
          value={this.state.password}
          onChange={(e) => this.setState({ password: e.target.value })}
        />
        <button onClick={this.login}>Login</button>
      </form>
    );
  }
}
