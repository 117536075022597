import { put, call } from 'redux-saga/effects';
import {
  getHeaders,
  getApiBaseUrl,
  parseErrors
} from '../../../shared/Network';
import {
  pdfOverlaysFetched,
  pdfOverlaysFetchFailed,
  pdfOverlayFetched,
  pdfOverlayFetchFailed,
  pdfOverlayCreated,
  pdfOverlayCreateFailed,
  pdfOverlayUpdated,
  pdfOverlayUpdateFailed,
  pdfOverlayDeleted,
  pdfOverlayDeleteFailed
} from '../actions/pdfOverlayActions';
import { INTERNAL_ERROR } from '../../../shared/constants';

import { callApi } from './shared';

export function* getPdfOverlays(action) {
  try {
    let url =
      getApiBaseUrl() +
      '/pdf_documents/' +
      action.payload.pdfDocumentId +
      '/pdf_overlays';

    const headers = yield call(getHeaders);
    const response = yield call(callApi, url, 'GET', headers, null, action);

    if (!response) {
      console.error(
        '[pdfOverlaySaga] getPdfOverlays, null response',
        url,
        action
      );
      return;
    }

    const responseJson = yield response.json();
    let errors = parseErrors(responseJson);

    if (response.status >= 200 && response.status <= 299) {
      if (errors.length === 0) {
        yield put(pdfOverlaysFetched(responseJson));
      } else {
        yield put(pdfOverlaysFetchFailed(errors));
      }
    } else {
      yield put(pdfOverlaysFetchFailed(errors));
    }
  } catch (e) {
    console.log('Error getting PdfOverlays ' + e.message);
    yield put(pdfOverlaysFetchFailed([INTERNAL_ERROR]));
  }
}

export function* getPdfOverlay(action) {
  try {
    let url =
      getApiBaseUrl() +
      '/pdf_documents/' +
      action.payload.pdfDocumentId +
      '/pdf_overlays/' +
      action.payload.pdfOverlayId;

    const headers = yield call(getHeaders);
    const response = yield call(callApi, url, 'GET', headers, null, action);

    if (!response) {
      console.error(
        '[pdfOverlaySaga] getPdfOverlay, null response',
        url,
        action
      );
      return;
    }

    const responseJson = yield response.json();
    let errors = parseErrors(responseJson);

    if (response.status >= 200 && response.status <= 299) {
      if (errors.length === 0) {
        yield put(pdfOverlayFetched(responseJson));
      } else {
        yield put(pdfOverlayFetchFailed(errors));
      }
    } else {
      yield put(pdfOverlaysFetchFailed(errors));
    }
  } catch (e) {
    console.log('Error getting PdfOverlay ' + e.message);
    yield put(pdfOverlayFetchFailed([INTERNAL_ERROR]));
  }
}

export function* createPdfOverlay(action) {
  try {
    let url =
      getApiBaseUrl() +
      '/pdf_documents/' +
      action.payload.pdfDocumentId +
      '/pdf_overlays';

    const { callback } = action.payload;

    let body = { pdf_overlay: action.payload.pdfOverlay };

    const headers = yield call(getHeaders);
    const response = yield call(
      callApi,
      url,
      'POST',
      headers,
      JSON.stringify(body),
      action
    );

    if (!response) {
      console.error(
        '[pdfOverlaySaga] createPdfOverlay, null response',
        url,
        action
      );
      return;
    }

    const responseJson = yield response.json();
    let errors = parseErrors(responseJson);

    if (response.status >= 200 && response.status <= 299) {
      if (errors.length === 0) {
        yield put(pdfOverlayCreated(responseJson));
        if (callback) {
          callback(responseJson.id);
        }
      } else {
        yield put(pdfOverlayCreateFailed(errors));
      }
    } else {
      yield put(pdfOverlayCreateFailed(errors));
    }
  } catch (e) {
    console.log('Error creating PdfOverlay ' + e.message);
    yield put(pdfOverlayFetchFailed([INTERNAL_ERROR]));
  }
}

export function* updatePdfOverlay(action) {
  try {
    const { callback, pdfOverlay, pdfDocumentId } = action.payload;
    let url =
      getApiBaseUrl() +
      '/pdf_documents/' +
      pdfDocumentId +
      '/pdf_overlays/' +
      pdfOverlay.id;
    let body = { pdf_overlay: pdfOverlay };

    const headers = yield call(getHeaders);
    const response = yield call(
      callApi,
      url,
      'PATCH',
      headers,
      JSON.stringify(body),
      action
    );

    if (!response) {
      console.error(
        '[pdfOverlaySaga] updatePdfOverlay, null response',
        url,
        action
      );
      return;
    }

    const responseJson = yield response.json();
    let errors = parseErrors(responseJson);

    if (response.status >= 200 && response.status <= 299) {
      if (errors.length === 0) {
        yield put(pdfOverlayUpdated(responseJson));
        if (callback) {
          callback();
        }
      } else {
        yield put(pdfOverlayUpdateFailed(errors));
        if (callback) {
          callback();
        }
      }
    } else {
      yield put(pdfOverlayUpdateFailed(errors));
      if (callback) {
        callback();
      }
    }
  } catch (e) {
    console.log('Error updating PdfOverlay ' + e.message);
    yield put(pdfOverlayFetchFailed([INTERNAL_ERROR]));
  }
}

export function* deletePdfOverlay(action) {
  try {
    let { pdfDocumentId, pdfOverlayId, callback } = action.payload;

    let url =
      getApiBaseUrl() +
      '/pdf_documents/' +
      pdfDocumentId +
      '/pdf_overlays/' +
      pdfOverlayId;

    const headers = yield call(getHeaders);
    const response = yield call(callApi, url, 'DELETE', headers, null, action);

    if (!response) {
      console.error(
        '[pdfOverlaySaga] deletePdfOverlay, null response',
        url,
        action
      );
      return;
    }

    const responseJson = yield response.json();
    let errors = parseErrors(responseJson);

    if (response.status >= 200 && response.status <= 299) {
      if (errors.length === 0) {
        yield put(pdfOverlayDeleted(responseJson.id));
        callback();
      } else {
        yield put(pdfOverlayDeleteFailed(errors));
      }
    } else {
      yield put(pdfOverlayDeleteFailed(errors));
    }
  } catch (e) {
    console.log('Error deleting PdfOverlay ' + e.message);
    yield put(pdfOverlayDeleteFailed([INTERNAL_ERROR]));
  }
}
