import React from 'react';
import PdfPage from './PdfPage';
import { getFieldFromCoord, getFieldCoords } from './Util';

export default class Pdf extends React.Component {
  constructor(props) {
    super(props);

    this.canvas = null;
    this.curPage = 1;
    this.pdfHeight = 0;
    this.pdfWidth = 0;

    this.state = {
      pdfPages: [],
      pages: [],
      pdf: null,
      loading: true
    };

    this.getMouse = this.getMouse.bind(this);
    this.renderPage = this.renderPage.bind(this);
    this.renderNextPage = this.renderNextPage.bind(this);
  }

  renderPdf = () => {
    this.props.pdf.getPage(this.curPage++).then(this.renderPage);
  };

  renderPage = (page) => {
    let viewPort = page.getViewport({ scale: 1.0 });
    let canvas = (
      <PdfPage
        key={this.curPage - 1}
        page={page}
        pageRendered={this.renderNextPage}
      />
    );

    this.pdfHeight += viewPort.height;
    this.pdfWidth = viewPort.width;

    let pdfPages = Object.assign([], this.state.pdfPages);
    pdfPages.push(canvas);
    let pages = Object.assign([], this.state.pages);
    pages.push({
      page: this.curPage - 1,
      yZero: this.pdfHeight,
      height: viewPort.height,
      width: viewPort.width
    });
    this.setState({ pdfPages: pdfPages, pages: pages });
  };

  renderNextPage = () => {
    if (this.curPage !== this.props.pdf.numPages + 1) {
      this.props.pdf.getPage(this.curPage++).then(this.renderPage);
    } else {
      // finished rendering pages, fix height and width
      this.canvas.height = this.pdfHeight;
      this.canvas.width = this.pdfWidth;
      this.setState({ loading: false }, () => this.drawFields());
    }
  };

  componentDidUpdate(prevProps) {
    //console.log("Pdf received componentDidUpdate!");
    if (this.props.pdf !== prevProps.pdf) {
      this.setState(
        {
          pdfPages: [],
          pages: [],
          pdf: null,
          loading: true
        },
        () => {
          this.curPage = 1;
          this.canvas = null;
          this.pdfWidth = 0;
          this.pdfHeight = 0;
          this.renderPdf();
        }
      );
    }
    this.drawFields();
  }

  componentDidMount() {
    this.renderPdf();
  }

  drawFields = () => {
    if (this.state.loading || this.props.loading) {
      return;
    }

    let ctx = this.canvas.getContext('2d');
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let coordsArr = [];
    let highlightArr = [];
    this.props.fields.forEach((field) => {
      let fieldCoord = getFieldCoords(field);
      // translate the y coordinate to our single canvas with coords 0,0 at top right

      fieldCoord.forEach((coordObj) => {
        let pages = this.state.pages;

        let page = pages[coordObj.page - 1];
        //reset coord 0,0 for current page (top left)
        // coordArr[1] = page.yZero - coordArr[1];
        coordObj.coords[1] = this.fromPdfYCoord(coordObj.coords[1], page);
        //invert coord system to start at bottom left
        // coordArr[1] = page.height - coordArr[1]

        // Highlight all the coords of the current field
        if (
          this.props.field != null &&
          field.external_id === this.props.field.external_id
        ) {
          highlightArr.push(coordObj.coords);
        }
      });

      coordsArr = coordsArr.concat(fieldCoord);
    });

    coordsArr.forEach((coordObj) => {
      let coords = coordObj.coords;

      ctx.fillStyle = 'rgba(255,255,0,.6)';
      ctx.fillRect(coords[0], coords[1] - coords[3], coords[2], coords[3]);

      // draw resize corner
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.fillStyle = 'rgba(0,0,0,.3)';
      ctx.moveTo(coords[0] + coords[2] - 8, coords[1]);
      ctx.lineTo(coords[0] + coords[2], coords[1] - 8);
      ctx.lineTo(coords[0] + coords[2], coords[1]);
      ctx.fill();
    });

    // TODO: revisit
    highlightArr.forEach((coords) => {
      ctx.strokeStyle = 'black';
      ctx.strokeRect(coords[0], coords[1] - coords[3], coords[2], coords[3]);
      ctx.fillStyle = 'rgba(0,255,0,.6)';
      ctx.fillRect(coords[0], coords[1] - coords[3], coords[2], coords[3]);
    });
  };

  getMouse = (e) => {
    var element = this.canvas,
      offsetX = 0,
      offsetY = 0,
      mx,
      my;

    // Compute the total offset
    if (element.offsetParent !== undefined) {
      do {
        offsetX += element.offsetLeft;
        offsetY += element.offsetTop;
      } while ((element = element.offsetParent));
    }

    // Add padding and border style widths to offset
    // Also add the <html> offsets in case there's a position:fixed bar
    offsetX += 0; //this.stylePaddingLeft + this.styleBorderLeft + this.htmlLeft;
    offsetY += 0; //this.stylePaddingTop + this.styleBorderTop + this.htmlTop;

    mx = e.pageX - offsetX;
    my = e.pageY - offsetY;

    // We return a simple javascript object (a hash) with x and y defined
    return { x: mx, y: my };
  };

  // This will adjust the x and y coord based on the PDF coordinate system
  // in Pdf spec the Y axis starts at the bottom left corner
  // We also need to account for pages, in our implementation we use one long
  // pdf canvas, but in reality each page has a coordinate system on its lower left of 0,0
  // canvas has a coordinate system of 0,0 in the top left
  getPdfMouse = (mouse) => {
    let pdfMouse = { x: mouse.x, y: null, page: null };

    let pages = this.state.pages;

    let page = null;

    // let height = 0;

    for (let i = 1; i <= pages.length; i++) {
      let p = pages[i - 1];
      // if (mouse.y <= (height + p.height)) {

      if (mouse.y <= p.yZero) {
        page = p;
        break;
      }
      // height += p.height
    }

    if (pages == null) {
      console.log('Issue calculcating PDF mouse: ' + JSON.stringify(mouse));
      return null;
    }

    // //reset coord 0,0 for current page to top left
    // let pdfY = page.height - mouse.y;
    //
    // //add page offset in single canvas
    // pdfY = page.yZero - page.height + pdfY;
    //
    // pdfMouse.y = pdfY;
    //
    // return pdfMouse

    pdfMouse.page = page;
    pdfMouse.y = this.toPdfYCoord(mouse.y, page);
    return pdfMouse;
  };

  onMouseDown = (e) => {
    let mouse = this.getMouse(e);
    let pdfMouse = this.getPdfMouse(mouse);

    console.log(
      'mouse : pdfMouse - ' + JSON.stringify(mouse) + JSON.stringify(pdfMouse)
    );

    let mx = mouse.x;
    let my = mouse.y;
    let py = pdfMouse.y;
    let page = pdfMouse.page;
    //console.log("mouse down " + mx + " " + my);
    // for (let i = 0; i < this.props.fields.length; i++) {

    let field = getFieldFromCoord(this.props.fields, mx, py, page);
    // Get the field that was clicked
    // let c = field.coords;
    // if (c[0] <= mx && (c[0] + c[2]) >= mx &&
    //     c[1] <= my && (c[1] + c[3]) >= my ) {
    if (field != null) {
      console.log(
        'clicked field: ' + field.external_id + ' ' + mx + ' ' + my + ' ' + py
      );
      let c = field.coords;

      this.dragging = true;
      this.dragoffx = mx - c[0];
      this.dragoffy = my - this.fromPdfYCoord(c[1], page); //(page.height - c[1] + page.yZero - page.height);
      this.props.setField(field.external_id, () => {
        console.log(
          'Setting current field to: ' +
            field.external_id +
            ' field data: ' +
            JSON.stringify(field)
        );
        this.field = Object.assign({}, field);
      });

      let x2 = c[0] + c[2];
      let y2 = this.fromPdfYCoord(c[1], page) - c[3];
      if (Math.abs(x2 - mx) <= 10 && Math.abs(y2 - my) <= 10) {
        this.resizing = true;
      }

      return;
      // }
    }
  };

  onMouseMove = (e) => {
    let mouse = this.getMouse(e);
    let mx = mouse.x;
    let my = mouse.y;
    let field = this.field;
    let page;

    if (field !== undefined && field !== null) {
      page = this.state.pages[field.page - 1];
    }

    if (this.resizing) {
      field.coords[2] = Math.abs(mx - field.coords[0]);
      field.coords[3] = Math.abs(my - this.fromPdfYCoord(field.coords[1]));

      //Signature fields need the original coords
      let page = this.state.pages[field.page - 1];
      if (field.pdf_coords !== undefined && field.pdf_coords !== null) {
        field.pdf_coords[2] = Math.abs(mx - field.pdf_coords[0]);
        field.pdf_coords[3] = field.coords[3];
        field.pdf_coords[1] = page.height - field.coords[1] - field.coords[3];
      }
      //Signature date fields need the original coords
      if (
        field.pdf_date_coords !== undefined &&
        field.pdf_date_coords != null
      ) {
        field.pdf_date_coords[2] = Math.abs(mx - field.pdf_date_coords[0]);
        field.pdf_date_coords[3] = field.coords[3];
        field.pdf_date_coords[1] =
          page.height - field.coords[1] - field.coords[3];
      }

      this.props.onChange(field);
    } else if (this.dragging) {
      //console.log("Dragging " + this.field.name + " " + mx + " " + my);
      // let page = this.state.pages[field.page - 1];
      if (my - this.dragoffy > page.yZero) {
        field.page = field.page + 1;
      } else if (my - this.dragoffy < page.yZero - page.height) {
        field.page = field.page - 1;
      }

      field.coords[0] = mx - this.dragoffx;
      field.coords[1] = this.toPdfYCoord(my - this.dragoffy);

      //Signature fields need the original coords
      if (field.pdf_coords !== undefined && field.pdf_coords !== null) {
        field.pdf_coords[0] = field.coords[0];
        field.pdf_coords[1] = page.height - field.coords[1] - field.coords[3];
      }
      //Signature date need the original coords
      if (
        field.pdf_date_coords !== undefined &&
        field.pdf_date_coords !== null
      ) {
        field.pdf_date_coords[0] = field.coords[0];
        field.pdf_date_coords[1] =
          page.height - field.coords[1] - field.coords[3];
      }
      this.props.onChange(field);
    }
  };

  toPdfYCoord = (y, page = null) => {
    if (page == null) {
      page = this.state.pages[this.field.page - 1];
    }

    return page.height - y + page.yZero - page.height;
  };

  fromPdfYCoord = (py, page = null) => {
    if (page == null) {
      page = this.state.pages[this.field.page - 1];
    }

    return page.height - py + page.yZero - page.height;
  };

  onMouseUp = (e) => {
    this.dragging = false;
    this.field = null;
    this.resizing = false;
    //console.log("Mouse up")
  };

  render() {
    return (
      <div style={container}>
        <canvas
          style={canvasStyle}
          ref={(ref) => {
            this.canvas = ref;
          }}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseUp={this.onMouseUp}
        ></canvas>
        {this.state.pdfPages}
      </div>
    );
  }
}

const container = {
  position: 'relative',
  width: '612px',
  lineHeight: 0
};

const canvasStyle = {
  position: 'absolute',
  width: '612px'
};
