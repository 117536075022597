import React from 'react';
import DateTimeField from '../pdf_annotator/DateTimeField';
import SignaturesField from './signaturesField';
import {
  createPdfFieldForPdfTemplate,
  updatePdfFieldForPdfTemplate
} from '../shared/Network';
import HtmlDocumentTree from './HtmlDocumentTree';
import RadioField from '../pdf_annotator/RadioField';
import CompositeField from '../pdf_annotator/CompositeField';
import CheckboxField from '../pdf_annotator/CheckboxField';
import {
  clearPdfField,
  pdfTemplateFieldRequested,
  pdfTemplateFetchRequested,
  updatePdfTemplateRequest,
  pdfTemplateFieldsRequested
} from './redux/actions/pdfTemplateActions';
import CopyField from './CopyField';
import { connect } from 'react-redux';
import AddendumToField from './AddendumToField';
import { Box } from '@mui/material';
import DocumentInfoFields from './DocumentInfoFields';
import TextField from '../pdf_annotator/TextField';

class HtmlFieldForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      params: {},
      showJsonPreview: false,
      formField: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.pdf !== prevProps.pdf) {
      this.props.clearPdfField();
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
  };

  save = () => {
    let field = this.getFieldParams();

    if (field.id == null) {
      createPdfFieldForPdfTemplate(this.props.pdfTemplate.id, field).then(
        (response) => {
          response.json().then(this.handleResponse);
        }
      );
    } else {
      updatePdfFieldForPdfTemplate(
        this.props.pdfTemplate.id,
        field.id,
        field
      ).then((response) => {
        response.json().then(this.handleResponse);
      });
    }
  };

  previewJson = () => {
    this.setState({ showJsonPreview: true });
  };

  handleResponse = (json) => {
    if (json.errors == null) {
      this.props.clearPdfField();
      this.props.updatePdfFields(this.props.pdfTemplate.id);
    } else {
      console.log('Errors: ' + JSON.stringify(json.errors));
    }
  };

  getFieldParams = () => {
    let field = JSON.parse(JSON.stringify(this.props.pdfField));
    if (field == null) field = {};

    // remove the id from new fields
    if (typeof field.id === 'string' && field.external_id.startsWith('u_')) {
      delete field.external_id;
    }

    if (typeof field.id === 'string' && field.id.startsWith('u_')) {
      delete field.id;
    }

    if (field.field != null && field.field.value == '') {
      delete field.field.value;
    }

    return field;
  };

  close = () => {
    this.props.clearPdfField(this.props.close);
  };

  setField = (e) => {
    let value = e.target.value;
    if (value !== '') {
      this.props.setPdfField(this.props.pdfTemplate.id, value);
    }
  };

  getFieldOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a field ---
      </option>
    );
    {
      this.props.pdfFields.forEach((field) => {
        options.push(
          <option key={field.id} value={field.id}>
            {field.name}
          </option>
        );
      });
    }
    return options;
  };

  getFieldActions = () => {
    return (
      <div>
        <button onClick={this.save}>Save</button>
        <button onClick={this.close}>Cancel</button>
        <button onClick={this.props.delete}>Delete</button>
        <button onClick={this.previewJson}>Preview JSON</button>
      </div>
    );
  };

  getTextElement = () => {
    return (
      <TextField
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getSignaturesElement = () => {
    return (
      <SignaturesField
        field={this.props.pdfField}
        name={this.state.name}
        onChange={this.props.onChange}
      />
    );
  };

  getDateTimeElement = () => {
    return (
      <DateTimeField
        field={this.props.pdfField}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getCheckboxElement = () => {
    return (
      <CheckboxField
        field={this.props.pdfField}
        fieldWidth={this.props.fieldWidth}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
        inject={this.props.inject}
      />
    );
  };

  getRadioElement = () => {
    return (
      <RadioField
        field={this.props.pdfField}
        fieldWidth={this.props.fieldWidth}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
        inject={this.props.inject}
      />
    );
  };

  getCompositeElement = () => {
    return (
      <CompositeField
        pdfDocument={this.props.pdfDocument}
        field={this.props.pdfField}
        fieldWidth={this.props.fieldWidth}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
        inject={this.props.inject}
      />
    );
  };

  getFieldElement = () => {
    if (this.props.pdfField == null) {
      return null;
    }
    switch (this.props.pdfField.field_type) {
      case 'text':
      case 'text_area':
        return this.getTextElement();
      case 'datetime':
        return this.getDateTimeElement();
      case 'radio':
        return this.getRadioElement();
      case 'checkbox':
        return this.getCheckboxElement();
      case 'composite':
        return this.getCompositeElement();
      case 'signatures':
        return this.getSignaturesElement();
      default:
        return null;
    }
  };

  injectField = () => {
    // TODO: Differentiate between different field types
    let injectableText = '';
    switch (this.props.pdfField.field_type) {
      case 'text_area':
        injectableText = `<span id="${this.props.pdfField.external_id}" class="field_container"><span class="field" th:if="\${fields.containsKey('${this.props.pdfField.external_id}')}" style="display: inline-block; min-width: ${this.props.fieldWidth}">
<table width="95%" align="center" cellspacing="0" cellpadding="0" border="0"><tr><td align="left">
    <span th:utext="\${fields['${this.props.pdfField.external_id}']}"></span></td>
      </tr>
    </table></span></span>`;
        break;
      case 'text':
      case 'datetime':
        injectableText = `<span id="${this.props.pdfField.external_id}" class="field_container"><span class="field" style="border-bottom: 1px solid #000;" th:if="\${fields.containsKey('${this.props.pdfField.external_id}')}">
    <span th:text="\${fields['${this.props.pdfField.external_id}']}"></span>
</span>
<span class="field" style="border-bottom: 1px solid #000; min-width: ${this.props.fieldWidth}; display: inline-block;" th:unless="\${fields.containsKey('${this.props.pdfField.external_id}')}"></span></span>`;
        break;
      case 'signatures':
        injectableText = `
          <input class="field ${this.props.pdfField.field_type}" type="text" name="${this.props.pdfField.external_id}" value="${this.props.pdfField.field.signature_type}" size="${this.props.fieldWidth}" style="border: none; height: ${this.props.pdfField.field.height}px; " />`;
        break;
      default:
        break;
    }
    this.props.inject(injectableText);
  };

  showInjectFieldButton() {
    return (
      this.props.pdfField &&
      [
        'text',
        'text_area',
        'datetime',
        'signatures',
        'signature',
        'date_signed',
        'initials'
      ].includes(this.props.pdfField.field_type) &&
      !this.props.pdfField.id.startsWith('u_')
    );
  }

  render() {
    const previewJson = (
      <div style={jsonPreview}>
        <pre>{JSON.stringify(this.getFieldParams(), undefined, 4)}</pre>
        <button onClick={() => this.setState({ showJsonPreview: false })}>
          Close
        </button>
      </div>
    );

    return (
      <div style={this.props.style}>
        <div style={{ flexDirection: 'col' }}>
          <div>
            <button
              onClick={() => {
                this.props.newField('text');
              }}
            >
              Text Field
            </button>
            <br />
            <button
              onClick={() => {
                this.props.newField('text_area');
              }}
            >
              Text Area Field
            </button>
            <br />
            <button
              onClick={() => {
                this.props.newField('datetime');
              }}
            >
              DateTime Field
            </button>
            <br />
            <button
              onClick={() => {
                this.props.newField('radio');
              }}
            >
              Radio Field
            </button>
            <br />
            <button
              onClick={() => {
                this.props.newField('checkbox');
              }}
            >
              Checkbox Field
            </button>
            <br />
            <button
              onClick={() => {
                this.props.newField('composite');
              }}
            >
              Composite Field
            </button>
            <br />
            <button
              onClick={() => {
                this.props.newField('signatures');
              }}
            >
              Signatures
            </button>
            <div className='mb-2'>
              <CopyField />
            </div>
          </div>
          <form onSubmit={this.onSubmit}>
            {/*<select onChange={this.setField} value={this.props.pdfField == null ? "" : this.props.pdfField.id}>{this.getFieldOptions()}</select>*/}
            <br />
            {this.showInjectFieldButton() ? (
              <button onClick={() => this.injectField()}>Inject Field</button>
            ) : (
              ''
            )}
            <br />
            <div>
              <br />
              {this.props.pdfField != null
                ? this.props.pdfField.external_id
                : ''}
            </div>
            {/*This is where it's not working. For some reason, the field element isn't showing up.*/}
            {this.getFieldElement()}
            {this.state.showJsonPreview ? previewJson : null}
            {this.props.pdfField != null ? this.getFieldActions() : null}

          </form>
          <div>
            <div>
              <h3>Document Information</h3>
            </div>
            <Box mb={2}>
              <AddendumToField pdfTemplate={this.props.pdfTemplate} />
            </Box>
            <Box>
              <DocumentInfoFields pdfTemplate={this.props.pdfTemplate} />
            </Box>
          </div>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <HtmlDocumentTree
            onClick={(id) =>
              this.props.setPdfField(this.props.pdfTemplate.id, id)
            }
          />

          {this.showInjectFieldButton() ? (<button onClick={() => this.injectField()}>Inject Field</button> ): ''}
        </div>
      </div>
    );
  }
}

const jsonPreview = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000'
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPdfField: (pdfTemplateId, pdfFieldId = null) =>
      dispatch(pdfTemplateFieldRequested(pdfTemplateId, pdfFieldId)),
    fetchPdfTemplate: (pdfTemplateId) =>
      dispatch(pdfTemplateFetchRequested(pdfTemplateId)),
    clearPdfField: () => dispatch(clearPdfField()),
    // updateHtmlTemplate: (pdfTemplateId, htmlTemplate) => dispatch(updatePdfTemplateRequest(pdfTemplateId, htmlTemplate)),
    updatePdfFields: (pdfTemplateId) =>
      dispatch(pdfTemplateFieldsRequested(pdfTemplateId))
  };
};

const mapStateToProps = (state) => {
  return {
    pdfFields: state.pdfTemplates.pdfFields,
    pdfField: state.pdfTemplates.pdfField
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HtmlFieldForm);
