import React from 'react';
import CheckboxOption from './CheckboxOption';

export default class CheckboxField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedElementOption: null
    };

    this.injectSection = this.injectSection.bind(this);
  }

  onChange = (data) => {
    let field = JSON.parse(JSON.stringify(this.props.field));

    let dataField = data.field;
    delete data.field;

    Object.assign(field.field, dataField);
    Object.assign(field, data);

    this.props.onChange(field);
  };

  getSectionOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a Section ---
      </option>
    );
    {
      this.props.sections.forEach((section) => {
        options.push(
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        );
      });
    }
    return options;
  };

  getOptionElements = () => {
    let options = this.props.field.field.options;
    let optionElements = [];

    if (options != null) {
      options.forEach((option, idx) => {
        optionElements.push(
          <CheckboxOption
            key={idx}
            index={idx}
            option={option}
            inject={this.props.inject}
            fields={this.props.fields}
            onChange={this.updateOptions}
          />
        );
      });
    }
    return optionElements;
  };

  addOption = () => {
    let options = Object.assign([], this.props.field.field.options);
    let option = {
      label: 'option' + options.length,
      value: 'value' + options.length,
      coords: [0, 0, 20, 20],
      page: 1
    };
    options.push(option);
    this.onChange({ field: { options: options } });
  };

  updateOptions = (id, data) => {
    let options = Object.assign([], this.props.field.field.options);

    options[id] = data;
    this.onChange({ field: { options: options } });
  };

  changeDefaultOption = (key, value) => {
    // "value"=>{"value"=>"", "sub_key"=>"county"}},
    let defaultValue = this.props.field.field.value;

    if (defaultValue === '') {
      defaultValue = { value: '', sub_key: '' };
    } else {
      defaultValue = Object.assign({}, defaultValue);

      if (key === 'sub_key') {
        // reset default vault
        if (value === '') {
          this.onChange({ field: { value: '' } });
          return;
        }
        defaultValue.value = '';
      }
    }
    defaultValue[key] = value;

    this.onChange({ field: { value: defaultValue } });
  };

  getDefaultValueElements = () => {
    let field = this.props.field.field;
    let defaultValue = '';
    let defaultSubKey = '';

    if (field.value != null) {
      defaultValue = field.value.value;
      defaultSubKey = field.value.sub_key;
    }

    let options = [];

    field.options.forEach((option) => {
      options.push(
        <option key={option.value} value={option.value}>
          {option.value}
        </option>
      );
    });

    return (
      <div>
        <select
          value={defaultSubKey || ''}
          onChange={(e) => this.changeDefaultOption('sub_key', e.target.value)}
        >
          <option value="">-- Select a default value --</option>
          {options}
        </select>
        <label>Default Text</label>
        <input
          type="text"
          name="default_text_value"
          value={defaultValue}
          onChange={(e) => this.changeDefaultOption('value', e.target.value)}
        />
      </div>
    );
  };

  injectSection = (option) => {
    let optionValue = this.state.selectedElementOption;

    if (
      optionValue === undefined ||
      optionValue === null ||
      optionValue === ''
    ) {
      console.log('Nothing selected to inject');
      return null;
    }

    console.log('Injecting the section');
    let textField = false;
    let TEXT_FIELD = 'TEXT_FIELD';
    if (optionValue.endsWith(TEXT_FIELD)) {
      optionValue = optionValue.substr(
        0,
        optionValue.length - TEXT_FIELD.length
      );
      textField = true;
    }

    const injectable = `<span id="${
      this.props.field.external_id
    }" class="field_container"><span class="field" style="${
      textField ? 'border-bottom: 1px solid #000;' : 'text-align:center;'
    }display: inline-block; min-width: ${
      this.props.fieldWidth
    };" th:if="\${fields.containsKey('${this.props.field.external_id}')}">
${
  textField
    ? `<span th:if="\${fields['${this.props.field.external_id}'].containsKey('${optionValue}')}"><span th:text="\${fields['${this.props.field.external_id}']['${optionValue}']['value']}"></span></span>`
    : `<span th:text="\${fields['${this.props.field.external_id}'].containsKey('${optionValue}')}? (\${fields['${this.props.field.external_id}']['${optionValue}']['checked']}? 'X' : '') : '' "></span>`
} 
</span>
<span style="display: inline-block; min-width:${
      this.props.fieldWidth
    }; text-align:center;" th:unless="\${fields.containsKey('${
      this.props.field.external_id
    }')}"></span></span>`;
    this.props.inject(injectable);
  };

  getElementInjectOptions = () => {
    let elements = [];

    let optionFields = this.props.field.field.options;
    optionFields.forEach((option, index) => {
      elements.push(
        <option key={index} index={index} value={option.value}>
          {option.label}
        </option>
      );

      if (option.text_field) {
        elements.push(
          <option key={index} index={index} value={option.value + 'TEXT_FIELD'}>
            {option.label + ' Text Field'}
          </option>
        );
      }
    });
    return elements;
  };

  render() {
    let field = this.props.field;
    return (
      <div>
        <select
          multiple={false}
          onChange={(e) => {
            this.setState({ selectedElementOption: e.target.value });
          }}
        >
          <option value="">-- Select an option to inject --</option>
          {this.getElementInjectOptions()}
        </select>
        <button onClick={this.injectSection}>Inject Option</button>
        <br />
        <input type="hidden" value={field.external_id} />
        <label>Name</label>
        <br />
        <input
          type="text"
          name="name"
          value={field.name}
          onChange={(e) =>
            this.onChange({
              name: e.target.value,
              field: { key: e.target.value }
            })
          }
        />
        <br />
        <label>Title</label>
        <br />
        <input
          type="text"
          value={field.field.title || ''}
          onChange={(e) => this.onChange({ field: { title: e.target.value } })}
        />
        <br />
        <label>Field Type</label>
        <br />
        <input
          type="text"
          name="field_type"
          value={field.field_type}
          readOnly={true}
        />
        <br />

        <label>Default Value</label>
        <br />
        {this.getDefaultValueElements()}

        <label htmlFor="context">Context</label>
        <br />
        <textarea
          onChange={(e) =>
            this.onChange({ field: { context: e.target.value } })
          }
          value={field.field.context || ''}
        />
        <br />

        <b>Options</b>
        <br />
        {this.getOptionElements()}
        <a
          href="#"
          onClick={(e) => {
            this.addOption();
            e.preventDefault();
          }}
        >
          + Add Option
        </a>
        <br />

        <label>hidden</label>
        <br />
        <input
          type="checkbox"
          checked={field.field.hidden || false}
          onChange={(e) =>
            this.onChange({ field: { hidden: e.target.checked } })
          }
        />
        <br />

        <label>formula</label>
        <br />
        <input
          type="text"
          value={field.field.formula || ''}
          onChange={(e) =>
            this.onChange({ field: { formula: e.target.value } })
          }
        />
        <br />
        <label>pdf section</label>
        <br />
        <select
          value={field['pdf_section_id'] || ''}
          onChange={(e) => this.onChange({ pdf_section_id: e.target.value })}
        >
          {this.getSectionOptions()}
        </select>
        <br />
        <label>Order</label>
        <br />
        <input
          type="number"
          value={field.order || ''}
          onChange={(e) => this.onChange({ order: e.target.value })}
        />
        <br />
      </div>
    );
  }
}
