import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["splitName", "filesContainer", "errorMessage", "submitButton", "splitRange"];

  connect() {
    this.setSplitNameValue();
    this.updateFiles();
  }

  setSplitNameValue() {
    const pageCount = parseInt(this.data.get("pageCount"), 10) || 0;
    if (pageCount) {
      this.splitNameTarget.value = '';
    }
  }

  updateFiles() {
    const pageCount = parseInt(this.data.get("pageCount"), 10) || 0;
    const splitCount = parseInt(this.splitNameTarget.value, 10) || 0;

    if (splitCount > pageCount) {
      this.errorMessageTarget.textContent = `Split document count can't be more than ${pageCount}.`;
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.style.display = 'none';
      return;
    } else {
      this.errorMessageTarget.textContent = "";
      this.submitButtonTarget.disabled = false;
    }

    const currentFileCount = this.filesContainerTarget.children.length;

    if (splitCount > currentFileCount && splitCount <= pageCount) {
      for (let i = currentFileCount; i < splitCount; i++) {
        this.addFileFields(i + 1);
      }
    } else if (splitCount < currentFileCount) {
      for (let i = currentFileCount; i > splitCount; i--) {
        this.removeFileFields();
      }
    }

    // Show the submit button if there is at least one file row
    if (splitCount > 0 && splitCount <= pageCount) {
      this.submitButtonTarget.style.display = 'block';
    } else {
      this.submitButtonTarget.style.display = 'none';
    }

    // Trigger validation for all existing file fields
    this.validatePages(false);
  }

  addFileFields(index) {
    const fileFieldsHTML = `
      <div class="row mb-3" data-split-documents-target="file">
        <div class="col-md-6">
          <input style="font-size: 0.75rem;" class="form-control" id="file${index}Name" type="text" placeholder="File ${index} Name" required data-split-documents-target="fileName">
        </div>
        <div class="col-md-3">
          <input style="font-size: 0.75rem;" class="form-control" id="file${index}StartPage" type="number" min="1" max="${this.data.get("pageCount")}" placeholder="Start Page" required data-split-documents-target="startPage" data-action="input->split-documents#updateEndPageMin input->split-documents#validatePages">
          <div class="invalid-feedback" data-split-documents-target="startPageError"></div>
        </div>
        <div class="col-md-3">
          <input style="font-size: 0.75rem;" class="form-control" id="file${index}EndPage" type="number" min="1" max="${this.data.get("pageCount")}" placeholder="End Page" required data-split-documents-target="endPage" data-action="input->split-documents#validatePages">
          <div class="invalid-feedback" data-split-documents-target="endPageError"></div>
        </div>
      </div>
    `;
    this.filesContainerTarget.insertAdjacentHTML('beforeend', fileFieldsHTML);
  }

  removeFileFields() {
    const lastFileField = this.filesContainerTarget.lastElementChild;
    if (lastFileField) {
      lastFileField.remove();
    }
  }

  updateEndPageMin(event) {
    const startPageInput = event.target;
    const endPageInput = startPageInput.closest('[data-split-documents-target="file"]').querySelector('[data-split-documents-target="endPage"]');
    endPageInput.min = startPageInput.value;
  }

  validatePages(showErrors = true) {
    const pageCount = parseInt(this.data.get("pageCount"), 10) || 0;
    const fileRows = this.filesContainerTarget.querySelectorAll('[data-split-documents-target="file"]');
    let isValid = true;

    fileRows.forEach(fileRow => {
      const startPageInput = fileRow.querySelector('[data-split-documents-target="startPage"]');
      const endPageInput = fileRow.querySelector('[data-split-documents-target="endPage"]');
      const startPage = parseInt(startPageInput.value, 10);
      const endPage = parseInt(endPageInput.value, 10);

      if (isNaN(startPage) || isNaN(endPage) || startPage > endPage || startPage < 1 || endPage > pageCount) {
        isValid = false;
        if (showErrors) {
          if (isNaN(startPage) || startPage < 1 || startPage > pageCount) {
            startPageInput.title = `Start Page must be between 1 and ${pageCount}.`;
          } else {
            startPageInput.title = '';
          }
          if (isNaN(endPage) || endPage > pageCount || endPage < startPage) {
            endPageInput.title = `End Page must be between ${startPage} and ${pageCount}.`;
          } else {
            endPageInput.title = '';
          }
        }
      } else {
        startPageInput.title = '';
        endPageInput.title = '';
      }
    });

    if (!isValid && showErrors) {
      this.errorMessageTarget.textContent = "Please correct the errors below.";
      this.submitButtonTarget.disabled = true;
    } else {
      this.errorMessageTarget.textContent = "";
      this.submitButtonTarget.disabled = false;
    }
  }

  submitForm(event) {
    const fileRows = this.filesContainerTarget.querySelectorAll('[data-split-documents-target="file"]');
    const splitRange = [];

    fileRows.forEach(fileRow => {
      const fileName = fileRow.querySelector('[data-split-documents-target="fileName"]').value;
      const startPage = parseInt(fileRow.querySelector('[data-split-documents-target="startPage"]').value, 10);
      const endPage = parseInt(fileRow.querySelector('[data-split-documents-target="endPage"]').value, 10);

      splitRange.push({ start_page: startPage, end_page: endPage, name: fileName });
    });

    this.splitRangeTarget.value = JSON.stringify(splitRange);
  }
}