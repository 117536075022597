import { put, call } from 'redux-saga/effects';
import { getHeaders, getApiBaseUrl, API_URL } from '../../../shared/Network';
import {
  pdfTemplatesFetched,
  pdfTemplateFetched,
  pdfTemplateUpdated,
  pdfTemplateFieldsFetched,
  pdfTemplateDeleted,
  pdfTemplateFieldFetched,
  pdfDownloaded
} from '../actions/pdfTemplateActions';

export function* getPdfTemplates(action) {
  try {
    let url =
      getApiBaseUrl() + '/pdf_templates?mls_org=' + action.payload.mlsOrgId;

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield call(getHeaders)
    });

    if (response.status >= 400) {
      console.log('Error getting PdfTemplates: ' + JSON.stringify(response));
    } else {
      const responseJson = yield response.json();
      yield put(pdfTemplatesFetched(responseJson));
    }
  } catch (e) {
    console.log('Error getting PdfDocuments ' + e.message);
  }
}

export function* getPdfTemplate(action) {
  try {
    let url =
      getApiBaseUrl() +
      '/pdf_templates/' +
      action.payload.pdfTemplateId +
      '?template=true';

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield call(getHeaders)
    });

    if (response.status >= 400) {
      console.log('Error getting PdfTemplates: ' + JSON.stringify(response));
    } else {
      const responseJson = yield response.json();
      yield put(pdfTemplateFetched(responseJson));
    }
  } catch (e) {
    console.log('Error getting PdfDocuments ' + e.message);
  }
}

export function* getPdfTemplateFields(action) {
  const { pdfTemplateId } = action.payload;
  try {
    let url =
      getApiBaseUrl() + '/pdf_templates/' + pdfTemplateId + '/pdf_fields';
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield call(getHeaders)
    });
    if (response.status >= 400) {
      console.log(
        'Error getting Fields for Pdf Template: ' + JSON.stringify(response)
      );
    } else {
      const responseJson = yield response.json();
      yield put(pdfTemplateFieldsFetched(responseJson));
    }
  } catch (e) {
    console.log('Error getting Fields for Pdf Template: ' + e.message);
  }
}

export function* getPdfTemplateField(action) {
  const { pdfTemplateId, pdfFieldId } = action.payload;
  try {
    let url =
      getApiBaseUrl() +
      '/pdf_templates/' +
      pdfTemplateId +
      '/pdf_fields/' +
      pdfFieldId;
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield call(getHeaders)
    });
    if (response.status >= 400) {
      console.log(
        'Error getting Fields for Pdf Template: ' + JSON.stringify(response)
      );
    } else {
      const responseJson = yield response.json();
      yield put(pdfTemplateFieldFetched(responseJson));
    }
  } catch (e) {
    console.log('Error getting Fields for Pdf Template: ' + e.message);
  }
}

export function* updatePdfTemplate(action) {
  const { htmlTemplate, pdfTemplateId } = action.payload;
  try {
    let url = getApiBaseUrl() + '/pdf_templates/' + pdfTemplateId;

    let body = {
      pdf_template: {
        html_template: htmlTemplate
      }
    };

    const response = yield fetch(url, {
      method: 'PATCH',
      headers: yield call(getHeaders),
      body: JSON.stringify(body)
    });

    if (response.status >= 400) {
      console.log('Error updating PdfTemplates: ' + JSON.stringify(response));
    } else {
      const responseJson = yield response.json();
      yield put(pdfTemplateUpdated(responseJson));
    }
  } catch (e) {
    console.log('Error updated PdfDocuments ' + e.message);
  }
}

export function* deletePdfField(action) {
  const { pdfTemplateId, pdfFieldId } = action.payload;
  try {
    let url =
      getApiBaseUrl() +
      '/pdf_templates/' +
      pdfTemplateId +
      '/pdf_fields/' +
      pdfFieldId;

    const response = yield fetch(url, {
      method: 'DELETE',
      headers: yield call(getHeaders)
    });

    if (response.status >= 400) {
      console.log(
        `Error deleting field ${pdfFieldId}: ` + JSON.stringify(response)
      );
    } else {
      const responseJson = yield response.json();
      yield put(pdfTemplateDeleted(responseJson));
    }
  } catch (e) {
    console.log('Error updated PdfDocuments ' + e.message);
  }
}

export function* downloadPdf(action) {
  try {
    let url = 'https://pdfgen-dev.herokuapp.com/pdf_template/pdf';

    const response = yield fetch(url, {
      method: 'POST',
      body: JSON.stringify(action.payload.params),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status >= 400) {
      console.log(
        `Error deleting field ${pdfFieldId}: ` + JSON.stringify(response)
      );
    } else {
      // Get the raw pdf
      const pdfBlob = yield response.blob();

      function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            console.log('resolving the promise');
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        });
      }

      const base64Pdf = yield blobToBase64(pdfBlob);

      yield put(pdfDownloaded(base64Pdf));
    }
  } catch (e) {
    console.log('Error downloading PDF: ' + e.message);
  }
}
