import React from 'react';
import {
  getPdfSectionsFromPdfDocument,
  createPdfSectionForPdfDocument,
  updatePdfSectionForPdfDocument
} from '../shared/Network';

export default class SectionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: this.clearParams(),
      errors: [],
      showForm: false
    };
  }

  componentDidMount() {
    this.loadPdfSections();
  }

  clearParams = () => {
    return { id: '', title: '', order: '' };
  };

  loadPdfSections = (cb = null) => {
    getPdfSectionsFromPdfDocument(this.props.pdfDocument.external_id).then(
      (response) => {
        response.json().then((json) => {
          this.props.setSections(json, cb);
        });
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.pdfDocument !== prevProps.pdfDocument) {
      this.setState(
        {
          errors: [],
          params: this.clearParams(),
          showForm: false
        },
        this.loadPdfSections
      );
    }
  }

  changeSection = (e) => {
    let value = e.target.value;
    if (value === 'new') {
      this.setState({
        params: this.clearParams(),
        errors: [],
        showForm: true
      });
      console.log('Creating new section');
    } else {
      this.setSection(value);
    }
  };

  setSection = (id) => {
    let idx = this.props.sections.findIndex((s) => s.external_id === id);
    let section = Object.assign({}, this.props.sections[idx]);
    this.setState({
      params: {
        id: section.id,
        external_id: section.external_id,
        title: section.title,
        order: section.order
      },
      errors: [],
      showForm: true
    });
  };

  save = () => {
    // create or update section
    if (this.state.params.id == null || this.state.params.id === '') {
      createPdfSectionForPdfDocument(
        this.props.pdfDocument.external_id,
        this.state.params
      ).then((response) => {
        response.json().then((json) => {
          if (json['errors'] == null) {
            this.updateParam('external_id', json['id'], () => {
              this.loadPdfSections(() => this.setSection(json['id']));
            });
          }
        });
      });
    } else {
      updatePdfSectionForPdfDocument(
        this.props.pdfDocument.external_id,
        this.state.params.external_id,
        this.state.params
      ).then((response) => {
        response.json().then((json) => {
          if (json['errors'] == null) {
            this.updateParam('external_id', json['id'], this.loadPdfSections);
          }
        });
      });
    }
  };

  cancel = (e) => {
    this.setState({ params: this.clearParams(), showForm: false });
    e.preventDefault();
  };

  updateParam = (key, value, cb = null) => {
    let params = Object.assign({}, this.state.params);
    params[key] = value;
    this.setState({ params: params }, cb);
  };

  sectionForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input type="hidden" value={this.state.params.id} />
        <label htmlFor="title">Title</label>
        <br />
        <input
          type="text"
          name="title"
          value={this.state.params.title}
          onChange={(e) => this.updateParam('title', e.target.value)}
        />
        <br />
        <label htmlFor="order">Order</label>
        <br />
        <input
          type="number"
          name="order"
          value={this.state.params.order}
          onChange={(e) => {
            this.updateParam('order', e.target.value);
          }}
        />
        <br />
        <button onClick={this.save}>Save</button>
        <button onClick={this.cancel}>Cancel</button>
      </form>
    );
  };

  getSectionOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a section ---
      </option>
    );
    {
      this.props.sections.forEach((section) => {
        options.push(
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        );
      });
    }
    options.push(
      <option key="new" value="new">
        + Add New Section
      </option>
    );
    return options;
  };

  render() {
    if (this.props.pdfDocument == null) {
      return null;
    }
    return (
      <div>
        {this.state.errors.forEach((e) => (
          <div>{e}</div>
        ))}
        <select onChange={this.changeSection} value={this.state.params.id}>
          {this.getSectionOptions()}
        </select>
        {this.state.showForm ? this.sectionForm() : ''}
      </div>
    );
  }
}
