import { create } from "zustand";
import { persist } from 'zustand/middleware';

const useComplianceReviewStore = create(
  persist(
    (set) => ({
      documents: [],
      setDocuments: (documents) => set({ documents }),
      updateDocument: (document) => set((state) => ({ documents: state.documents.map((d) => d.id === document.id ? document : d) })),

      selectedTransactions: [],
      setSelectedTransactions: (selectedTransactions) => set({ selectedTransactions }),

      currentDocument: null,
      setCurrentDocument: (currentDocument) => set({ currentDocument }),

      comments: [],
      setComments: (comments) => set({ comments }),
      addComment: (comment) => set((state) => ({ comments: [...state.comments, comment] })),
      updateComment: (comment) => set((state) => ({ comments: state.comments.map((c) => c.id === comment.id ? comment : c) })),
    }),
    {
      name: 'compliance-review-storage', // unique name
    }
  )
);

export default useComplianceReviewStore;