import React from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function CheckboxField(props) {
  return (
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="formTextField">
        <h3>Checkbox</h3>
        <br />
        <br />
        <Button
          size="sm"
          style={{ marginTop: 200 }}
          variant="danger"
          onClick={props.onDelete}
        >
          Delete
        </Button>
      </Form.Group>
    </Form>
  );
}
