import React from 'react';

export default class Initial extends React.Component {
  constructor(props) {
    super(props);

    // {
    //   id: "",
    //   coords [],
    //   date: [],
    //   page: ""
    // };
  }

  render() {
    let date = this.props.initial.date;
    let pdf_date_coords = this.props.initial.pdf_date_coords;

    let dateCoords;

    if (date != null) {
      dateCoords = (
        <div>
          <label>date coords</label>
          <br />
          <input type="text" value={date} readOnly={true} />
          <br />
          <label>date pdf coords</label>
          <br />
          <input type="text" value={pdf_date_coords} readOnly={true} />
        </div>
      );
    } else {
      dateCoords = (
        <a
          href="#"
          onClick={(e) => {
            let initial = Object.assign({}, this.props.initial);
            initial['date'] = [0, 0, 50, 20];
            initial['pdf_date_coords'] = [0, 0, 50, 20];
            this.props.onChange(initial, this.props.index);
            e.preventDefault();
          }}
        >
          {' '}
          + date coords{' '}
        </a>
      );
    }

    return (
      <div>
        <label>initial coords</label>
        <br />
        <input type="text" value={this.props.initial.coords} readOnly={true} />
        <br />
        <label>initial pdf coords</label>
        <br />
        <input
          type="text"
          value={this.props.initial.pdf_coords}
          readOnly={true}
        />
        <br />
        {dateCoords}
      </div>
    );
  }
}
