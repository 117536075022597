import React from 'react';
import CompositeElement from './CompositeElement';
import CompositeFieldElement from './CompositeFieldElement';
import { connect } from 'react-redux';

// class InjectionElement extends React.Component {
//   constructor(props) {
//     super(props);
//   }
//
//   injectionElements = () => {
//     let htmlArray = []
//     this.props.elements.forEach((element, index) => {
//       htmlArray.push()
//     })
//     return htmlArray
//   }
//
//   render() {
//     return ()
//   }
// }

class CompositeField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedElementOptions: []
    };

    this.injectSection = this.injectSection.bind(this);
  }

  onChange = (data) => {
    let field = JSON.parse(JSON.stringify(this.props.field));

    let dataField = data.field;
    delete data.field;

    Object.assign(field.field, dataField);
    Object.assign(field, data);

    // Keep key and name in sync for subField
    if (field.field.field != null) {
      field.field.field.key = field.name;
      field.field.field.name = field.name;
    }
    field.field.key = field.name;

    this.props.onChange(field);
  };

  getSectionOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a Section ---
      </option>
    );
    {
      this.props.sections.forEach((section) => {
        options.push(
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        );
      });
    }
    return options;
  };

  addElement = () => {
    let elements = Object.assign([], this.props.field.field.elements);

    let replace = '$key' + elements.length;
    elements.push({ replace: replace, pattern: '(.*)' });

    this.onChange({ field: { elements: elements } });
  };

  updateElement = (index, data) => {
    let elements = Object.assign([], this.props.field.field.elements);

    elements[index] = data;
    this.onChange({ field: { elements: elements } });
  };

  getElements = () => {
    let elements = [];

    let elementFields = this.props.field.field.elements;
    elementFields.forEach((element, index) => {
      elements.push(
        <CompositeElement
          key={index}
          index={index}
          element={element}
          onChange={this.updateElement}
        />
      );
    });

    return elements;
  };

  injectSection = () => {
    let injectionSection =
      `<span id="${this.props.pdfField.external_id}" class="field_container">
<span class="field" style="border-bottom: 1px solid #000;" th:if="\${fields.containsKey('${this.props.pdfField.external_id}')}">` +
      Array.from(this.state.selectedElementOptions)
        .map((element, index) => {
          return `
  <span key=\"${index}\" th:if=\"\${fields['${this.props.pdfField.external_id}'].containsKey('${element.value}')}\" >
    <span th:text=\"\${fields['${this.props.pdfField.external_id}']['${element.value}']['value']}\"></span>
  </span>`;
        })
        .join('') +
      `
</span>
<span class="field" style="border-bottom: 1px solid #000; min-width: ${this.props.fieldWidth}; display: inline-block;" th:unless="\${fields.containsKey('${this.props.pdfField.external_id}')}"></span></span>
`;
    this.props.inject(injectionSection);
  };

  getElementInjectOptions = () => {
    let elements = [];

    let elementFields = this.props.field.field.elements;
    elementFields.forEach((element, index) => {
      elements.push(
        <option key={index} index={index} value={element.replace}>
          {element.replace}
        </option>
      );
    });

    elements.push(
      <option
        key="text_fallback"
        index={elements.length}
        value={'text_fallback'}
      >
        text_fallback
      </option>
    );

    return elements;
  };

  render() {
    let field = this.props.field;
    return (
      <div>
        <input type="hidden" value={field.external_id} />
        <select
          multiple={true}
          onChange={(e) => {
            this.setState({ selectedElementOptions: e.target.selectedOptions });
          }}
        >
          {this.getElementInjectOptions()}
        </select>
        <button onClick={this.injectSection}>Inject Field</button>
        <br />
        <label>Name</label>
        <br />
        <input
          type="text"
          name="name"
          value={field.name}
          onChange={(e) =>
            this.onChange({
              name: e.target.value,
              field: { key: e.target.value }
            })
          }
        />
        <br />

        <label>Field Type</label>
        <br />
        <input
          type="text"
          name="field_type"
          value={field.field_type}
          readOnly={true}
        />
        <br />
        <label>Template</label>
        <br />
        <input
          type="text"
          name="template"
          value={field.field.template}
          onChange={(e) =>
            this.onChange({ field: { template: e.target.value } })
          }
        />
        <br />
        <CompositeFieldElement
          pdfDocument={this.props.pdfDocument}
          field={this.props.field}
          onChange={this.onChange}
        />

        <b>Elements</b>
        <br />
        {this.getElements()}
        <a
          href="#"
          onClick={(e) => {
            this.addElement();
            e.preventDefault();
          }}
        >
          + Add Element
        </a>
        <br />

        <label>pdf section</label>
        <br />
        <select
          value={field['pdf_section_id'] || ''}
          onChange={(e) => this.onChange({ pdf_section_id: e.target.value })}
        >
          {this.getSectionOptions()}
        </select>
        <br />
        <label>Order</label>
        <br />
        <input
          type="number"
          value={field.order || ''}
          onChange={(e) => this.onChange({ order: e.target.value })}
        />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pdfField: state.pdfTemplates.pdfField
  };
};

export default connect(mapStateToProps)(CompositeField);
