import React from 'react';
import { connect } from 'react-redux';

class HtmlDocumentTree extends React.Component {
  onClick = (id, e) => {
    this.props.onClick(id);
    e.preventDefault();
  };

  render() {
    if (this.props.pdfFields == null) return null;

    let filteredFields = this.props.pdfFields.filter((field) =>
      [
        'text',
        'text_area',
        'datetime',
        'radio',
        'checkbox',
        'composite',
        'signature',
        'date_signed',
        'initials'
      ].includes(field['field_type'])
    );
    return (
      <div>
        <ul>
          {this.props.pdfTemplate.sections.map((s) => {
            // let fields = s.data
            let fields = filteredFields.filter(
              (field) => field['pdf_section_id'] === s.external_id
            );

            return (
              <li key={s.external_id}>
                {s.title}{' '}
                <ul>
                  {fields.map((i) => (
                    <li key={i.id}>
                      <a href="#" onClick={(e) => this.onClick(i.id, e)}>
                        {i.name || i.field.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
          <li>
            No Section{' '}
            <ul key="no_section">
              {filteredFields
                .filter((field) => field['pdf_section_id'] == null)
                .map((i) => (
                  <li key={i.external_id}>
                    <a href="#" onClick={(e) => this.onClick(i.external_id, e)}>
                      {i.name || i.field.title}
                    </a>
                  </li>
                ))}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pdfFields: state.pdfTemplates.pdfFields,
    pdfField: state.pdfTemplates.pdfField,
    pdfTemplate: state.pdfTemplates.pdfTemplate
  };
};

export default connect(mapStateToProps, null)(HtmlDocumentTree);
