import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import useEnsureAuth from '../../shared/hooks/ensureAuth';

export default function SignaturesForm({ onSubmit }) {
  const [signatureFile, setSignatureFile] = useState(null)
  const [initialFile, setInitialFile] = useState(null)

  const { user } = useEnsureAuth();

  const signatureRef = useRef(null)
  const initialRef = useRef(null)

  const onSignatureEnd = () => {
    setSignatureFile(convertDataUriToFile(signatureRef.current.toDataURL()))
  }

  const onInitialEnd = () => {
    setInitialFile(convertDataUriToFile(initialRef.current.toDataURL()))
  }

  const convertDataUriToFile = (dataUri) => {
    let byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataUri.split(',')[1]);
    else byteString = unescape(dataUri.split(',')[1]);

    // separate out the mime component
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    // eslint-disable-next-line
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Stack>
          <Typography>Your signature and initials will be added to the disclosure document</Typography>
        </Stack>
        <Stack>
          <Typography variant='h4'>Please sign below</Typography>
        </Stack>
        <Stack
          px={4} pb={4}
          bgcolor="white"
          direction="row"
          sx={{
            '.sigCanvas': {
              display: 'block',
              width: '100%',
              height: '180px',
              borderBottom: '2px solid darkgrey',
            }
          }}
        >
          <Typography sx={{ mt: 'auto !important' }} fontWeight="bold" color="darkgrey" fontSize="24px">x</Typography>
          <SignatureCanvas
            penColor="black"
            canvasProps={{ className: 'sigCanvas' }}
            onEnd={onSignatureEnd}
            ref={signatureRef}
          />
        </Stack>
        <Stack direction="row">
          <Button variant="outlined" onClick={() => signatureRef.current.clear()}>
            Clear Signature
          </Button>
        </Stack>
      </Stack>
      <Stack my={1}>
        <Divider />
      </Stack>
      <Stack spacing={1}>
        <Stack>
          <Typography variant='h4'>Please initial below</Typography>
        </Stack>
        <Stack
          px={4} pb={4}
          bgcolor="white"
          direction="row"
          sx={{
            '.sigCanvas': {
              display: 'block',
              width: '100%',
              height: '180px',
              borderBottom: '2px solid darkgrey',
            }
          }}
        >
          <Typography sx={{ mt: 'auto !important' }} fontWeight="bold" color="darkgrey" fontSize="24px">x</Typography>
          <SignatureCanvas
            penColor="black"
            canvasProps={{ className: 'sigCanvas' }}
            onEnd={onInitialEnd}
            ref={initialRef}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Button variant="outlined" onClick={() => initialRef.current.clear()}>
              Clear Initials
            </Button>
          </Box>
          <Box width="50%" textAlign="center">
            <Typography>
              I, {user.full_name}, by signing agree to be legally bound to this agreement and the Offer App Terms of Service.
            </Typography>
          </Box>
          <Box>
            <Button sx={{ px: "20px" }} onClick={() => onSubmit({ signatureFile, initialFile })} variant="contained" color="primary">
              Adopt Signature
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}