import { Controller } from 'stimulus';
import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from 'flatpickr';
import { put } from '@rails/request.js';
import moment from 'moment';

/**
 * data: {controller: 'datepicker', datepicker: options}
 *
 * options:
 *     alt_format
 *     date_format
 *     default_date
 *     url
 *     body
 *     response_kind
 */
export default class extends Controller {
  connect() {
    let textValue;

    let element = this.element;
    let dataset = element.dataset;
    let defaultDate = dataset.datepickerDefaultDate || null;
    let dateFormat = dataset.dateFormat;
    let altDateFormat = dataset.altFormat;

    // start with basic flatpickr configuration
    let flatpickrConfig = {
      altInput: true,

      dateFormat: dateFormat,
      altFormat: altDateFormat,
      allowInput: true,
      allowInvalidPreload: false,
      onClose: function (selectedDates, dateStr, instance) {
        if (selectedDates.length === 1 && selectedDates[0] === undefined) {
          textInput.value = textValue;
          element.value = textValue;
        }
      },
      onValueUpdate: function (selectedDates, dateStr, instance) {
        if (selectedDates.length === 1 && selectedDates[0] === undefined) {
          textInput.value = textValue;
          element.value = textValue;
        }
      },
      formatDate: (date, format, locale) => {
        if(!format) format = 'F j, Y';
        const _date = moment(date).format(format);
        if (_date !== 'Invalid date') {
          const str = flatpickr.formatDate(new Date(date), format, locale);
          return str;
        }
      },
      parseDate: (dateStr, format) => {
        const _date = moment(dateStr).format(format);

        if (_date == 'Invalid date') {
          setTimeout(() => {
            const textInput = element.parentElement.querySelector(
              "input[type='text'].datepicker"
            );
            const hiddenInput = element.parentElement.querySelector(
              "input[type='hidden'].datepicker"
            );

            textInput.value = dateStr;
            hiddenInput.value = dateStr;
          }, 0); // next tick
          return;
        }

        return flatpickr.parseDate(dateStr, format);
      },
      defaultDate: defaultDate,
      onChange: function (selectedDates, dateStr, instance) {
        if (dataset.datepickerUrl != null) {
          let body = dataset.datepickerBody;
          body = body.replace('%dateStr', dateStr);

          put(dataset.datepickerUrl, {
            responseKind: dataset.datepickerResponseKind || 'turbo-stream',
            body: JSON.parse(body)
          });
        }
      }
    };

    // only add altFormat and dateFormat properties if they exist
    if (dataset.datepickerAltFormat) {
      flatpickrConfig.altFormat = dataset.datepickerAltFormat;
    }
    if (dataset.datepickerDateFormat) {
      flatpickrConfig.dateFormat = dataset.datepickerDateFormat;
    }

    flatpickr(element, flatpickrConfig);

    const textInput =
      this.element.parentNode.querySelector("input[type='text']");
    textInput.addEventListener('keyup', (event) => {
      textValue = textInput.value;
    });

    const datepickers = document.querySelectorAll('.datepicker');
    datepickers.forEach((datePicker) => {
      const textInput = datePicker.parentNode.querySelector(
        "input[type='text'].datepicker"
      );
      if (datePicker.value && !textInput.value) {
        textInput.value = datePicker.value;
      }
    });
  }
}
