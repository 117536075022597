import React from 'react'
import { RouterProvider } from 'react-router-dom';
import router from './router';
import theme from '../shared/theme';
import { CircularProgress, ThemeProvider } from '@mui/material';
import useEnsureAuth from '../shared/hooks/ensureAuth';
import GlobalAlert from '../shared/components/GlobalAlert';

export default function App() {
  const {authLoaded } = useEnsureAuth();

  if(!authLoaded) {
    return (
      <CircularProgress />
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <GlobalAlert />
    </ThemeProvider>
  )
}