import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function PageTitle({ title, subtitle }) {
  return (
    <Stack>
      <Typography variant="h5">
        {title}
      </Typography>
      {subtitle && <Typography variant="body1">
        {subtitle}
      </Typography>}
    </Stack>
  )
}