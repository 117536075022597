import { createReducer } from '@reduxjs/toolkit';
import { MLS_FIELDS_FETCHED } from '../actions/mlsFieldActions';

const initialState = {
  mlsFields: []
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(MLS_FIELDS_FETCHED, (state, action) => {
    state.mlsFields = action.payload;
  });
});

export default reducer;
