import { Controller } from 'stimulus';
import { Dropdown } from 'bootstrap';

export default class extends Controller {
  static targets = ['dropdown', 'option', 'input'];

  connect() {
    this.dropdown = new Dropdown(this.dropdownTarget);
    this._selectOption = this.selectOption.bind(this);
    this.attachEventListeners();
  }

  disconnect() {
    this.detachEventListeners();
  }

  attachEventListeners() {
    this.optionTargets.forEach((option) => {
      option.addEventListener('click', this._selectOption);
    });
  }

  detachEventListeners() {
    this.optionTargets.forEach((option) => {
      option.removeEventListener('click', this._selectOption);
    });
  }

  hideDropdown() {
    this.dropdown.hide();
  }

  selectOption(event) {
    const option = event.currentTarget;
    this.inputTarget.value = option.dataset.collectionselectValue;

    this.hideDropdown();
  }
}
