import { format } from "date-fns";

const formatDateTime = (date) => {
  return format(new Date(date), "LLLL d, yyyy h:mm a");
}

const formatDate = (date) => {
  return format(new Date(date), "LLLL d, yyyy");
}

export { formatDateTime, formatDate };