export const CONTACT_COLORS = [
  'rgb(70, 178, 178)',
  'rgb(255, 180, 192)',
  'rgb(192, 255, 192)',
  'rgb(224, 192, 255)',
  'rgb(178, 178, 70)',
  'rgb(70, 178, 117)'
];

export const getInitials = (name) => {
  // Split the name into words
  const words = name.split(/\s+/);

  // Extract the first character of each word and concatenate them
  const initials = words.map((word) => word.charAt(0)).join('');

  return initials.toUpperCase(); // Convert to uppercase if needed
};
