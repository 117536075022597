import React from 'react';

export default class CompositeElement extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = (key, data) => {
    let element = Object.assign({}, this.props.element);
    element[key] = data;

    this.props.onChange(this.props.index, element);
  };

  render() {
    return (
      <div>
        <label>replace</label>
        <br />
        <input
          type="text"
          value={this.props.element.replace || ''}
          onChange={(e) => this.onChange('replace', e.target.value)}
        />
        <br />
        <label>pattern</label>
        <br />
        <input
          type="text"
          value={this.props.element.pattern || ''}
          onChange={(e) => this.onChange('pattern', e.target.value)}
        />
        <br />
        <label>Text Fallback Element?</label>
        <br />
        <input
          type="checkbox"
          checked={this.props.element.text_fallback || false}
          onChange={(e) => this.onChange('text_fallback', e.target.checked)}
        />
        <br />
      </div>
    );
  }
}
