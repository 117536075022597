import { Badge, Box, Button, Checkbox, Chip, CircularProgress, Divider, FormGroup, FormLabel, IconButton, Stack, TextField, ThemeProvider, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SearchIcon from '@mui/icons-material/Search';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useEnsureAuth from '../shared/hooks/ensureAuth';
import { Modal } from 'bootstrap';
import theme from '../shared/theme';
import Request from '../shared/services/Request';
import { getApiUrl } from '../shared/Network';
import useYupValidationResolver from '../shared/services/useYupValidationResolver';
import useAlertStore from '../shared/stores/AlertStore';
import OfferPopover from '../shared/components/OfferPopover';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const validationSchema = yup.object({
  email: yup.string().required("Required"),
  subject: yup.string().required("Required"),
  body: yup.string().required("Required"),
  combine_pdfs: yup.boolean(),
});

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 1,

  // change background colour if dragging
  background: isDragging ? 'white' : 'white',
  transform: isDragging ? 'rotate(10deg)' : 'rotate(10deg)',
  boxShadow: isDragging ? '8px 8px 10px darkgrey' : '',
  border: '1px solid lightgrey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  borderRadius: '10px',
  padding: grid,
  width: '100%',
});

export default function App({ folderable, documents: documentsProps, defaultSelected, modalId }) {
  const [documents, setDocuments] = useState(documentsProps);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState(defaultSelected || []);
  const [sharing, setSharing] = useState(false);

  const { setAlertMessage } = useAlertStore();

  const resolver = useYupValidationResolver(validationSchema);

  const { register, handleSubmit, reset, watch, formState: { isValid, errors } } = useForm({
    resolver: resolver,
    defaultValues: {
      attachmentName: folderable.name,
    },
    reValidateMode: 'onChange'
  });
  
  const authLoaded = useEnsureAuth();

  if(!authLoaded) {
    return (
      <CircularProgress />
    )
  }

  useEffect(() => {
    return () => {
      setSelectedDocumentIds([]);
      setDocuments([]);
    }
  }, [])

  const handleModalClose = () => {
    if(!modalId) return;
    setSelectedDocumentIds([]);
    reset();
    const modal = Modal.getInstance(document.getElementById(modalId));
    modal.hide();
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      documents,
      result.source.index,
      result.destination.index,
    );

    setDocuments(reorderedItems);
  };

  const handleCheckboxChange = (documentId) => {
    console.log(documentId)
    if(selectedDocumentIds.includes(documentId)) {
      setSelectedDocumentIds(selectedDocumentIds.filter(id => id !== documentId));
    } else {
      setSelectedDocumentIds([...selectedDocumentIds, documentId]);
    }
  }

  const onSubmit = (values) => {
    if(selectedDocumentIds.length === 0) {
      window.alert("Please select at least one document to share");
      return;
    }

    setSharing(true);

    const documentIds = documents.filter(document => document.folder_resourcable_type === 'Document' && selectedDocumentIds.includes(document.id)).map(document => document.id);
    console.log(documentIds);

    const pdfDocumentIds = documents.filter(document => document.folder_resourcable_type === 'PdfDocument' && selectedDocumentIds.includes(document.id)).map(document => document.id);
    console.log(pdfDocumentIds);

    const body = {
      documents: documentIds,
      pdf_documents: pdfDocumentIds,
      email_recipients: values.email,
      subject: values.subject,
      body: values.body,
      combine_pdfs: values.combine_pdfs ? "1" : "0",
      attachment_name: values.attachmentName
    }

    console.log(body);

    Request.post(getApiUrl() + `/transactions/${folderable.id}/share_documents`, body, (data) => {
      reset();
      setAlertMessage("Documents shared successfully", "success");
      setSharing(false);
      handleModalClose();
    }, () => {
      setAlertMessage("Error sharing documents", "error");
      setSharing(false);
    })
  }

  console.log(documents)

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Typography variant="h6">1. Select Documents</Typography>
            <Stack>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(providedUpper, snapshotUpper) => (
                    <Box
                      {...providedUpper.droppableProps}
                      ref={providedUpper.innerRef}
                      style={getListStyle(snapshotUpper.isDraggingOver)}
                    >
                      {documents.map((document, index) => (
                        <Draggable key={document.id} draggableId={`${document.id}`} index={index}>
                          {(provided, snapshot) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <Stack direction="row" spacing={2}>
                                <Box className="iconBox" my="auto !important">
                                  <DragIndicatorIcon color="lightgrey" />
                                </Box>
                                <Box my="auto !important">
                                  <Checkbox onChange={() => handleCheckboxChange(document.id)} checked={selectedDocumentIds.includes(document.id)} sx={{ p: 0 }} />
                                </Box>
                                <Typography sx={{ my: 'auto !important' }} variant="body1">{index + 1}</Typography>
                                <Typography sx={{ my: 'auto !important' }} variant="body1">{document.name}</Typography>
                                <OfferPopover
                                  anchor={
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  }
                                >
                                  <Stack p={1}>
                                    <iframe src={document.pdf_url} width="500px" height="450px" />
                                  </Stack>
                                </OfferPopover>

                                {document.archive && <Stack my="auto !important">
                                  <Chip size="small" label="Archived" color="error" />
                                </Stack>}
                              </Stack>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {providedUpper.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Stack>
            <Stack my={2}>
              <Divider />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="h6">2. Compose Email</Typography>
              <FormGroup>
                <TextField size="small" label="Recipient(s) email (comma separated)" {...register('email')} error={errors.email} />
              </FormGroup>
              <FormGroup>
                <TextField size="small" label="Subject" {...register('subject')} error={errors.subject} />
              </FormGroup>
              <FormGroup>
                <TextField size="small" label="Body" {...register('body')} multiline={true} rows={4} error={errors.body} />
              </FormGroup>
              <FormGroup>
                <Stack direction="row" spacing={1}>
                  <Checkbox {...register('combine_pdfs')} />
                  <Typography sx={{ my: 'auto !important' }} variant="body1">Combine PDFs into single attachment</Typography>
                </Stack>
              </FormGroup>
              {watch('combine_pdfs') && (
                <FormGroup>
                  <TextField size="small" label="Attachment name" {...register('attachmentName')} />
                </FormGroup>
              )}
            </Stack>
            <Stack my={2}>
              <Divider />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button variant='outlined' onClick={handleModalClose}>Cancel</Button>
              <Button variant='contained' sx={{ width: '125px' }} type="submit" disabled={!isValid || sharing}>{sharing ? <CircularProgress size={"12px"} /> : "Share"}</Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </ThemeProvider>
  )
}