import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = {
  mlsFields: []
};

export const addMlsFieldsEvent = createAction('ADD_MLS_FIELDS');

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(addMlsFieldsEvent, (state, action) => {
    state.mlsFields = action.payload;
  });
});

export default reducer;
