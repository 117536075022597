import { Avatar, Stack, Typography } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import React from 'react';
import ContactTypeSelect from './ContactTypeSelect';
import EditableText from '../../shared/components/EditableText';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import useContactsStore from '../../shared/stores/ContactsStore';
import { CONTACT_TYPES } from '../../shared/constants';

const CONTACT_FIELDS = [
  {
    label: 'Email',
    key: 'email',
    icon: <EmailIcon sx={{ width: '18px' }} />
  },
  {
    label: 'Phone',
    key: 'phone',
    icon: <PhoneIcon sx={{ width: '18px' }} />
  },
  {
    label: 'Company',
    key: 'company',
    icon: <WorkIcon sx={{ width: '18px' }} />
  }
]

export default function ContactCard({ contact, afterUpdate, maxWidth }) {
  const { updateContact } = useContactsStore();

  const handleUpdate = (data) => {
    Request.patch(getApiUrl() + `/v2/contacts/${contact.id}`, { contact: data }, (response) => {
      updateContact(response);
      afterUpdate(response);
    })
  }

  return (
    <Stack maxWidth={{ sm: maxWidth || '325px' }}>
      <Stack
        bgcolor={CONTACT_TYPES.find((type) => type.value === contact.contact_type)?.color + '.main'}
        borderRadius={'5px'}
        width={{ sm: maxWidth || '325px' }}
        height="36px"
      />
      <Stack px={2} mt={"-18px"}>
        <Avatar sx={{ width: 36, height: 36 }}>
          {contact.full_name[0]}
        </Avatar>
      </Stack>
      <Stack px={2}>
        <Stack mt={1} mb={2}>
          <Stack>
            <Typography variant="h6">
              {contact.full_name}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body1">
              {contact.brief_description || contact.email}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Stack mb={1}>
            <Typography variant="body2">
              Details
            </Typography>
          </Stack>
          <Stack spacing={1}>
            {
              CONTACT_FIELDS.map((field) => (
                <Stack direction="row" justifyContent={"space-between"} spacing={2}>
                  <Stack direction="row" spacing={1}>
                    {field.icon}
                    <Typography variant="body1" fontWeight={510}>
                      {field.label}
                    </Typography>
                  </Stack>
                  <EditableText
                    type="text"
                    initialValue={contact[field.key]}
                    onSave={(value) => handleUpdate({ [field.key]: value })}
                    placeholder="Contact company"
                    fontSize="12px"
                    autoFocus={false}
                    textAlign="right"
                  >
                    <Typography variant="body1">
                      {contact[field.key]}
                    </Typography>
                  </EditableText>
                </Stack>
              ))
            }
            <Stack direction="row" justifyContent={"space-between"}>
              <Stack direction="row" spacing={1}>
                <PersonIcon />
                <Typography variant="body1" fontWeight={510}>
                  Type
                </Typography>
              </Stack>
              <ContactTypeSelect value={contact.contact_type} onChange={(contactType) => handleUpdate({ contact_type: contactType })} />
            </Stack>
            {contact.auto_bio && <Stack>
              <Stack>
                <Typography variant="body2">
                  Bio
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="body1">
                  {contact.auto_bio}
                </Typography>
              </Stack>
            </Stack>}
            {contact.auto_bio_bullets && <Stack
              px={2}
              sx={{
                'ul': {
                  listStyleType: 'disc'
                }
              }}
            >
              <Typography variant="body2">
                <div dangerouslySetInnerHTML={{ __html: contact.auto_bio_bullets }} />
              </Typography>
            </Stack>}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}