import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static values = {
    id: String,
    open: Boolean,
    close: Boolean
  };

  connect() {
    if (this.openValue) {
      this.open();
    }

    if (this.closeValue === true) {
      const modal = Modal.getOrCreateInstance(document.getElementById(this.idValue));
      modal.hide();
    }
  }

  disconnect() {
    super.disconnect();

    const modal = Modal.getInstance(document.getElementById(this.idValue));
  }

  close(event) {
    if (this.idValue === event.detail.id) {
      // bad practice but turbo handling logic differently thats why added here
      const contactsForm = document.getElementById("contacts")?.querySelector('form');
      if (contactsForm) {
        contactsForm.reset();
      }

      const modal = Modal.getInstance(document.getElementById(this.idValue));
      modal.hide();
    }
  }

  open() {
    if (this.idValue) {
      const modalElement = document.getElementById(this.idValue);
      if (modalElement) {
        const modal = Modal.getOrCreateInstance(modalElement);
        modal.show();
      }
    }
  }
}
