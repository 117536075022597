import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducers/root';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/root';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middleware)
});

sagaMiddleware.run(rootSaga);
