import { put, call } from 'redux-saga/effects';
import {
  getHeaders,
  getApiBaseUrl,
  parseErrors
} from '../../../shared/Network';
import {
  contactsFetched,
  contactsFetchFailed
} from '../actions/contactActions';
import { INTERNAL_ERROR } from '../../../shared/constants';
import { callApi } from './shared';

export function* getContacts(action) {
  try {
    let url = getApiBaseUrl() + '/flows/' + action.payload.flowId + '/contacts';

    const headers = yield call(getHeaders);
    const response = yield call(callApi, url, 'GET', headers, null, action);

    if (!response) {
      console.error('[contactSaga] getContacts, null response', url, action);
      return;
    }

    const responseJson = yield response.json();
    let errors = parseErrors(responseJson);

    if (response.status >= 200 && response.status <= 299) {
      if (errors.length === 0) {
        yield put(contactsFetched(responseJson));
      } else {
        yield put(contactsFetchFailed(errors));
      }
    } else {
      yield put(contactsFetchFailed(errors));
    }
  } catch (e) {
    console.log('Error getting PdfDocument: ' + e.message);
    yield put(contactsFetchFailed([INTERNAL_ERROR]));
  }
}
