import React from 'react';
import Viewer from './Viewer';
import PdfDocument from './PdfDocument';
import Login from '../shared/Login';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false
    };
  }

  componentDidMount() {
    this.setState({ loggedIn: window.sessionStorage.getItem('auth') != null });
  }

  render() {
    if (this.state.loggedIn) {
      return <Viewer />;
    } else {
      return <Login setLoggedIn={() => this.setState({ loggedIn: true })} />;
    }
  }
}
