import { Controller } from 'stimulus';

export default class extends Controller {
  showSection(event) {
    event.preventDefault();

    const sectionToShow = event.currentTarget.dataset.section;
    const links = this.element.querySelectorAll('.commission_card_link');

    this.element
      .querySelectorAll('.commission_card_section')
      .forEach((section) => (section.style.display = 'none'));

    this.element.querySelector(`#${sectionToShow}`).style.display = 'block';

    links.forEach((link) => {
      if (link === event.currentTarget) {
        link.classList.add('active');
        link.classList.remove('inactive');
      } else {
        link.classList.remove('active');
        link.classList.add('inactive');
      }
    });
  }
}
