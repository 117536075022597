import React from 'react';

import { Alert, Snackbar } from '@mui/material';
import useAlertStore from '../stores/AlertStore';

export default function GlobalAlert() {
  const { alertMessage, alertType, clearAlerts } = useAlertStore();

  const handleClose = () => {
    clearAlerts();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={alertMessage}
      autoHideDuration={4000}
      onClose={handleClose}
      sx={{
        width: '50%',
      }}
    >
      <Alert onClose={handleClose} severity={alertType || "info"} sx={{ width: '100%' }}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

