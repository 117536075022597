import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';

const splitString = (str, ch1, ch2) => str.substring(
  str.indexOf(ch1) + 1,
  str.lastIndexOf(ch2)
);

export default function CompositeField({ field, value, onChange }) {
  console.log(value)
  const years = (value && value !== "false") ? value.split("-")[0] : 0
  const months = (value && value !== "false") ? value.split("-")[1] : 0

  const handleYearChange = (e) => {
    onChange(`${e.target.value}-${months}`)
  }

  const handleMonthChange = (e) => {
    onChange(`${years}-${e.target.value}`)
  }

  switch(field.template) {
    case '$YEAR_MONTH':
      return (
        <Stack direction="row" spacing={2}>
          <TextField defaultValue={years} value={years} onChange={handleYearChange} label="Years" type="number" />
          <Typography variant="h6" fontWeight="bold" sx={{ my: 'auto !important' }}> and </Typography>
          <TextField defaultValue={months} value={months} onChange={handleMonthChange} label="Months" type="number" />
        </Stack>
      )
    default:
      return (
        <Typography>Unsupported field type</Typography>
      )
  }
}