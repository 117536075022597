import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    let viewer = this.element.querySelector('div');
    let embeddedUrl = this.element.dataset['embeddedurl'];

    console.log('Connected to EmbeddedPdfViewer, loading url: ' + embeddedUrl);

    var signWellEmbed = new SignWellEmbed({
      url: embeddedUrl,
      events: {
        completed: (e) => {
          console.log('completed event: ', e);
          //document successfully sent/completed
        },
        closed: (e) => {
          console.log('closed event: ', e);
          //SignWell iFrame closed
        }
      },
      containerId: 'embedded-pdf-viewer',
      allowClose: false,
      showHeader: false
    });

    signWellEmbed.open();
  }
}
