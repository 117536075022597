import React from 'react';

import { createBrowserRouter } from "react-router-dom";
import Root from './pages/root';
import Contacts from './pages/Contacts';
import ViewContact from './pages/ViewContact';

const router = createBrowserRouter([
  {
    path: "/v2/contacts",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Contacts />
      },
      {
        path: ":id",
        element: <ViewContact />
      }
    ]
  }
]);

export default router;