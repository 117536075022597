import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['planSummary', 'planSelect'];

  connect() {
    this.updateDisplay();
  }

  updateDisplay() {
    this.planSummaryTargets.forEach((summary) => {
      summary.style.display = 'none';
    });

    const selectedValue = this.planSelectTarget.value;
    if (selectedValue) {
      const selectedSummary = this.element.querySelector(
        `#commission-plan-${selectedValue}`
      );
      if (selectedSummary) {
        selectedSummary.style.display = 'block';
      }
    }
  }
}
