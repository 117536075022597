import { Stack, Typography, useTheme } from '@mui/material';
import humanizeString from 'humanize-string';
import React from 'react';

export default function TransactionStatusTag({ transactionStatus, hideLabel }) {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      bgcolor="#FFFFFF"
      p={0.75}
      borderRadius={'3px'}
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.08)"
      spacing={1}
    >
      {!hideLabel && <Typography variant="body1" fontWeight="bold">Status: </Typography>}
      <Typography variant="body1" sx={{ my: 'auto !important' }}>
        {humanizeString(transactionStatus)}
      </Typography>
    </Stack>
  )
}