import React from 'react';
import ToggleFields from './ToggleFields';

export default class ToggleButtonField extends React.Component {
  constructor(props) {
    super(props);
  }

  getSectionOptions = () => {
    let sections = [];
    sections.push(
      <option key="select" value="">
        --- Select a Section ---
      </option>
    );
    {
      this.props.sections.forEach((section) => {
        sections.push(
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        );
      });
    }
    return sections;
  };

  onChange = (data) => {
    let field = JSON.parse(JSON.stringify(this.props.field));

    let dataField = data.field;
    delete data.field;

    Object.assign(field.field, dataField);
    Object.assign(field, data);

    this.props.onChange(field);
  };

  render() {
    let field = this.props.field;

    return (
      <div>
        <input type="hidden" value={field.external_id} />

        <label>Name</label>
        <br />
        <input
          type="text"
          name="name"
          value={field.name}
          onChange={(e) =>
            this.onChange({
              name: e.target.value,
              field: { key: e.target.value }
            })
          }
        />
        <br />

        <label>Field Type</label>
        <br />
        <input
          type="text"
          name="field_type"
          value={field.field_type}
          readOnly={true}
        />
        <br />

        <label>Label</label>
        <br />
        <input
          type="text"
          name="label"
          value={field.field.label || ''}
          onChange={(e) => this.onChange({ field: { label: e.target.value } })}
        />
        <br />

        <ToggleFields
          fields={this.props.fields}
          toggleFields={field.field.toggle_fields || []}
          onChange={(toggleFields) =>
            this.onChange({ field: { toggle_fields: toggleFields } })
          }
        />

        <label>pdf section</label>
        <br />
        <select
          value={field['pdf_section_id'] || ''}
          onChange={(e) => this.onChange({ pdf_section_id: e.target.value })}
        >
          {this.getSectionOptions()}
        </select>
        <br />

        <label>Order</label>
        <br />
        <input
          type="number"
          value={field.order || ''}
          onChange={(e) => this.onChange({ order: e.target.value })}
        />
        <br />
      </div>
    );
  }
}
