import { create } from "zustand";
import { persist } from 'zustand/middleware';

const useContactsStore = create(
  persist(
    (set) => ({
      contacts: [],
      setContacts: (contacts) => set({ contacts }),
      updateContact: (contact) => set((state) => {
        const index = state.contacts.findIndex((c) => c.id === contact.id);
        state.contacts[index] = contact;
        return { contacts: state.contacts };
      }),
      addContact: (contact) => set((state) => {
        return { contacts: [...state.contacts, contact] };
      }),
      removeContact: (contactToRemove) => set((state) => {
        const newContacts = state.contacts.filter((contact) => contact.id !== contactToRemove.id);
        return { contacts: newContacts };
      }),
    }),
    {
      name: 'contacts-storage', // unique name
    }
  )
);

export default useContactsStore;