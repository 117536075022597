import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'pdfDocumentsMedia',
    'fileList',
    'submitButton',
    'hiddenInputs',
    'form'
  ];

  connect() {
    this.pdfDocumentsMediaTarget.addEventListener(
      'change',
      this.handleFiles.bind(this)
    );
    this.submitButtonTarget.addEventListener(
      'click',
      this.submitForm.bind(this)
    );
    this.fileListTarget.addEventListener('click', this.removeFile.bind(this));
  }

  disconnect() {
    this.pdfDocumentsMediaTarget.removeEventListener(
      'change',
      this.handleFiles.bind(this)
    );
    this.submitButtonTarget.removeEventListener(
      'click',
      this.submitForm.bind(this)
    );
  }

  handleFiles() {
    const { hiddenInputsTarget, pdfDocumentsMediaTarget, submitButtonTarget } =
      this;

    console.log(pdfDocumentsMediaTarget.files.length);

    if (pdfDocumentsMediaTarget.files.length > 0) {
      let clone = pdfDocumentsMediaTarget.cloneNode();
      hiddenInputsTarget.appendChild(clone);

      pdfDocumentsMediaTarget.value = '';
      submitButtonTarget.disabled = false;
    }

    this.updateList();
  }

  updateList() {
    const { fileListTarget, submitButtonTarget } = this;

    const clonedInputs = Array.from(
      this.hiddenInputsTarget.querySelectorAll('input[type="file"]')
    );
    let allFiles = [];

    for (const input of clonedInputs) {
      allFiles = allFiles.concat(Array.from(input.files));
    }

    fileListTarget.innerHTML = '';

    allFiles.forEach((file, index) => {
      let p = document.createElement('li');
      p.innerText = file.name;
      p.classList.add('hover-danger', 'hover-cursor-pointer');
      p.setAttribute('data-file-index', index); // Use for identification during removal
      fileListTarget.appendChild(p);
    });

    submitButtonTarget.disabled = allFiles.length === 0;
  }

  removeFile(event) {
    if (event.target.hasAttribute('data-file-index')) {
      const fileIndex = event.target.getAttribute('data-file-index');
      const clonedInputs = Array.from(
        this.hiddenInputsTarget.querySelectorAll('input[type="file"]')
      );
      const inputToRemove = clonedInputs[fileIndex];

      if (inputToRemove) {
        inputToRemove.remove();
      }

      event.target.remove();
      this.updateList();
    }
  }

  submitForm(event) {
    event.preventDefault();
    const clonedInput =
      this.hiddenInputsTarget.querySelector('input[type="file"]');
    const fileCount = clonedInput ? clonedInput.files.length : 0;

    console.log(fileCount);

    if (fileCount === 0) {
      alert('Please select 1 or more documents to upload');
    } else {
      this.formTarget.submit();
    }
  }
}
