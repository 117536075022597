import { createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_SIGNATURES_STATE,
  SIGNATURE_DOWNLOADED,
  SIGNATURES_FETCHED
} from '../actions/signatureActions';

const initialState = {
  entities: [],
  initials: null,
  signature: null,
  ready: false
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CLEAR_SIGNATURES_STATE, (state, action) => initialState)
    .addCase(SIGNATURE_DOWNLOADED, (state, action) => {
      if (action.payload.signatureType === 'signature') {
        state.signature = action.payload.data;
      } else if (action.payload.signatureType === 'initials') {
        state.initials = action.payload.data;
      }

      if (
        state.initials !== undefined &&
        state.initials !== null &&
        state.signature !== undefined &&
        state.signature !== null
      ) {
        state.ready = true;
      }
    });
});

export default reducer;
