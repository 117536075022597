import React from 'react';
import { useSelector, useDispatch, useEffect } from 'react-redux';
import HtmlSectionForm from '../pdf_annotator/HtmlSectionForm';
import HtmlFieldForm from './HtmlFieldForm';
import HtmlDocumentTree from './HtmlDocumentTree';
import {
  updatePdfField,
  deletePdfField,
  pdfTemplateFetchRequested
} from './redux/actions/pdfTemplateActions';
import { newField } from '../shared/pdf_field_utils';

export default function FieldModal(props) {
  const pdfTemplate = useSelector((state) => state.pdfTemplates.pdfTemplate);
  const pdfFields = useSelector((state) => state.pdfTemplates.pdfFields);
  const pdfField = useSelector((state) => state.pdfTemplates.pdfField);

  const dispatch = useDispatch();

  return (
    <div id="left-column">
      <HtmlSectionForm
        pdfTemplate={pdfTemplate}
        update={() => {
          dispatch(pdfTemplateFetchRequested(pdfTemplate.id));
        }}
      />
      <HtmlFieldForm //pdf={this.state.pdf}
        pdfTemplate={pdfTemplate}
        sections={pdfTemplate.sections}
        fieldWidth={props.fieldWidth}
        style={{ display: 'flex' }}
        newField={(type) => {
          let field = newField(pdfTemplate, type);
          if (field) {
            dispatch(updatePdfField(field));
          }
        }}
        close={() => {
          props.close();
        }}
        delete={() => {
          let idx = pdfFields.findIndex((f) => f.id === pdfField.id);

          if (pdfFields[idx].id !== null) {
            dispatch(deletePdfField(pdfTemplate.id, pdfField.id));
            dispatch(pdfTemplateFetchRequested(pdfTemplate.id));

            return;
          }
          if (idx !== -1) {
            console.error(
              'The currently selected pdfField does not have an id attribute'
            );
          }
        }}
        onChange={(field) => {
          dispatch(updatePdfField(field));
        }}
        inject={props.replace}
      />
    </div>
  );
}
