import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';
import { parse } from '../src/formulas';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['formContainer'];
  connect() {
    // Calculate formulas initially
    this.calculateFormulas();

    this.formContainerTargets.forEach((formContainer) => {
      let valueElem = formContainer.querySelectorAll('.value');

      // Calculate formula listeners
      valueElem.forEach((elem) => {
        elem.addEventListener('change', (evt) => {
          this.calculateFormulas();
        });
      });

      // Toggle Fields listener
      formContainer.querySelectorAll("input[type='radio']").forEach((radio) => {
        radio.addEventListener('click', (event) => {
          this.toggleFields();
        });
      });
    });

    let form = this.element;
    form.addEventListener(
      'submit',
      (event) => {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
          this.handleInvalid();
        } else {
          let loadingModal = new Modal(
            document.getElementById('loadingModal'),
            {}
          );

          loadingModal.show();
        }
        form.classList.add('was-validated');
      },
      false
    );
  }

  calculateFormulas() {
    // Get all values
    let idValuesMap = {};
    let idFormulas = {};

    this.formContainerTargets.forEach((formContainer) => {
      // Save formula if formContainer has one
      if (
        formContainer.dataset.formula !== undefined &&
        formContainer.dataset.formula !== null &&
        formContainer.dataset.formula !== ''
      )
        idFormulas[formContainer.dataset.externalId] =
          formContainer.dataset.formula;

      let value;
      formContainer.querySelectorAll('.value').forEach((elem) => {
        if (
          formContainer.dataset.fieldType === 'text' ||
          formContainer.dataset.fieldType === 'textarea' ||
          formContainer.dataset.fieldType === 'datetime'
        ) {
          value = elem.value;
        } else if (formContainer.dataset.fieldType === 'radio' && elem.checked) {
          value = JSON.parse(elem.value)
        } else if (formContainer.dataset.fieldType === 'checkbox' && elem.checked) {
          if (value === null || value === undefined) {
            value = [];
          }
          value.push(JSON.parse(elem.value));
        }
      });

      // Save all values and formContainer to data structure
      idValuesMap[formContainer.dataset.externalId] = {
        value: value,
        element: formContainer,
        fieldType: formContainer.dataset.fieldType
      };
    });

    // Iterate through formula and parse
    Object.keys(idFormulas).forEach((key) => {
      let formulaValue = this.parseFormula(idFormulas[key], idValuesMap);

      if (
        ['text', 'textarea', 'datetime'].includes(
          idValuesMap[key].element.dataset.fieldType
        )
      ) {
        idValuesMap[key].element.querySelector('.value').value = formulaValue;
      } else if (idValuesMap[key].element.dataset.fieldType === 'checkbox') {
        // checkbox value
        // TODO Implement checkbox formulaValue
      } else if (idValuesMap[key].element.dataset.fieldType === 'radio') {
        // if empty string then nothing should be checked...
        if (
          formulaValue === '' ||
          formulaValue === null ||
          formulaValue === undefined
        ) {
          idValuesMap[key].element
            .querySelectorAll('.value')
            .forEach((radio) => {
              radio.checked = false;
            });
        } else if (typeof formulaValue === 'object') {
          idValuesMap[key].element
            .querySelectorAll('.value')
            .forEach((radio) => {
              let radioValue = JSON.parse(radio.value);
              if (radioValue.value == formulaValue['sub_key']) {
                radio.checked = true;
              }
            });
        }
      }
    });
  }

  parseFormula(formula, fields) {
    if (formula == null) {
      return null;
    }

    const result = parse(formula);

    // If parsing fails return null
    if (result == null) {
      return null;
    }

    return this.evalFormula(result, fields);
  }

  // This function evaluates the parsed grammar defined in formula.pegjs
  // To review the grammar you can load this into https://pegjs.org/online
  evalFormula(funcObj, fields) {
    const func = funcObj['func'];
    let fieldName;
    let fieldValue;

    // Hack to get the FlowContacts from the controller element dataset
    if (funcObj === 'SIGNERS') {
      return this.element.dataset.contactNames;
    }

    switch (func) {
      case 'IF':
        const expression = funcObj['exp'];
        let pos = funcObj['pos'];
        let neg = funcObj['neg'];

        if (typeof expression === 'object' && expression != null) {
          // TODO this assumes the field is a radio field, make this more generic for any field type
          let formulaResult = this.evalFormula(expression, fields);
          if (formulaResult) {
            if (typeof pos === 'object') {
              if (pos['key'] != null) {
                // The value was already calculated
                return pos;
              } else {
                // Parse next function
                pos = this.evalFormula(pos, fields);

                if (typeof pos === 'object' && pos['key'] != null) {
                  return pos;
                }
              }
            }

            if (pos === 'NULL' || pos === '') {
              return '';
            } else {
              return { key: pos, sub_key: pos };
            }
          } else {
            if (typeof neg === 'object') {
              // The value was already calculated
              if (neg['key'] != null) {
                return neg;
              } else {
                // Parse next function
                neg = this.evalFormula(neg, fields);

                if (typeof neg === 'object' && neg['key'] != null) {
                  return neg;
                }
              }
            }

            if (neg === 'NULL' || neg === '') {
              return '';
            } else {
              return { key: neg, sub_key: neg };
            }
          }
        } else {
          return null;
        }

      case 'ISBLANK':
        fieldName = funcObj['exp'];

        if (fieldName === 'SIGNER2') {
          // Hack to get the FlowContact count from the controller element dataset
          return this.element.dataset.contactCount <= 1;
        }

        fieldValue = fields[fieldName].value;
        if (fieldValue instanceof Array)
          return fieldValue.length === 0;
        else
          return fieldValue == null || fieldValue === '';

      case 'FIELDVALUE':
        fieldName = funcObj['exp'];
        fieldValue = fields[fieldName].value;

        if (typeof fieldValue === 'object') {
          return fieldValue.value
        }

        return fieldValue;
      case 'FIELDVALUES':
        let fieldValue1 = fields[funcObj.fv1.exp].value;
        let fieldValue2 = fields[funcObj.fv2.exp].value;
        let result = '';

        if (fieldValue1 && fieldValue2) return fieldValue1 + ' ' + fieldValue2;

        if (fieldValue1) return fieldValue1;

        if (fieldValue2) return fieldValue2;

        return '';
      case 'INCLUDE':
        let match = funcObj['match'];

        fieldName = funcObj['field'];
        fieldValue = fields[fieldName].value;

        return fieldValue != null && fieldValue.indexOf(match) > -1;
      default:
        console.log("Don't know how to parse " + result['func']);
        return null;
    }
  }

  toggleFields() {
    let formContainers = {};
    // map formContainers to their externalId
    this.formContainerTargets.forEach((formContainer) => {
      formContainers[formContainer.dataset.externalId] = formContainer;
    });

    const radioButtons = document.querySelectorAll('input[type="radio"]');

    radioButtons.forEach((radio) => {
      let toggleFields = radio.dataset.toggleFields;

      if (toggleFields === null || toggleFields.length === 0) {
        return;
      } else {
        let array = JSON.parse(toggleFields);
        // Hide the selected fields
        array.forEach(function (element) {
          //Find the element and hide or show it
          const field = formContainers[element];
          if (field) {
            field.style.display = radio.checked ? 'block' : 'none';
            const inputElements = field.querySelectorAll('input');

            inputElements.forEach((input) => {
              input.disabled = !radio.checked;
            });
          }
          console.log('Show id: ' + element);
        });
      }
    });
  }

  handleInvalid(event) {
    let flashMessage = document.getElementById('flashMessage');
    flashMessage.classList.remove('hide');
  }
}
