import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["transaction", "currentTransaction"];

  connect() {
    this.element.addEventListener('shown.bs.modal', () => {
      this.fetchTransactions();
    });
  }

  fetchTransactions() {
    const url = `/transactions/folder_associates`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.transactions = data;
        this.populateTransactionSelect();
      })
      .catch(error => {
        console.error('Error fetching transactions:', error);
      });
  }

  populateTransactionSelect() {
    const transactionSelectElements = document.getElementsByName("move_to_transaction_id");

    transactionSelectElements.forEach(transactionSelect => {
      transactionSelect.innerHTML = '';

      const promptOption = document.createElement('option');
      promptOption.text = 'Select a transaction';
      promptOption.disabled = true;
      promptOption.selected = true;
      transactionSelect.appendChild(promptOption);

      this.transactions.forEach(transaction => {
        const optionElement = document.createElement('option');
        optionElement.value = transaction.id;
        optionElement.text = transaction.name;
        transactionSelect.appendChild(optionElement);
      });
    });
  }


  updateFolders() {
    const selectedTransactionId = parseInt(this.transactionTarget.value);
    const selectedTransaction = this.transactions.find(transaction => transaction.id === selectedTransactionId);

    const folderSelect = document.getElementById(`move_to_folder_id_${this.transactionTarget.id.split('_').pop()}`);

    folderSelect.innerHTML = '';

    if (selectedTransaction && selectedTransaction.folders.length > 0) {
      selectedTransaction.folders.forEach(folder => {
        const optionElement = document.createElement('option');
        optionElement.value = folder.id;
        optionElement.text = folder.name;
        folderSelect.appendChild(optionElement);
      });
    } else {
      const noFoldersOption = document.createElement('option');
      noFoldersOption.text = 'No folders available';
      noFoldersOption.disabled = true;
      folderSelect.appendChild(noFoldersOption);
    }
  }
}