import React, { useEffect, useState } from 'react';
import Request from '../../shared/services/Request';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Chip,
  Dialog,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { getApiUrl } from '../../shared/Network';
import { useNavigate } from 'react-router-dom';
import RequestDocument from './RequestDocument';

const SignatureStatus = ({ status }) => {
  if (status === 'created') {
    return <Chip size="small" label="Sent" color="grey" />;
  } else if (status === 'started') {
    return <Chip size="small" label="Viewed" color="info" />;
  } else {
    return <Chip size="small" label="Signed" color="primary" />;
  }
};

export default function DocumentsList({ contact }) {
  const [folders, setFolders] = useState();
  const [preview, setPreview] = useState();
  const navigate = useNavigate();
  const [showRequestModal, setShowRequestModal] = useState(false);

  useEffect(() => {
    if (contact) {
      Request.get(getApiUrl() + '/v2/contacts/' + contact.id + '/folders', (data) => {
        setFolders(data);
      });
    }
  }, [contact?.id]);

  if (!folders) {
    return <Skeleton variant="rectangular" height={200} />;
  }

  const toggleRequestDocumentModal = () => {
    setShowRequestModal(!showRequestModal);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Stack mb={1}>
          <Button onClick={() => window.location = `/flows/initiate/contact/${contact.id}`} variant="contained"
                  color="primary">+ Add Document</Button>
        </Stack>
        <Stack mb={1}>
          <Button
            onClick={toggleRequestDocumentModal}
            variant="contained"
            color="primary"
          >
            Request Document
          </Button>
        </Stack>
      </Stack>
      <RequestDocument toggleModal={toggleRequestDocumentModal} showRequestModal={showRequestModal} contact={contact} />
      {folders.map((folder) => folder.folder_resources.length > 0 && (
        <Accordion key={folder.id} defaultExpanded>
          <AccordionSummary>
            {folder.name} ({folder.folder_resources.length})
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: 'background.default'
            }}
          >
            <Stack spacing={1} direction="row">
              {folder.folder_resources.map((folder_resource) => (
                <Card
                  key={folder_resource.id}
                  onClick={() => setPreview(folder_resource)}
                  sx={{
                    width: '20%',
                    cursor: 'pointer'
                  }}
                >
                  <Stack p={2}>
                    <Typography variant="body1" fontWeight="bold">{folder_resource.folder_resourcable.name}</Typography>
                    <Stack direction="row" mb={1}>
                      <SignatureStatus status={folder_resource.folder_resourcable.signature_status} />
                    </Stack>
                    <Stack>
                      <iframe src={folder_resource.folder_resourcable.url} />
                    </Stack>
                  </Stack>
                </Card>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
      <Dialog
        open={preview}
        onClose={() => setPreview(null)}
        maxWidth="lg"
      >
        <Stack
          p={2}
          height="85vh"
          width="700px"
        >
          <iframe height="100%" src={preview?.folder_resourcable?.url} />
        </Stack>
      </Dialog>
    </Stack>
  );
}