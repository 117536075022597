import React from 'react';
import TextField from './TextField';
import DateTimeField from './DateTimeField';
import SignatureField from './SignatureField';
import {
  createPdfFieldForPdfDocument,
  updatePdfFieldForPdfDocument
} from '../shared/Network';
import DocumentTree from './DocumentTree';
import RadioField from './RadioField';
import CompositeField from './CompositeField';
import AddDocumentButtonField from './AddDocumentButtonField';
import ToggleButtonField from './ToggleButtonField';
import CheckboxField from './CheckboxField';

export default class FieldForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      params: {},
      showJsonPreview: false
    };
  }

  clearFieldState = (cb = null) => {
    this.setState({ params: {} }, cb);
  };

  componentDidUpdate(prevProps) {
    if (this.props.pdf !== prevProps.pdf) {
      this.clearFieldState();
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
  };

  save = () => {
    let field = this.getFieldParams();

    if (field.external_id == null) {
      createPdfFieldForPdfDocument(
        this.props.pdfDocument.external_id,
        field
      ).then((response) => {
        response.json().then(this.handleResponse);
      });
    } else {
      updatePdfFieldForPdfDocument(
        this.props.pdfDocument.external_id,
        field.external_id,
        field
      ).then((response) => {
        response.json().then(this.handleResponse);
      });
    }
  };

  previewJson = () => {
    this.setState({ showJsonPreview: true });
  };

  handleResponse = (json) => {
    if (json.errors == null) {
      this.props.loadPdfFields(json.id);
    } else {
      console.log('Errors: ' + JSON.stringify(json.errors));
    }
  };

  getFieldParams = () => {
    let field = JSON.parse(JSON.stringify(this.props.field));

    // remove the id from new fields
    if (typeof field.id === 'string' && field.external_id.startsWith('u_')) {
      delete field.external_id;
    }

    if (typeof field.id === 'string' && field.id.startsWith('u_')) {
      delete field.id;
    }

    if (field.field != null && field.field.value == '') {
      delete field.field.value;
    }

    return field;
  };

  close = () => {
    this.clearFieldState(this.props.close);
  };

  setField = (e) => {
    let value = e.target.value;
    if (value !== '') {
      this.props.setField(value);
    }
  };

  getFieldOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a field ---
      </option>
    );
    {
      this.props.fields.forEach((field) => {
        options.push(
          <option key={field.external_id} value={field.external_id}>
            {field.name}
          </option>
        );
      });
    }
    return options;
  };

  getFieldActions = () => {
    return (
      <div>
        <button onClick={this.save}>Save</button>
        <button onClick={this.close}>Cancel</button>
        <button onClick={this.props.delete}>Delete</button>
        <button onClick={this.previewJson}>Preview JSON</button>
      </div>
    );
  };

  getTextElement = () => {
    return (
      <TextField
        field={this.props.field}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getSignatureElement = () => {
    return (
      <SignatureField
        field={this.props.field}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getDateTimeElement = () => {
    return (
      <DateTimeField
        field={this.props.field}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getCheckboxElement = () => {
    return (
      <CheckboxField
        field={this.props.field}
        fields={this.props.fields}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getRadioElement = () => {
    return (
      <RadioField
        field={this.props.field}
        fields={this.props.fields}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getAddDocumentButtonElement = () => {
    return (
      <AddDocumentButtonField
        field={this.props.field}
        sections={this.props.sections}
        fields={this.props.fields}
        onChange={this.props.onChange}
      />
    );
  };

  getToggleButtonElement = () => {
    return (
      <ToggleButtonField
        field={this.props.field}
        sections={this.props.sections}
        fields={this.props.fields}
        onChange={this.props.onChange}
      />
    );
  };

  getCompositeElement = () => {
    return (
      <CompositeField
        pdfDocument={this.props.pdfDocument}
        field={this.props.field}
        name={this.state.name}
        sections={this.props.sections}
        onChange={this.props.onChange}
      />
    );
  };

  getFieldElement = () => {
    if (this.props.field == null) {
      return null;
    }
    switch (this.props.field.field_type) {
      case 'text':
      case 'text_area':
        return this.getTextElement();
      case 'datetime':
        return this.getDateTimeElement();
      case 'radio':
        return this.getRadioElement();
      case 'checkbox':
        return this.getCheckboxElement();
      case 'composite':
        return this.getCompositeElement();
      case 'add_document_button':
        return this.getAddDocumentButtonElement();
      case 'toggle_button':
        return this.getToggleButtonElement();
      case 'signature':
        return this.getSignatureElement();
      default:
        return null;
    }
  };

  render() {
    const previewJson = (
      <div style={jsonPreview}>
        <pre>{JSON.stringify(this.getFieldParams(), undefined, 4)}</pre>
        <button onClick={() => this.setState({ showJsonPreview: false })}>
          Close
        </button>
      </div>
    );

    return (
      <div>
        <div>
          <button
            onClick={() => {
              this.props.newField('text');
            }}
          >
            Text Field
          </button>
          <button
            onClick={() => {
              this.props.newField('text_area');
            }}
          >
            Text Area Field
          </button>
          <button
            onClick={() => {
              this.props.newField('datetime');
            }}
          >
            DateTime Field
          </button>
          <button
            onClick={() => {
              this.props.newField('radio');
            }}
          >
            Radio Field
          </button>
          <button
            onClick={() => {
              this.props.newField('checkbox');
            }}
          >
            Checkbox Field
          </button>
          <button
            onClick={() => {
              this.props.newField('composite');
            }}
          >
            Composite Field
          </button>
          <button
            onClick={() => {
              this.props.newField('add_document_button');
            }}
          >
            Add Document Button Field
          </button>
          <button
            onClick={() => {
              this.props.newField('toggle_button');
            }}
          >
            Toggle Button Field
          </button>
          <button
            onClick={() => {
              this.props.newField('signature');
            }}
          >
            Signature Field
          </button>
        </div>
        <form onSubmit={this.onSubmit}>
          <select
            onChange={this.setField}
            value={this.props.field == null ? '' : this.props.field.external_id}
          >
            {this.getFieldOptions()}
          </select>
          <br />
          <br />
          <div>
            Id
            <br />
            {this.props.field != null ? this.props.field.external_id : ''}
          </div>
          {this.getFieldElement()}
          {this.state.showJsonPreview ? previewJson : null}
          {this.props.field != null ? this.getFieldActions() : null}
        </form>
        <h3>Fields</h3>
        <DocumentTree
          sections={this.props.sections}
          fields={this.props.fields}
          onClick={(id) => this.props.setField(id)}
        />
      </div>
    );
  }
}

const jsonPreview = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000'
};
