import React, { useEffect, useRef } from 'react';
import useCanvas from './hooks/useCanvas';

export default function Canvas(props) {
  const { draw, width, height, canvasRef, ...rest } = props;

  const ref =
    canvasRef === undefined || canvasRef === null ? useCanvas(draw) : canvasRef;

  return (
    <div style={{ width: width, height: height, margin: '0 auto' }}>
      <canvas ref={ref} width={width} height={height} {...rest} />
    </div>
  );
}
