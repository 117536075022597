import React from 'react';
import { getPdfDocuments } from '../shared/Network';

export default class AddDocumentButtonField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfDocuments: []
    };
  }

  componentDidMount() {
    this.loadPdfDocuments();
  }

  loadPdfDocuments = (cb = null) => {
    getPdfDocuments().then((response) => {
      response.json().then((json) => {
        if (json['errors'] != null) {
          this.setState({ errors: json['errors'] });
        } else {
          this.setState({ pdfDocuments: json }, cb);
        }
      });
    });
  };

  onChange = (data) => {
    let field = JSON.parse(JSON.stringify(this.props.field));

    let dataField = data.field;
    delete data.field;

    Object.assign(field.field, dataField);
    Object.assign(field, data);

    this.props.onChange(field);
  };

  getSectionOptions = () => {
    let sections = [];
    sections.push(
      <option key="select" value="">
        --- Select a Section ---
      </option>
    );
    {
      this.props.sections.forEach((section) => {
        sections.push(
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        );
      });
    }
    return sections;
  };

  getDocumentOptions = () => {
    let options = [];
    options.push(
      <option key="select" value="">
        --- Select a document ---
      </option>
    );
    options.push(
      <option key="all" value="any">
        Any Document
      </option>
    );
    this.state.pdfDocuments.forEach((doc) => {
      options.push(
        <option key={doc.external_id} value={doc.external_id}>
          {doc.name}
        </option>
      );
    });
    return options;
  };

  render() {
    let field = this.props.field;

    return (
      <div>
        <input type="hidden" value={field.external_id} />
        <label>Name</label>
        <br />
        <input
          type="text"
          name="name"
          value={field.name}
          onChange={(e) =>
            this.onChange({
              name: e.target.value,
              field: { key: e.target.value }
            })
          }
        />
        <br />

        <label>Field Type</label>
        <br />
        <input
          type="text"
          name="field_type"
          value={field.field_type}
          readOnly={true}
        />
        <br />

        <label>Label</label>
        <br />
        <input
          type="text"
          name="label"
          value={field.field.label || ''}
          onChange={(e) => this.onChange({ field: { label: e.target.value } })}
        />
        <br />

        <label>pdf_document</label>

        <div>
          <select
            onChange={(e) =>
              this.onChange({ field: { pdf_document: e.target.value } })
            }
            value={field.field.pdf_document || ''}
          >
            {this.getDocumentOptions()}
          </select>
        </div>

        <label>pdf section</label>
        <br />
        <select
          value={field['pdf_section_id'] || ''}
          onChange={(e) => this.onChange({ pdf_section_id: e.target.value })}
        >
          {this.getSectionOptions()}
        </select>
        <br />

        <label>Order</label>
        <br />
        <input
          type="number"
          value={field.order || ''}
          onChange={(e) => this.onChange({ order: e.target.value })}
        />
        <br />
      </div>
    );
  }
}
