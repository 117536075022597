import { Button, ButtonGroup } from '@mui/material';
import React from 'react';

export default function RadioField({ value, field, onChange, handleNext }) {
  const options = field.options;

  return (
    <ButtonGroup aria-label="Basic button group">
      {
        options.map((option) => (
          <Button key={option.value} onClick={() => {
            onChange(option.value)
          }} variant={value === option.value ? 'contained' : 'outlined'}>
            {option.label}
          </Button>
        ))
      }
    </ButtonGroup>
  )
}