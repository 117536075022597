import { Box, CircularProgress, Stack } from '@mui/material';
import React from 'react';

export default function PageLoader() {
  return (
    <Stack width="100%" height="100vh" justifyContent="center">
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    </Stack>
  )
}