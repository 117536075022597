import { takeLatest } from 'redux-saga/effects';
import {
  getPdfTemplates,
  getPdfTemplate,
  updatePdfTemplate,
  getPdfTemplateFields,
  deletePdfField,
  getPdfTemplateField,
  downloadPdf
} from './pdfTemplateSaga';
import { getMlsFields } from './mlsFieldsSaga';
import {
  PDF_TEMPLATES_FETCH_REQUESTED,
  PDF_TEMPLATE_FETCH_REQUESTED,
  UPDATE_PDF_TEMPLATE_REQUEST,
  PDF_TEMPLATE_FIELDS_REQUESTED,
  DELETE_PDF_FIELD_REQUEST,
  PDF_TEMPLATE_FIELD_REQUESTED,
  DOWNLOAD_PDF, PDF_TEMPLATE_COPY_FIELDS_REQUESTED
} from '../actions/pdfTemplateActions';
import { MLS_FIELDS_FETCH_REQUESTED } from '../actions/mlsFieldActions';

export default function* rootSaga() {
  yield takeLatest(PDF_TEMPLATES_FETCH_REQUESTED, getPdfTemplates);
  yield takeLatest(PDF_TEMPLATE_FETCH_REQUESTED, getPdfTemplate);
  yield takeLatest(UPDATE_PDF_TEMPLATE_REQUEST, updatePdfTemplate);
  yield takeLatest(PDF_TEMPLATE_FIELDS_REQUESTED, getPdfTemplateFields);
  yield takeLatest(PDF_TEMPLATE_COPY_FIELDS_REQUESTED, getPdfTemplateFields);
  yield takeLatest(DELETE_PDF_FIELD_REQUEST, deletePdfField);
  yield takeLatest(PDF_TEMPLATE_FIELD_REQUESTED, getPdfTemplateField);
  yield takeLatest(DOWNLOAD_PDF, downloadPdf);
  yield takeLatest(MLS_FIELDS_FETCH_REQUESTED, getMlsFields);
}
