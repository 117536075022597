import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { formatDateTime } from '../../shared/services/dates';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { getApiUrl } from '../../shared/Network';
import useComplianceReviewStore from '../../shared/stores/ComplianceReviewStore';
import Request from '../../shared/services/Request';

export default function CommentThread({ comment }) {
  const [childComments, setChildComments] = useState(comment.thread);
  const [showThreadReply, setShowThreadReply] = useState(false);

  const { currentDocument, updateComment } = useComplianceReviewStore();

  const { register, handleSubmit, reset } = useForm();

  const user = JSON.parse(sessionStorage.getItem('user'));

  const handleResolve = () => {
    Request.post(getApiUrl() + `/v2/compliances/complianceables/${currentDocument.id}/comments/${comment.id}/resolve`, {}, (response) => {
      updateComment(response);
    })
  }

  const onSubmit = (data) => {
    if(!data.comment) return;

    Request.post(getApiUrl() + `/v2/compliances/complianceables/${currentDocument.id}/comments/${comment.id}/reply_to_comment`, { comment: data }, (response) => {
      updateComment({ ...comment, resolved_at: null });
      setChildComments([...childComments, response]);
      reset();
    })
  }

  return(
    <Stack
      bgcolor={comment.user_id === user.id ? "info.light" : 'lightgrey'}
      p={2}
      borderRadius="8px"
      mb={2}
    >
      <Stack spacing={2} onClick={() => setShowThreadReply(!showThreadReply)}>
        <Stack>
          <Stack mb={0.5}>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="body1" sx={{ my: 'auto' }}>
                  {comment.user?.full_name}
                </Typography>
                <Typography variant="caption">
                  {formatDateTime(comment.created_at)}
                </Typography>
              </Stack>
              {!comment.resolved_at && <IconButton onClick={handleResolve}>
                <CheckCircle />
              </IconButton>}
            </Stack>
          </Stack>
          <Typography>
            {comment.comment}
          </Typography>
        </Stack>
        {childComments?.length > 0 && childComments.map((childComment) => (
          <Stack pl={1}>
            <Stack mb={0.5}>
              <Typography variant="body1">
                {childComment.user?.full_name}
              </Typography>
              <Typography variant="caption">
                {childComment.created_at && formatDateTime(childComment.created_at)}
              </Typography>
            </Stack>
            <Typography>
              {childComment.comment}
            </Typography>
          </Stack>
        ))}
      </Stack>
      {comment.resolved_at &&
      <Stack mt={2}>
        <Typography variant="caption">
          Resolved at {formatDateTime(comment.resolved_at)}
        </Typography>
      </Stack>}
      {showThreadReply && <Stack mt={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register('comment')}
            size='small'
            label={comment.resolved_at ? "Replying will unresolve the comment" : "Reply"}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton variant="contained" color="primary" type="submit">
                  <AddCircleIcon />
                </IconButton>
              )
            }}
          />
        </form>
      </Stack>}
    </Stack>
  )
}