import { combineReducers } from 'redux';

import pdfTemplates from './pdfTemplateReducer';
// import pdfSectionReducer from '../../../pdf_annotator/redux/reducers/textFieldReducer'
import textField from '../../../pdf_annotator/redux/reducers/textFieldReducer';

const rootReducer = combineReducers({
  pdfTemplates,
  // pdfSectionReducer,
  textField
});

export default rootReducer;
