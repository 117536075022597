import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.keepOpen);
  }

  disconnect() {
    this.element.removeEventListener('click', this.keepOpen);
  }

  keepOpen(event) {
    event.stopPropagation();
  }
}
