import React, { forwardRef } from 'react';
import { Box, IconButton, MenuItem, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useForm } from "react-hook-form";

const InlineTextField = forwardRef(({formRegistration, inputEndAdornment, textAlign, fontSize, autoFocus=true, onBlur, ...props}, ref) => {
  return (
    <TextField
      variant='standard'
      sx={{
        padding: 0,
        "&.Mui-focused fieldset": {
          border: 'none',
          borderWidth: "0px"
        },
      }}
      fullWidth
      onBlur={onBlur}
      {...props}
      inputProps={{
        sx: {
          padding: 0,
          textAlign: textAlign,
        }
      }}
      InputProps={{
        disableUnderline: true,
        sx: {
          padding: 0,
          color: '#234352',
          margin: 0,
          fontSize: fontSize
        },
        ...formRegistration,
        endAdornment: inputEndAdornment,
        onBlur: onBlur,
      }}
      inputRef={ref}
      autoFocus={autoFocus}
    />
  );
});

const EditableText = ({onSave, placeholder, autoFocus, type = 'text', textAlign, fontSize="16px", initialValue = "", options = [], children, disabled}) => {
  const [ editing, setEditing ] = useState(false);
  const theme = useTheme();
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      value: initialValue
    }
  });

  const onSubmit = (values) => {
    if(!values.value) {
      setEditing(false);
      return;
    }

    if(type === 'text' || type === 'markdown' || type === 'textarea' || type === 'select') {
      onSave(values.value);
    } else if(type === 'date') {
      const parsedDate = analyzeText(values.value);
      if(parsedDate.length > 0) {
        console.log(parsedDate);
        onSave(parsedDate[0].start);
      }
    } else if(type === 'duration') {
      const parsedDuration = analyzeText(values.value);
      if(parsedDuration > 0) {
        onSave(parsedDuration);
      }
    }

    setEditing(false);
  };

  useEffect(() => {
    setValue('value', initialValue);
  }, [initialValue]);

  const renderEditor = () => {
    return (
      <InlineTextField
        fontSize={fontSize}
        formRegistration={register('value')}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onBlur={handleSubmit(onSubmit)}
        multiline={type === 'textarea'}
        textAlign={textAlign}
        sx={{
          my: 'auto'
        }}
        inputEndAdornment={
          <IconButton sx={{ padding: 0 }} onClick={() => { setEditing(false); }}>
            <ClearIcon color="primary" fontSize="small"/>
          </IconButton>
        }
      />
    );
  };

  if(!editing && initialValue !== null) return (
    <Box
      px={0.5}
      onClick={() => console.log('click') || !disabled && setEditing(true)}
      display={type !== "markdown" ? "flex" : "block"}
      sx={{
        cursor: "pointer",
        border: '1px solid transparent',
        borderRadius: '5px',
        transition: 'background 0.2s'
      }}
    >
      {children}
    </Box>
  );

  return (
    <>
      <form style={{ marginTop: 'auto', marginBottom: 'auto'}} onSubmit={handleSubmit(onSubmit)}>
        {renderEditor()}
      </form>
    </>
  );
};

export default EditableText;