import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("input", this.formatPhone.bind(this));
  }

  formatPhone(event) {
    const input = event.target;
    let value = input.value.replace(/\D/g, "");
    if (value.length > 3 && value.length <= 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else if (value.length > 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
    input.value = value;
  }
}