import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function Settings() {
  return (
    <Stack>
      <Stack>
        <Typography variant="h4">Settings</Typography>
      </Stack>
    </Stack>
  )
} 