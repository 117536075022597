import { put } from 'redux-saga/effects';
import { getHeaders, getApiBaseUrl, API_URL } from '../../../shared/Network';
import { mlsFieldsFetched } from '../actions/mlsFieldActions';

export function* getMlsFields() {
  try {
    const response = yield fetch(API_URL + '/properties/annotation_fields', {
      method: 'GET',
      headers: getHeaders()
    });
    if (response.status >= 400) {
      console.log('Error in getting MLS fields', response);
    } else {
      const responseJson = yield response.json();
      yield put(mlsFieldsFetched(responseJson));
    }
  } catch (e) {
    console.log('Error in getting MLS fields', e);
  }
}
