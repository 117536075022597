import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HtmlPage from './html_page';
import {
  pdfTemplatesFetchRequested,
  pdfTemplateFetchRequested,
  clearPdfTemplateState,
  pdfTemplateFieldsRequested
} from './redux/actions/pdfTemplateActions';
import { mlsFieldsFetchRequested } from './redux/actions/mlsFieldActions';

export default function PdfTemplates() {
  const MLS_ORG_ID = '86b3f50dc61748c3c538a2c4';

  const dispatch = useDispatch();

  const pdfTemplates = useSelector((state) => {
    return state.pdfTemplates.pdfTemplates;
  });

  const pdfTemplate = useSelector((state) => {
    return state.pdfTemplates.pdfTemplate;
  });

  useEffect(() => {
    dispatch(pdfTemplatesFetchRequested(MLS_ORG_ID));
    dispatch(mlsFieldsFetchRequested());
  }, [pdfTemplate]);

  if (pdfTemplate) {
    return (
      <HtmlPage
        htmlTemplate={pdfTemplate.html_template}
        close={() => {
          dispatch(clearPdfTemplateState());
        }}
      />
    );
  } else {
    let sortedPdfTemplates = [...pdfTemplates];
    sortedPdfTemplates.sort((t1, t2) => {
      if (t1.name < t2.name) {
        return -1;
      }
      if (t2.name > t1.name) {
        return 1;
      }
      return 0;
    });

    return (
      <div>
        {sortedPdfTemplates.map((pdfTemplate) => {
          return (
            <div>
              <a
                onClick={() => {
                  dispatch(pdfTemplateFetchRequested(pdfTemplate.id));
                  dispatch(pdfTemplateFieldsRequested(pdfTemplate.id));
                }}
              >
                {pdfTemplate.name}
              </a>
            </div>
          );
        })}
      </div>
    );
  }
}
