import React from 'react';
import ToggleFields from './ToggleFields';
import { connect } from 'react-redux';

class RadioOption extends React.Component {
  constructor(props) {
    super(props);
  }

  getTextField = () => {
    let textField = this.props.option.text_field;

    if (textField != null) {
      return (
        <div>
          <p>
            <b>Text Field</b>
          </p>
          <input
            type="text"
            value={textField.name}
            onChange={(e) =>
              this.onChange('text_field', { name: e.target.value })
            }
          />
          <br />
          <label>placeholder</label>
          <br />
          <input
            type="text"
            value={textField.placeholder || ''}
            onChange={(e) =>
              this.onChange('text_field', { placeholder: e.target.value })
            }
          />
          <br />
          <label>Checkbox</label>
          <input
            type="checkbox"
            checked={textField.checkbox}
            onChange={this.updateCheckbox}
          />
        </div>
      );
    } else {
      return (
        <a
          href="#"
          onClick={(e) => {
            let option = Object.assign({}, this.props.option);
            option.text_field = {
              name: 'option_text_field' + this.props.index,
              checkbox: true
            };
            this.props.onChange(this.props.index, option);
            e.preventDefault();
          }}
        >
          + Add text field
        </a>
      );
    }
  };

  updateCheckbox = (event) => {
    let option = { ...this.props.option };
    option.text_field = { ...option.text_field }; //deep copy of data, getting read only error

    let checked = event.target.checked;

    if (checked) {
      option.text_field.checkbox = checked;
      this.props.onChange(this.props.index, option);
    } else {
      option.text_field.checkbox = checked;
      this.props.onChange(this.props.index, option);
    }
  };

  onChange = (key, data) => {
    let option = Object.assign({}, this.props.option);
    let textField = Object.assign({}, option.text_field);

    if (key === 'text_field') {
      textField = Object.assign(textField, data);
      option['text_field'] = textField;
    } else {
      option[key] = data;
    }

    this.props.onChange(this.props.index, option);
  };

  onToggleChange = (toggleFields) => {
    this.onChange('toggle_fields', toggleFields);
  };

  render() {
    return (
      <div>
        <label>label</label>
        <br />
        <input
          type="text"
          value={this.props.option.label || ''}
          onChange={(e) => this.onChange('label', e.target.value)}
        />
        <br />
        <label>value</label>
        <br />
        <input
          type="text"
          value={this.props.option.value || ''}
          onChange={(e) => this.onChange('value', e.target.value)}
        />
        <br />
        {this.getTextField()}
        <br />
        <ToggleFields
          toggleFields={this.props.option['toggle_fields']}
          fields={this.props.fields}
          onChange={this.onToggleChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pdfField: state.pdfTemplates.pdfField
  };
};

export default connect(mapStateToProps)(RadioOption);
