import { Box, Button, Card, Dialog, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FlagIcon from '@mui/icons-material/Flag';
import Request from '../../shared/services/Request';
import useClientAppStore from '../../shared/stores/ClientAppStore';
import { getApiUrl } from '../../shared/Network';
import PageLoader from '../../shared/components/PageLoader';
import PdfInput from '../components/PdfInput';
import useAlertStore from '../../shared/stores/AlertStore';

export default function Section() {
  const [ fieldValue, setFieldValue ] = useState();
  const [ navigationDirection, setNavigationDirection ] = useState('next');
  const [ showFlagModal, setShowFlagModal ] = useState(false);
  const [ clientQuestion, setClientQuestion ] = useState();
  const { currentTransaction, currentDocument, setCurrentSection, currentSection, setValues, values, updateValue, setCurrentValue, currentValue } = useClientAppStore();
  const { setAlertMessage } = useAlertStore();
  const { documentId, sectionId } = useParams();

  const navigate = useNavigate();

  const handleBack = (updateValue = true) => {
    setNavigationDirection('back')
    if(updateValue) {
      handleUpdate(() => {
        const currentIndex = values.findIndex((s) => s.id === currentValue.id)
        if(currentIndex > 0) {
          setCurrentValue(values[currentIndex - 1])
          setFieldValue(values[currentIndex - 1]?.value || "")
        } else {
          setFieldValue("")
          handlePreviousSection()
        }
      })
    } else {
      const currentIndex = values.findIndex((s) => s.id === currentValue.id)
        if(currentIndex > 0) {
          setCurrentValue(values[currentIndex - 1])
          setFieldValue(values[currentIndex - 1]?.value || "")
        } else {
          setFieldValue("")
          handlePreviousSection()
        }
    }
  }

  const handleNext = (updateValue = true) => {
    setNavigationDirection('next')
    if(updateValue) {
      handleUpdate(() => {
        const currentIndex = values.findIndex((s) => s.id === currentValue.id)
        if(currentIndex < values.length - 1) {
          setCurrentValue(values[currentIndex + 1])
          setFieldValue(values[currentIndex + 1]?.value || "")
          setClientQuestion(values[currentIndex + 1]?.client_question)
        } else {
          setAlertMessage("Section completed! Great job!", "success")
          setFieldValue("")
          handleNextSection()
        }
      })
    } else {
      const currentIndex = values.findIndex((s) => s.id === currentValue.id)
        if(currentIndex < values.length - 1) {
          setCurrentValue(values[currentIndex + 1])
          setFieldValue(values[currentIndex + 1]?.value || "")
          setClientQuestion(values[currentIndex + 1]?.client_question)
        } else {
          setAlertMessage("Section completed! Great job!", "success")
          setFieldValue("")
          handleNextSection()
        }
    }

  }

  const handlePreviousSection = () => {
    const currentSectionIndex = currentDocument.pdf_sections.findIndex((s) => s.id === parseInt(sectionId, 10))
    if(currentSectionIndex > 0) {
      navigate(`/v2/client_apps/documents/${documentId}/${currentDocument.pdf_sections[currentSectionIndex - 1].id}`)
    } else {
      navigate(`/v2/client_apps/documents/${documentId}`)
    }
  }

  const handleNextSection = () => {
    const currentSectionIndex = currentDocument.pdf_sections.findIndex((s) => s.id === parseInt(sectionId, 10))
    if(currentSectionIndex < currentDocument.pdf_sections.length - 1) {
      navigate(`/v2/client_apps/documents/${documentId}/${currentDocument.pdf_sections[currentSectionIndex + 1].id}`)
    } else {
      navigate(`/v2/client_apps/documents/${documentId}`)
    }
  }

  const handleUpdate = (callback) => {
    Request.patch(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}/values/${currentValue.id}`, { value: fieldValue }, (data) => {
      console.log(data)
      updateValue(data)
      callback && callback()
    })
  }

  const handleRemoveFlag = () => {
    Request.patch(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}/values/${currentValue.id}/submit_question`, { question: null }, (data) => {
      updateValue(data)
      setShowFlagModal(false)
      setClientQuestion(null)
      setAlertMessage("Question removed!", "success")
    })
  }
  const handleSubmitQuestion = () => {
    Request.patch(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}/values/${currentValue.id}/submit_question`, { question: clientQuestion }, (data) => {
      updateValue(data)
      setShowFlagModal(false)
      setAlertMessage("Question flagged for agent!", "success")
    })
  }

  const currentProgress = () => {
    const currentValueIndex = values.findIndex((s) => s.id === currentValue.id)
    return (currentValueIndex / values.length) * 100
  }

  useEffect(() => {
    Request.get(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}/sections/${sectionId}`, (data) => {
      if(data.values.length === 0) {
        handleNextSection()
        return
      } else {
        setCurrentSection(data.section)
        setValues(data.values)
        console.log(data.values)
        const currentValue = data.values.find((value) => (value.value === '' || value.value === null) && !value?.pdf_field?.field?.hidden) || data.values[0]
        setCurrentValue(currentValue)
      }
    })
  }, [documentId, sectionId])

  useEffect(() => {
    if(currentValue?.pdf_field?.field?.field?.hidden || currentValue?.pdf_field?.field?.hidden) {
      const toggledValue = values.filter((value) => {
        const toggleFields = value?.pdf_field?.field?.options?.map((option) => option.toggle_fields && option.toggle_fields).flat()
        return value?.pdf_field?.field_type === 'radio' && toggleFields.includes(currentValue.pdf_field.external_id)
      })[0]
      const toggleOption = toggledValue.pdf_field.field.options.filter((option) => option.toggle_fields && option.toggle_fields.includes(currentValue.pdf_field.external_id))[0]
      if(toggledValue?.value === toggleOption.value) {
        if(currentValue?.value) {
          setFieldValue(currentValue.value)
          setClientQuestion(currentValue.client_question)
        }
      }
      else {
        navigationDirection === 'next' ? handleNext(false) : handleBack(false);
      }
    } else {
      if(currentValue?.value) {
        setFieldValue(currentValue.value)
        setClientQuestion(currentValue.client_question)
      }
    }
  }, [currentValue])

  if(!currentSection || !currentDocument || !currentValue) {
    return <PageLoader />
  }

  console.log(currentValue)

  return (
    <Stack spacing={2} te>
      <Stack direction={{ xs: 'column-reverse', sm: 'row'}} justifyContent="space-between">
        <Stack>
          <Typography variant="h6">{currentTransaction.name}</Typography>
          <Typography variant="h4">{currentDocument.name}</Typography>
        </Stack>
        <Box direction="row" mb={1}>
          <Button variant="outlined" onClick={() => navigate(`/v2/client_apps/documents/${documentId}`)} color="secondary">Back to document</Button>
        </Box>
      </Stack>
      <Stack>
        <Card sx={{ height: "100%" }}>
          <Stack height="100%" p={2} textAlign="center" spacing={2}>
            <Stack>
              <Stack mb={1}>
                <LinearProgress variant="determinate" value={currentProgress()} />
              </Stack>
              <Typography variant="h6">{currentSection?.title}</Typography>
              <Typography variant="h4">{currentValue?.pdf_field?.field?.field?.title || currentValue?.pdf_field?.field?.title}</Typography>
            </Stack>
            <Stack alignItems="center" px={{ xs: 0, sm: 10 }}>
              <PdfInput value={fieldValue} onChange={(val) => setFieldValue(val)} field={currentValue.pdf_field.field} handleNext={handleNext} />
            </Stack>
          </Stack>
        </Card>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2} width={{ xl: "65%" }} mx="auto !important" >
        <Stack direction="row">
          <Button onClick={handleBack} variant="contained" color="primary">Back</Button>
        </Stack>
        <Button onClick={() => setShowFlagModal(true)} variant="outlined" color="secondary">
          <Stack direction="row" spacing={2}>
            {clientQuestion && <FlagIcon sx={{ my: 'auto !important' }} color="warning" />}
            <Typography>
              {clientQuestion ? "Question flagged" : "Flag question for agent"}
            </Typography>
          </Stack>
        </Button>
        <Button onClick={handleNext} variant="contained" color="primary">Next</Button>
      </Stack>
      <Dialog fullWidth open={showFlagModal} onClose={() => setShowFlagModal(false)}>
        <Stack p={2} spacing={2} width={{ xs: "100%", sm: "100%"}} textAlign={'center'} justifyContent='center'>
          <Typography variant="h6">Message to agent</Typography>
          <TextField
            value={clientQuestion}
            onChange={(e) => setClientQuestion(e.target.value)}
            placeholder="Write a message here to include to your agent..."
            fullWidth
            multiline
            rows={4}
          />
          <Stack direction="row" justifyContent="space-between" spacing={{xs: 1, sm: 2}}>
            {clientQuestion && <Button onClick={handleRemoveFlag} variant="outlined" color="secondary">Remove flag</Button>}
            <Button onClick={() => setShowFlagModal(false)} variant="outlined" color="secondary">Cancel</Button>
            <Button onClick={handleSubmitQuestion} variant="contained" color="primary">Send</Button>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  )
}