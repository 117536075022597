import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  confirmDelete(event) {
    event.preventDefault();
    if (confirm('Are you sure you want to archive this document?')) {
      this.formTarget.submit();
    }
  }
}