import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('keyup', (evt) => {
      Rails.fire(this.element.form, 'submit');
    });
  }
}
