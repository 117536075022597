export const MLS_FIELDS_FETCH_REQUESTED = 'mlsFields/mlsFieldsFetchRequested';

export const mlsFieldsFetchRequested = () => ({
  type: MLS_FIELDS_FETCH_REQUESTED
});

export const MLS_FIELDS_FETCHED = 'mlsFields/mlsFieldsFetched';

export const mlsFieldsFetched = (mlsFields) => ({
  type: MLS_FIELDS_FETCHED,
  payload: mlsFields
});
