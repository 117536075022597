import idx from 'idx';

const ENVIRONMENT = process.env.RAILS_ENV || 'development';

const HOST = getApiUrl();

export const API_PATH = '/api/v1';

export const API_URL = HOST + API_PATH;

export function getApiUrl() {
  if (ENVIRONMENT === 'production') {
    return 'https://app.useoffer.ai'; // TODO change this when we know where production will be
  } else if (ENVIRONMENT === 'staging') {
    return 'https://offer-web-dev.herokuapp.com';
  } else {
    return 'http://localhost:3000';
  }
}

export function getApplication() {
  if (ENVIRONMENT === 'production') {
    return {
      client_id: 'IYHB87HNUFEiQ6gtjsjFSGH7Kng8jF8l_w02GiSxFBU',
      client_secret: 'ylkc573iHufQNYeVcqPBwcX0xGmLVAjPlTgdQxBqiyQ'
    };
  } else if (ENVIRONMENT === 'staging') {
    return {
      client_id: '6vAhv4mi-z2uL67hSRBWqBKm8luBjwV3SXyLh_nu5U0',
      client_secret: 'oyp15dCeoC3nL8U9vNWdDjqGWB6-1xvh2hz-U-A-VN8'
    };
  } else {
    return {
      client_id: '0cMBm79ZdOlkUCS4pgvHf-JFtdwmLoazSfGP_h1yg4Q',
      client_secret: 'gVgjSYvtZVjuKo-av5l6cyC1mw2lEmIIUs_XiFgivG4'
    };
  }
}

export function getPdfGenUrl() {
  if (ENVIRONMENT === 'staging') {
    return 'https://pdfgen-dev.herokuapp.com';
  } else {
    return 'http://localhost:8080';
  }
}

export function getApiBaseUrl() {
  return getApiUrl() + API_PATH;
}

export const sessionToTokenAuth = async ({ onSuccess, onError }) => {
  const isLoggedIn = Boolean(window.sessionStorage.getItem('auth'));

  if (isLoggedIn) {
    console.log(
      '[sessionToTokenAuth] Already logged in via token, skipping sessionToTokenAuth'
    );
    return;
  }

  try {
    let application = getApplication();
    const res = await fetch(
      '/sessionToTokenAuth?client_id=' + application['client_id']
    );
    if (!res.ok) {
      console.error(`[sessionToTokenAuth] HTTP error, status: ${res.status}`);
      if (onError) {
        onError(error);
      }
      return;
    }

    const json = await res.json();

    if (json.data && json.data.user && json.data.auth) {
      console.log(
        '[sessionToTokenAuth] Successfully acquired token via session'
      );
      window.sessionStorage.setItem('auth', JSON.stringify(json.data.auth));
      window.sessionStorage.setItem('user', JSON.stringify(json.data.user));
      onSuccess && typeof onSuccess === 'function' && onSuccess();
    } else {
      if (onError) {
        onError();
      }
      console.error(
        '[sessionToTokenAuth] Unexpected response format, json:',
        json
      );
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
    console.error('[sessionToTokenAuth] An error occurred:', error);
  }
};

export const login = (body) => {
  const application = getApplication();
  let postParams = { ...body, application };

  return fetch(API_URL + '/oauth/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(handleErrors)
    .catch((error) => console.log('Error: ' + JSON.stringify(error)));
};

export const getPdfSectionsFromPdfDocument = (document_id) => {
  return fetch(API_URL + '/pdf_documents/' + document_id + '/pdf_sections', {
    headers: getHeaders()
  }).then(handleErrors);
};

export const createPdfSectionForPdfDocument = (document_id, params) => {
  return fetch(API_URL + '/pdf_documents/' + document_id + '/pdf_sections', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(params)
  }).then(handleErrors);
};

export const updatePdfSectionForPdfDocument = (
  document_id,
  section_id,
  params
) => {
  return fetch(
    API_URL + '/pdf_documents/' + document_id + '/pdf_sections/' + section_id,
    {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(params)
    }
  ).then(handleErrors);
};

export const getPdfDocuments = () => {
  let user = JSON.parse(window.sessionStorage.getItem('user'));
  return fetch(
    API_URL + '/pdf_documents?mls_org=' + user['data']['mls_org_id'],
    {
      headers: getHeaders()
    }
  )
    .then(handleErrors)
    .catch((error) => console.log('API Error: ' + JSON.stringify(error)));
};

export const createPdfDocument = (data) => {
  let headers = getHeaders();
  delete headers['Content-Type'];

  return fetch(API_URL + '/pdf_documents', {
    method: 'POST',
    headers: headers,
    body: data
  }).then(handleErrors);
};

export const updatePdfDocument = (id, data) => {
  let headers = getHeaders();
  delete headers['Content-Type'];

  return fetch(API_URL + '/pdf_documents/' + id, {
    method: 'PUT',
    headers: headers,
    body: data
  }).then(handleErrors);
};

export const getPdfDocument = (document_id) => {
  let headers = getHeaders();
  headers['Accept'] = 'application/pdf';
  return fetch(API_URL + '/pdf_documents/' + document_id + '/pdf', {
    headers: headers
  }).then(handleErrors);
};

export const testPdfDocument = (document_id) => {
  let headers = getHeaders();
  delete headers['Content-Type'];

  return fetch(API_URL + '/pdf_documents/' + document_id + '/test', {
    headers: headers
  }).then(handleErrors);
};

export const getPdfFieldsForPdfDocument = (document_id) => {
  return fetch(API_URL + '/pdf_documents/' + document_id + '/pdf_fields', {
    headers: getHeaders()
  }).then(handleErrors);
};

export const createPdfFieldForPdfDocument = (document_id, params) => {
  return fetch(API_URL + '/pdf_documents/' + document_id + '/pdf_fields', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(params)
  });
};

export const updatePdfFieldForPdfDocument = (document_id, id, params) => {
  return fetch(
    API_URL + '/pdf_documents/' + document_id + '/pdf_fields/' + id,
    {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(params)
    }
  );
};

export const deletePdfFieldFromPdfDocument = (document_id, id) => {
  return fetch(
    API_URL + '/pdf_documents/' + document_id + '/pdf_fields/' + id,
    {
      method: 'DELETE',
      headers: getHeaders()
    }
  );
};

//Pdf Template Calls

export const getPdfSectionsFromPdfTemplate = (document_id) => {
  console.log('calling getPdfSectionsFromPdfTemplate: ', document_id);
  return fetch(API_URL + '/pdf_templates/' + document_id + '/pdf_sections', {
    headers: getHeaders()
  }).then(handleErrors);
};

export const createPdfSectionForPdfTemplate = (document_id, params) => {
  return fetch(API_URL + '/pdf_templates/' + document_id + '/pdf_sections', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(params)
  }).then(handleErrors);
};

export const updatePdfSectionForPdfTemplate = (
  document_id,
  section_id,
  params
) => {
  return fetch(
    API_URL + '/pdf_templates/' + document_id + '/pdf_sections/' + section_id,
    {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(params)
    }
  ).then(handleErrors);
};

export const getPdfTemplates = () => {
  return fetch(API_URL + '/pdf_templates?mls_org=86b3f50dc61748c3c538a2c4', {
    headers: getHeaders()
  })
    .then(handleErrors)
    .catch((error) => console.log('API Error: ' + JSON.stringify(error)));
};

export const createPdfTemplate = (data) => {
  let headers = getHeaders();
  delete headers['Content-Type'];

  return fetch(API_URL + '/pdf_templates', {
    method: 'POST',
    headers: headers,
    body: data
  }).then(handleErrors);
};

export const updatePdfTemplate = (id, data) => {
  let headers = getHeaders();
  delete headers['Content-Type'];

  return fetch(API_URL + '/pdf_templates/' + id, {
    method: 'PUT',
    headers: headers,
    body: data
  }).then(handleErrors);
};

// export const getPdfTemplate = (document_id) => {
//   let headers = getHeaders();
//   headers['Accept'] = 'application/pdf';
//   return fetch(API_URL + '/pdf_templates/' + document_id + '/pdf', {
//     headers: headers
//   }).then(handleErrors)
// };

export const testPdfTemplate = (document_id) => {
  let headers = getHeaders();
  delete headers['Content-Type'];

  return fetch(API_URL + '/pdf_templates/' + document_id + '/test', {
    headers: headers
  }).then(handleErrors);
};

export const getPdfFieldsForPdfTemplate = (document_id) => {
  return fetch(API_URL + '/pdf_templates/' + document_id + '/pdf_fields', {
    headers: getHeaders()
  }).then(handleErrors);
};

export const createPdfFieldForPdfTemplate = (document_id, params) => {
  return fetch(API_URL + '/pdf_templates/' + document_id + '/pdf_fields', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(params)
  });
};

export const updatePdfFieldForPdfTemplate = (document_id, id, params) => {
  return fetch(
    API_URL + '/pdf_templates/' + document_id + '/pdf_fields/' + id,
    {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(params)
    }
  );
};

export const deletePdfFieldFromPdfTemplate = (document_id, id) => {
  return fetch(
    API_URL + '/pdf_templates/' + document_id + '/pdf_fields/' + id,
    {
      method: 'DELETE',
      headers: getHeaders()
    }
  );
};

//End of Pdf Template Calls

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export const parseErrors = (response) => {
  let errors = idx(response, (_) => _.errors);

  if (errors === null || errors === undefined) {
    return [];
  } else {
    return errors;
  }
};

export const getHeaders = () => {
  let auth = JSON.parse(window.sessionStorage.getItem('auth'));

  return {
    Authorization: 'Bearer ' + auth['access_token'],
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };
};
