import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useEnsureAuth from '../../shared/hooks/ensureAuth';
import { Alert, Button, Dialog, Stack, TextField, Typography } from '@mui/material';
import { getApiUrl } from '../../shared/Network';
import Request from '../../shared/services/Request';

export default function NewPasswordModal() {
  const [ show, setShow ] = useState(false);
  const [ error, setError ] = useState();

  const { user } = useEnsureAuth();

  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    if(!user['password_set?']) {
      setShow(true);
    }
  }, [user['password_set?']])

  const onSubmit = ({ password, password_confirmation }) => {
    Request.patch(`${getApiUrl()}/users/password`, {
      reset_password_token: user.reset_password_token,
      current_password: null,
      password: password,
      password_confirmation: password
    }, () => {
      sessionStorage.setItem('user', JSON.stringify({ ...user, 'password_set?': true }));
      setShow(false);
    }, (error) => {
      setError(error)
    })
  }

  return (
    <Dialog
      open={show}
    >
      <Stack p={2} spacing={2}>
        <Stack textAlign="center">
          <Stack>
            <Typography variant="h4">Welcome to Offer!</Typography>
          </Stack>
          <Stack>
            <Typography variant="h6">You will use this platform to complete necessary paperwork as provided to you by your real estate agent.</Typography>
          </Stack>
          <Stack>
            <Typography fontWeight={510} variant="h6">Before we get started, please enter a password to finish your account setup.</Typography>
          </Stack>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Stack>
              <TextField {...register('password', { required: true })} label="Password" type="password" />
            </Stack>
            <Stack>
              <TextField {...register('password_confirmation', { required: true })} label="Password Confirmation" type="password" />
            </Stack>
            {error && (
              <Stack>
                <Alert severity='error'>Passwords do not match</Alert>
              </Stack>
            )}
            <Stack direction="row">
              <Button variant="contained" type="submit">Submit</Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Dialog>
  )
}