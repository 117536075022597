// app/javascript/controllers/timestamp_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["time"];

  connect() {
    this.updateTime();
  }

  updateTime() {
    this.timeTargets.forEach(element => {
      const timestamp = element.dataset.timestamp;
      const textLeft = element.dataset.textleft;
      console.log(element.dataset);
      if (timestamp) {
        const localDate = new Date(timestamp);
        const formattedDate = localDate.toLocaleString('default', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        });
        const formattedTime = localDate.toLocaleString('default', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
          timeZoneName: 'shortOffset'
        });

        element.innerHTML = `
          <div class="${textLeft == 'true' ? "text-start" : "text-end"}">
            <b>${formattedDate}</b>
            <div class="d-block">${formattedTime}</div>
          </div>
        `;
      }
    });
  }
}