import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function ComplianceStatusBadge({compliance_status, showPending}) {
  console.log(compliance_status)
  const bgColor = () => {
    switch(compliance_status) {
      case 'denied':
        return "error.light";
      case 'approved':
        return "success.light";
      default:
        return "grey.dark";
    }
  }

  const complianceText = () => {
    switch(compliance_status) {
      case 'denied':
        return "Rejected";
      case 'approved':
        return "Approved";
      default:
        return showPending ? "Pending" : "";
    }
  }

  return (
    <Stack
      bgcolor={bgColor()}
      borderRadius="3px"
      height={1}
      px={0.5}
      my="auto"
    >
      <Typography sx={{ my: 'auto !important' }} variant="caption" color="white">
        {complianceText()}
      </Typography>
    </Stack>
  )
}