import React from 'react'; 
import OfferPopover from '../../shared/components/OfferPopover';
import { CONTACT_TYPES } from '../../shared/constants';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';

export default function ContactTypeSelect({ value, onChange }) {
  const [ contactTypeState, setContactTypeState ] = React.useState(value);

  const handleChange = (contactType) => {
    if(onChange) {
      onChange(contactType);
      setContactTypeState(contactType);
    }
  }

  if(onChange) {
    return (
      <OfferPopover
        anchor={
          <Chip
            label={CONTACT_TYPES.find((contactType) => contactType.value === contactTypeState)?.label || 'N/A'}
            color={CONTACT_TYPES.find((contactType) => contactType.value === contactTypeState)?.color}
            size='small'
          />
        }
      >
        <Stack>
          {CONTACT_TYPES.map((contactType) => (
            <Button key={contactType.value} onClick={() => handleChange(contactType.value)}>
              <Stack direction="row" spacing={2}>
                <Box
                  height={12}
                  width={12}
                  borderRadius={10}
                  my="auto !important"
                  bgcolor={contactType.color + '.main'}
                />
                <Typography variant="body1">
                  {contactType.label}
                </Typography>
              </Stack>
            </Button>
          ))}
        </Stack>
      </OfferPopover>
    )
  }

  return (
    <Chip
      label={CONTACT_TYPES.find((contactType) => contactType.value === contactTypeState)?.label || 'N/A'}
      color={CONTACT_TYPES.find((contactType) => contactType.value === contactTypeState)?.color}
      size='small'
    />
  )
}