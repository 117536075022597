import { Controller } from 'stimulus';
import SignaturePad from 'signature_pad';
import trimCanvas from 'trim-canvas';

export default class extends Controller {
  static targets = [
    'canvas',
    'text',
    'save',
    'clear',
    'draw',
    'erase',
    'undo',
    'hiddenField'
  ];
  static values = {
    image: String
  };

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget);

    this.setActiveButton(this.drawTarget);

    // necessary for cursor/canvas to be lined up after loading.
    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.resizeCanvas();
          this.loadSavedImage();
        }
      });
    });
    this.intersectionObserver.observe(this.canvasTarget);

    window.addEventListener('resize', this.resizeCanvas.bind(this));

    this.signaturePad.addEventListener('endStroke', this.autoSave.bind(this));

    if (this.hasSaveTarget) {
      this.saveTarget.addEventListener('click', this.save.bind(this));
    }
    if (this.hasClearTarget) {
      this.clearTarget.addEventListener('click', this.clear.bind(this));
    }
    if (this.hasDrawTarget) {
      this.drawTarget.addEventListener('click', this.draw.bind(this));
    }
    if (this.hasEraseTarget) {
      this.eraseTarget.addEventListener('click', this.erase.bind(this));
    }
    if (this.hasUndoTarget) {
      this.undoTarget.addEventListener('click', this.undo.bind(this));
    }
    if (this.hasTextTarget) {
      this.textTarget.addEventListener(
        'input',
        this.addTextToCanvas.bind(this)
      );
    }
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeCanvas);
    this.intersectionObserver.disconnect();

    if (this.hasSaveTarget) {
      this.saveTarget.removeEventListener('click', this.save);
    }
    if (this.hasClearTarget) {
      this.clearTarget.removeEventListener('click', this.clear);
    }
    if (this.hasDrawTarget) {
      this.drawTarget.removeEventListener('click', this.draw);
    }
    if (this.hasEraseTarget) {
      this.eraseTarget.removeEventListener('click', this.erase);
    }
    if (this.hasUndoTarget) {
      this.undoTarget.removeEventListener('click', this.undo);
    }
    if (this.hasTextTarget) {
      this.textTarget.removeEventListener('change', this.addTextToCanvas);
    }
  }

  save() {
    if (!this.hasSaveTarget) {
      return;
    }
    // Make save button optional: If no save button target is provided, then it won't be attached.
    if (this.signaturePad.isEmpty()) {
      return alert('Please provide a signature first.');
    }

    const data = this.signaturePad.toDataURL();
    this.hiddenFieldTarget.value = data;
  }

  autoSave() {
    if (this.isCanvasEmpty()) {
      this.hiddenFieldTarget.value = '';
    } else {
      this.hiddenFieldTarget.value = this.trimCanvasToDataUrl();
    }
  }

  isCanvasEmpty() {
    const context = this.canvasTarget.getContext('2d');
    const data = context.getImageData(
      0,
      0,
      this.canvasTarget.width,
      this.canvasTarget.height
    ).data;

    // Check if any pixel's alpha value is not 0 (transparent)
    return !data.some((color) => color !== 0);
  }

  trimCanvasToDataUrl() {
    const clonedCanvas = this.canvasTarget.cloneNode();
    clonedCanvas.getContext('2d').drawImage(this.canvasTarget, 0, 0);
    return trimCanvas(clonedCanvas).toDataURL();
  }

  clear() {
    this.signaturePad.clear();

    this.autoSave();
  }

  draw() {
    this.signaturePad.minWidth = 0.5;
    this.signaturePad.maxWidth = 2.5;
    this.signaturePad.compositeOperation = 'source-over';
    this.setActiveButton(this.drawTarget);
  }

  erase() {
    this.signaturePad.minWidth = 5;
    this.signaturePad.maxWidth = 5;
    this.signaturePad.compositeOperation = 'destination-out';
    this.setActiveButton(this.eraseTarget);
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
    }

    this.autoSave();
  }

  setActiveButton(buttonElement) {
    const allButtons = [this.drawTarget, this.eraseTarget];
    allButtons.forEach((button) => button.classList.remove('active'));
    buttonElement.classList.add('active');

    if (buttonElement === this.drawTarget) {
      this.setCanvasCursor('draw');
    } else if (buttonElement === this.eraseTarget) {
      this.setCanvasCursor('erase');
    }
  }

  setCanvasCursor(mode) {
    if (mode === 'draw') {
      this.canvasTarget.classList.add('hover-cursor-pen');
      this.canvasTarget.classList.remove('hover-cursor-eraser');
    } else if (mode === 'erase') {
      this.canvasTarget.classList.add('hover-cursor-eraser');
      this.canvasTarget.classList.remove('hover-cursor-pen');
    }
  }

  // Adjust canvas coordinate space taking into account pixel ratio,
  // to make it look crisp on mobile devices.
  // This also causes canvas to be cleared.
  resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const canvas = this.canvasTarget;
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d', { willReadFrequently: true }).scale(ratio, ratio);
  }

  loadSavedImage() {
    // Check if there's an existing signature in the data attribute
    if (this.hasImageValue) {
      this.signaturePad.fromDataURL(this.imageValue);
    }
    console.log(this.imageValue);

    this.hiddenFieldTarget.value = this.imageValue;
  }

  addTextToCanvas() {
    this.clear();

    const text = this.textTarget.value;
    const context = this.canvasTarget.getContext('2d');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const canvasWidth = this.canvasTarget.offsetWidth * ratio;
    const canvasHeight = this.canvasTarget.offsetHeight * ratio;
    let fontSize = Math.round((canvasHeight * 0.4) / ratio);

    context.resetTransform();
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    // Adjust font size to fit the text within the canvas
    do {
      context.font = `${fontSize}px Cursive`;
      if (context.measureText(text).width < canvasWidth) {
        break; // The text fits the canvas, stop adjusting the font size
      }
      fontSize -= 1; // Decrease font size and check again
    } while (fontSize > 0);

    // Draw the text at the center of the canvas
    context.fillText(text, canvasWidth / 2, canvasHeight / 2);

    this.autoSave();
  }
}
