import { Stack, Typography, useTheme } from '@mui/material';
import humanizeString from 'humanize-string';
import React from 'react';

export default function TransactionTypeTag({ transactionType }) {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      bgcolor="#FFFFFF"
      p={0.75}
      borderRadius={'3px'}
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.08)"
      spacing={1}
    >
      <Stack
        height="10px"
        width="10px"
        borderRadius={"100px"}
        bgcolor={transactionType === 'PURCHASE' ? "info.main" : theme.palette.primary.main}
        my="auto !important"
      />
      <Typography variant="body1" sx={{ my: 'auto !important' }}>
        {humanizeString(transactionType)}
      </Typography>
    </Stack>
  )
}