import React from 'react';
import { useState, useEffect } from 'react';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import Login from '../shared/Login';
import MessageViewer from './MessageViewer';

export default function App(props) {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setLoggedIn(window.sessionStorage.getItem('auth') != null);
  }, []);

  if (loggedIn) {
    return (
      <Provider store={store}>
        <MessageViewer
          emailComponentId={props.emailComponentId}
          emailSourceId={props.emailSourceId}
          threadId={props.threadId}
        />
      </Provider>
    );
  } else {
    return <Login setLoggedIn={() => setLoggedIn(true)} />;
  }
}
