import { Stack, TextField } from '@mui/material';
import React from 'react';
import RadioField from './inputs/RadioField';
import CompositeField from './inputs/CompositeField';
import DateField from './inputs/DateField';

export default function PdfInput(props) {
  const { value, field, onChange } = props;

  switch(field.type) {
    case 'radio':
      return (
        <RadioField {...props} />
      )
    case 'composite':
      return (
        <CompositeField {...props} />
      )
    case 'datetime':
      return (
        <DateField {...props} />
      )
    default:
      return (
        <Stack width="100%">
          <TextField
            fullWidth
            multiline={field.type === 'text_area'}
            rows={field.type === 'text_area' ? 3 : 1}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            placeholder='Answer here...'
          />
        </Stack>
      )
  }
}