import { call } from 'redux-saga/effects';
import {
  getApiBaseUrl,
  getApiUrl,
  getHeaders,
  parseErrors,
  sessionToTokenAuth
} from '../../../shared/Network';
import { store } from '../../redux/store';
import {
  pdfOverlayFetched,
  pdfOverlayFetchFailed,
  pdfOverlaysFetchFailed
} from '../actions/pdfOverlayActions';
import { INTERNAL_ERROR } from '../../../shared/constants';

const MAX_RETRIES = 5;

export function* callApi(url, method, headers, body, action) {
  const response = yield fetch(url, {
    method,
    headers,
    body
  });

  if (!action.retries) {
    action.retries = 0;
  }

  if (response.status === 401) {
    window.sessionStorage.removeItem('auth');
    console.log('[callApi] 401: Removed token session');
    yield call(sessionToTokenAuth, {
      onSuccess: function () {
        action.retries += 1;
        console.log('[callApi] 401: Successfully reauthorized', action);
        store.dispatch(action);
      },
      onError: function (err) {
        action.retries += 1;
        console.log('[callApi] 401: Failed to reauthorize', err, action);
        store.dispatch(action);
      }
    });
    return null;
  } else if (action?.retries >= MAX_RETRIES) {
    console.log('[callApi] Exceeded max retries.', url, action);
    return null;
  }

  return response;
}

export function* log(action) {
  try {
    let url = getApiUrl() + '/logs';

    const { logs } = action.payload;

    yield call(
      callApi,
      url,
      'POST',
      { 'Content-Type': 'application/json' },
      JSON.stringify(logs),
      {}
    );
  } catch (e) {
    console.log('Error sending logs: ' + e.message);
  }
}
