import React from 'react';

import { Popover, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const OfferPopover = ({ anchor, children, onHover = false, side = 'left', forceClose, padding, ...props }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHover = (event) => {
    console.log('hovering')
    setHoverTimeout(setTimeout(() => {
      console.log(event.target)
      setAnchorEl(event.target);
    }, 500))
  }

  const handleClose = () => {
    setAnchorEl(null);
    if(hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
  };

  useEffect(() => {
    if(forceClose) setAnchorEl(null);
  }, [forceClose]);

  return (
    <>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseEnter={(e) => { if(onHover) { handleHover(e) } }}
        onMouseLeave={(e) => { if(onHover) { handleClose(e) } }}
      >
        {anchor}
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: onHover && 'none',
        }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: side,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: side,
          }}
          disableRestoreFocus
          {...props}
      >
        <Stack
          height="100%"
          p={padding || 2}
          overflow="scroll"
        >
          {children}
        </Stack>
      </Popover>
    </>
  );
};

export default OfferPopover;