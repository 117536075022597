import { Box, Button, Card, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import OfferTable from '../../shared/components/OfferTable';
import useTransactionStore from '../../shared/stores/TransactionsStore';
import { formatDate } from '../../shared/services/dates';
import TransactionTypeTag from '../../shared/components/TransactionTypeTag';
import TransactionStatusTag from '../../shared/components/TransactionStatusTag';
import OfferPopover from '../../shared/components/OfferPopover';
import useComplianceReviewStore from '../../shared/stores/ComplianceReviewStore';
import PageLoader from '../../shared/components/PageLoader';

export default function Transactions() {
  const { transactions, setTransactions } = useTransactionStore();
  const { setSelectedTransactions, selectedTransactions, setDocuments } = useComplianceReviewStore();

  const navigate = useNavigate();

  useEffect(() => {
    Request.get(getApiUrl() + '/v2/compliances/transactions', (data) => {
      setTransactions(data);
    });
  }, [])

  const handleStartReview = (selectedIds) => {
    if(selectedIds.length === 0) {
      setSelectedTransactions(transactions);
    } else {
      setSelectedTransactions(transactions.filter((transaction) => selectedIds.includes(transaction.id)));
    }
    navigate(`/v2/compliances/review`);
  }

  const handleReviewDocument = (document) => {
    setSelectedTransactions([]);
    setDocuments([document]);
    navigate(`/v2/compliances/review`);
  }

  if(!transactions) {
    return (
      <PageLoader />
    )
  }

  return (
    <Box>
      <Stack mb={2}>
        <Typography variant="h5">
          Compliance
        </Typography>
        <Typography variant="body1">
          Review transactions that need compliance review. You may select multiple transactions to review at once.
        </Typography>
      </Stack>
      <Card>
        <Box>
          <OfferTable
            rows={transactions || []}
            defaultRowsPerPage={10}
            title="Transactions needing review"
            defaultOrderBy="company_transaction_id"
            defaultOrder="desc"
            headers={[
              { key: 'company_transaction_id', label: 'ID' },
              {
                key: 'name',
                label: 'Name',
                customValue: (row) => <Button onClick={() => window.location = `/transactions/${row.id}/documents`}>{row.name}</Button>
              },
              {
                key: 'closing_date',
                label: 'Closing Date',
                customValue: (row) => row.closing_date ? formatDate(row.closing_date) : "Unknown"
              },
              {
                key: 'documents_in_compliance',
                label: 'Documents to review',
                customValue: (row) => <OfferPopover
                  anchor={<Button>{row.documents_in_compliance?.length} documents</Button>}
                >
                  <Stack>
                    {row.documents_in_compliance?.map((document) => (
                      <Button
                        onClick={() => handleReviewDocument(document)}
                      >
                        <Stack direction="row" spacing={2}>
                          <Typography>
                            {document.name}
                          </Typography>
                        </Stack>
                      </Button>
                    ))}
                  </Stack>
                </OfferPopover>
              },
              {
                key: 'transaction_type',
                label: 'Type',
                customValue: (row) => <Stack direction="row">
                  <TransactionTypeTag transactionType={row.transaction_type} />
                </Stack>
              },
              {
                key: 'transaction_status',
                label: 'Status',
                customValue: (row) => <Stack direction="row">
                  <TransactionStatusTag hideLabel transactionStatus={row.transaction_status} />
                </Stack>
              },
              { key: 'agent', label: 'Agent', customValue: (row) => row.agent?.full_name },
              { label: '', customValue: (row) => <Button variant="contained" size="small" onClick={() => handleStartReview([row.id])}>Review</Button>}
            ]}
            multiSelectAction={handleStartReview}
            multiSelectCtaText="Review"
            defaultSelected={selectedTransactions?.map((transaction) => transaction.id)}
          />
        </Box>
      </Card>
    </Box>
  )
}