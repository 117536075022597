import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CreateIcon from '@mui/icons-material/Create';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DrawIcon from '@mui/icons-material/Draw';
import SendIcon from '@mui/icons-material/Send';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Typography from '@mui/material/Typography';
import {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import useComplianceReviewStore from '../../shared/stores/ComplianceReviewStore';
import { useEffect } from 'react';
import Request from '../../shared/services/Request';
import { formatDateTime } from '../../shared/services/dates';

const ICON_MAPPING = {
  'document__replaced': <ContentCopyIcon />,
  'document__shared': <SendIcon />,
  'document__viewed': <VisibilityIcon />,
  'document__signed': <DrawIcon />,
  'document__signature_requested': <DrawIcon />,
  'document__submitted_for_review': <BookmarkIcon />
}

export default function History() {
  const { currentDocument } = useComplianceReviewStore();
  const [ auditLogs, setAuditLogs ] = React.useState([]);

  useEffect(() => {
    Request.get(`/v2/compliances/complianceables/${currentDocument.id}/audit_logs`, (response) => {
      console.log(response);
      setAuditLogs(response);
    })
  }, [currentDocument.id]);

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      {
        auditLogs.map((auditLog) => (
          <TimelineItem key={auditLog.id}>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              {formatDateTime(auditLog.created_at)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary" >
                {ICON_MAPPING[auditLog.key]}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                {auditLog.readable_name}
              </Typography>
              <Typography>{auditLog.name}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))
      }
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          {formatDateTime(currentDocument.created_at)}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot color="secondary">
            <CreateIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Document Created
          </Typography>
          <Typography>Document was added by {currentDocument.user.full_name}</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
