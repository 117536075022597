export const formatPhone = (value) => {
  value = value.replace(/\D/g, '');

  if (value.length > 3 && value.length <= 6) {
    value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
  } else if (value.length > 6) {
    value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
  } else if (value.length > 10) {
    value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
  }

  return value;
};
