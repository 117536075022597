import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const fields = this.element.querySelectorAll("input[type='text']");
    const nameField = fields[0];
    const value = nameField.value;

    nameField.focus();
    nameField.value = '';
    nameField.value = value;
  }
}
