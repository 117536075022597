export const SIGNATURES_FETCH_REQUESTED = 'signatures/signaturesFetchRequested';

// Index
export const signaturesFetchRequested = () => ({
  type: SIGNATURES_FETCH_REQUESTED
});

export const SIGNATURES_FETCHED = 'signatures/signaturesFetched';

export const signaturesFetched = (signatures) => ({
  type: SIGNATURES_FETCHED,
  payload: {
    signatures: signatures
  }
});

export const SIGNATURE_DOWNLOAD_REQUESTED =
  'signatures/signatureDownloadRequested';

export const signatureDownloadRequested = (signatureType, url) => ({
  type: SIGNATURE_DOWNLOAD_REQUESTED,
  payload: {
    signatureType: signatureType,
    url: url
  }
});

export const SIGNATURE_DOWNLOADED = 'signatures/signatureDownloaded';

export const signatureDownloaded = (signatureType, data) => ({
  type: SIGNATURE_DOWNLOADED,
  payload: {
    signatureType: signatureType,
    data: data
  }
});

export const CLEAR_SIGNATURES_STATE = 'signatures/clearSignaturesState';

export const SIGNATURES_FETCH_FAILED = 'signatures/signaturesFetchFailed';

export const signaturesFetchFailed = (errors) => ({
  type: SIGNATURES_FETCH_FAILED,
  payload: {
    errors: errors
  }
});
