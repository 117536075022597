import { Button, Card, CircularProgress, Dialog, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagIcon from '@mui/icons-material/Flag';

import useClientAppStore from '../../shared/stores/ClientAppStore';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import useEnsureAuth from '../../shared/hooks/ensureAuth';
import SignaturesForm from '../components/SignaturesForm';
import PageLoader from '../../shared/components/PageLoader';

export default function Review() {
  const [ pdfUrl, setPdfUrl ] = useState('');
  const [ pdfLoading, setPdfLoading ] = useState(false);
  const [ showSubmittedDialog, setShowSubmittedDialog ] = useState(false);
  const [ showSignatureForm, setShowSignatureForm ] = useState(false);
  const { user } = useEnsureAuth();
  const { currentTransaction, currentDocument, updateDocument } = useClientAppStore();
  const { documentId } = useParams();

  const navigate = useNavigate();

  const fetchPdfUrl = () => {
    setPdfLoading(true);
    Request.get(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}/preview`, (data) => {
      console.log(data)
      setPdfUrl(data.pdf_url)
      setPdfLoading(false)
    })
  }

  useEffect(fetchPdfUrl, [user, user['all_signatures?']])

  // useEffect(() => {
  //   if (!user['all_signatures?']) {
  //     setShowSignatureForm(true)
  //   }
  // }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleSubmit = () => {
    Request.post(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}/submit`, {}, (data) => {
      updateDocument(data)
      setShowSubmittedDialog(true)
    })
  }

  const handleSignaturesUpdate = ({ signatureFile, initialFile }) => {
    setPdfLoading(true)
    const formData = new FormData();
    formData.append('signature', signatureFile);
    formData.append('initial', initialFile);
    Request.post(`${getApiUrl()}/v2/client_apps/signatures`, formData, (data) => {
      setShowSignatureForm(false)
      sessionStorage.setItem('user', JSON.stringify(data.user))
      fetchPdfUrl()
    }, null, 'multipart/form-data')
  }

  if(pdfLoading) {
    return <PageLoader />
  }

  console.log(currentDocument)
  return (
    <Grid container spacing={2}>
      <Grid item sm={8}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="center">
            <Typography variant="h4">Review and Send</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Button variant="outlined" onClick={() => navigate(-1)}>Back to overview</Button>
            {currentDocument.document_status !== 'finished' && <Button variant="contained" onClick={handleSubmit}>Finalize and Sign</Button>}
          </Stack>
          <Stack display={{ xs: 'none', sm: 'flex' }}>
            <div style={{ width: '100%', height: '85vh' }} className='p-2'>
              <iframe src={pdfUrl} style={{ border: 0, height: '100%', width: '100%' }} />
            </div>
          </Stack>
        </Stack>
        <Dialog
          open={showSignatureForm}
          onClose={() => setShowSignatureForm(false)}
          maxWidth={{ xs: 'xs', sm: 'md', xl: 'xl' }}
        >
          <Stack p={2} width={{ xs: "100%", sm: "800px" }} bgcolor="background.default">
            <SignaturesForm onSubmit={handleSignaturesUpdate} />
          </Stack>
        </Dialog>
      </Grid>
      <Grid item sm={4} height="95vh" overflow="scroll">
        {currentDocument.document_status !== 'finished' && <Card sx={{ position: "sticky", top: "25px"}}>
          <Stack p={2} spacing={2}>
            <Typography variant="h5">Document Information</Typography>
            <Stack>
              <Typography variant="h6">{currentTransaction.name}</Typography>
              <Typography variant="h4">{currentDocument.name}</Typography>
            </Stack>
            {currentDocument.pdf_sections.map((section, index) => (
              <Stack
                key={section.id}
                direction="row"
                justifyContent="space-between"
                onClick={() => navigate(`/v2/client_apps/documents/${currentDocument.id}/${section.id}`)}
                sx={{
                  p: 0.5,
                  borderRadius: '3px',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'primaryLight.light',
                  }
                }}>
                <Stack>
                  <Typography variant="h6" m={0}>{section.title}</Typography>
                  <Stack direction="row" spacing={2}>
                    <Typography>{section.description}</Typography>
                    <Stack direction="row" spacing={0.5}>
                      <CircularProgress sx={{ my: 'auto !important' }} size="12px" thickness={8} variant='determinate' value={section.completed_fields / section.total_fields * 100} />
                      <Typography>{section.completed_fields}/{section.total_fields} questions answered</Typography>
                    </Stack>
                    {section.flagged_fields > 0 &&
                    <Stack direction="row" spacing={0.5}>
                      <FlagIcon sx={{ my: 'auto !important' }} color="warning" />
                      <Typography>{section.flagged_fields} questions flagged</Typography>
                    </Stack>
                    }
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Card>}
      </Grid>
      <Grid item sm={12}>
        <Stack direction="row">
          <Button onClick={() => setShowSignatureForm(true)}>
            Update Signature
          </Button>
        </Stack>
      </Grid>
      <Dialog open={showSubmittedDialog} onClose={() => setShowSubmittedDialog(false)}>
        <Stack p={2} bgcolor="background.default" spacing={3}>
          <Typography variant="h3">Your almost done!</Typography>
          <Typography variant="h5">You and any other signers will receive an email shortly to sign and submit this document to your agent.</Typography>
          <Stack direction="row">
            <Button variant="contained" onClick={() => navigate('/v2/client_apps')}>Close</Button>
          </Stack>
        </Stack>
      </Dialog>
    </Grid>
  )
}