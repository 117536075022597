import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { DebounceInput } from 'react-debounce-input';

export default function TextField(props) {
  return (
    <Form>
      <Form.Group className="mb-3 mx-3" controlId="formTextField">
        <h5>Text</h5>
        <DebounceInput
          element="textarea"
          onChange={(e) => props.onChange(e.target.value)}
          debounceTimeout={500}
          value={props.value}
          style={{
            borderRadius: 5,
            fontSize: 22,
            borderColor: '#a6a8ab',
            paddingTop: 20,
            paddingBottom: 20
          }}
          placeholder={'Type your text here'}
        />
        <br />
        <br />
        <Button
          size="sm"
          variant="danger"
          style={{ marginTop: 200 }}
          onClick={props.onDelete}
        >
          Delete
        </Button>
      </Form.Group>
    </Form>
  );
}
