import React, { useState } from 'react';
import {Box, FormLabel, MenuItem, TextField} from '@mui/material';
import DateTimeField from '../pdf_annotator/DateTimeField';
import SignaturesField from './signaturesField';
import {
  createPdfFieldForPdfTemplate,
  getApiBaseUrl,
  getApiUrl,
  getHeaders,
  updatePdfFieldForPdfTemplate
} from '../shared/Network';
import { connect, useDispatch, useSelector } from 'react-redux';
import { newFieldCopy } from '../shared/pdf_field_utils';

export default function AddendumToField(props) {
  const pdfTemplates = useSelector((state) => state.pdfTemplates.pdfTemplates);
  const pdfTemplate = pdfTemplates.filter((pdfTemplate) => pdfTemplate.id === props.pdfTemplate.id)[0];
  const [ addendedPdfTemplateId, setAddendedPdfTemplateId ] = useState(pdfTemplate.addended_pdf_template_id);

  console.log(pdfTemplate)
  console.log(pdfTemplates)

  function getPdfTemplateOptions(){
    let sortedPdfTemplates = [...pdfTemplates];
    sortedPdfTemplates.sort((t1, t2) => {
      if (t1.name < t2.name) {
        return -1;
      }
      if (t2.name > t1.name) {
        return 1;
      }
      return 0;
    });

    let options = [];
    options.push(
      <MenuItem value={-1} key={-1}>
        Not an addendum
      </MenuItem>
    );
    {
      sortedPdfTemplates.forEach((pdfTemplate) => {
        options.push(
          <MenuItem key={pdfTemplate.id} value={pdfTemplate.id}>
            {pdfTemplate.name}
          </MenuItem>
        );
      });
    }
    return options;
  }

  const handleUpdate = (addendedToTemplateId) => {
    const url = getApiBaseUrl() + '/pdf_templates/' + props.pdfTemplate.id
    fetch(
      url,
      {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify({
          addended_pdf_template_id: addendedToTemplateId
        })
      }
    )
  }

  console.log(addendedPdfTemplateId)

  return (
    <Box>
      <FormLabel>Addendum to</FormLabel>
      <Box>
        <TextField
          select
          size="small"
          value={addendedPdfTemplateId}
          placeholder='Select a pdf template'
          onChange={(e) => {
            let value = e.target.value;
            setAddendedPdfTemplateId(value);
            if (value !== '') {
              handleUpdate(value)
            }
          }}
        >
          {getPdfTemplateOptions()}
        </TextField>
      </Box>
    </Box>
  );
}

