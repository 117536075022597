export const validCoords = (coords) => {
  let arr;

  try {
    arr = coordsToArray(this.state.coords);
  } catch (exception) {
    console.log(
      'Unable to parse coords, not updating field: ' + exception.message
    );
    return false;
  }

  // Not valid array
  if (arr.length !== 4) {
    return false;
  }

  return true;
};

export const coordsToArray = (coords) => {
  let arr = coords;
  if (Array.isArray(arr)) {
    return arr;
  } else if (typeof arr === 'string') {
    arr = coords.split(',');
    arr = arr.map((item) => parseInt(item));
    return arr;
  } else {
    throw 'Invalid array type format';
  }
};

export const fieldsToCoordinates = (json) => {
  let coordinates = [];

  json.forEach((field) => {
    switch (field['field_type']) {
      case 'text':
        coordinates.push({
          id: field['id'].toString(),
          external_id: field['external_id'],
          name: field['name'],
          coords: field['field']['coords'],
          fieldType: 'text',
          pdfSectionId: field['pdf_section_id']
        });
        break;
      default:
        console.log('Type not implemented yet: ' + field['field_type']);
    }
  });

  return coordinates;
};

export const updateCoord = (field, data) => {
  // Nothing to update here
  if (data.coords == null) {
    return;
  }

  switch (field.field_type) {
    case 'text':
    case 'text_area':
    case 'datetime':
      field.field.coords = data.coords;
      field.field.page = data.page;
      break;
    case 'radio':
    case 'checkbox':
      if (data.coordKey === 'text') {
        field.field.options[data.coordIndex]['text_field']['page'] = data.page;
        field.field.options[data.coordIndex]['text_field']['coords'] =
          data.coords;
      } else {
        field.field.options[data.coordIndex]['page'] = data.page;
        field.field.options[data.coordIndex]['coords'] = data.coords;
      }
      break;
    case 'composite':
      field.field.elements[data.coordIndex]['page'] = data.page;
      field.field.elements[data.coordIndex]['coords'] = data.coords;
      break;
    case 'signature':
      if (data.field_type === 'signature') {
        field.field.signatures[data.coordIndex]['page'] = data.page;
        field.field.signatures[data.coordIndex][data.coordKey] = data.coords;
      } else if (data.field_type === 'initial') {
        field.field.initials[data.coordIndex]['page'] = data.page;
        field.field.initials[data.coordIndex][data.coordKey] = data.coords;
      }
      break;
  }
};

export const newTextField = (pdfDocumentId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_document_id: pdfDocumentId,
    name: '',
    field_type: 'text',
    field: {
      type: 'text',
      key: '',
      title: 'Field ' + external_id,
      placeholder: '',
      header_text: '',
      header_icon: '',
      is_detail: false,
      coords: [],
      page: 1,
      value: '',
      context: '',
      mls_field: '',
      hidden: false
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '52'
  };
};

export const newRadioField = (pdfDocumentId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_document_id: pdfDocumentId,
    name: '',
    field_type: 'radio',
    field: {
      type: 'radio',
      key: '',
      title: 'Field ' + external_id,
      options: [],
      value: ''
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '53'
  };
};

export const newCheckboxField = (pdfDocumentId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_document_id: pdfDocumentId,
    name: '',
    field_type: 'checkbox',
    field: {
      type: 'checkbox',
      key: '',
      title: 'Field ' + external_id,
      options: [],
      value: ''
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: ''
  };
};

export const newDateTimeField = (pdfDocumentId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_document_id: pdfDocumentId,
    name: '',
    field_type: 'datetime',
    field: {
      type: 'datetime',
      key: '',
      title: 'Field ' + external_id,
      mode: 'date',
      header_text: '',
      header_icon: '',
      is_detail: false,
      format: '',
      coords: [],
      page: 1,
      context: ''
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '54'
  };
};

export const newCompositeField = (pdfDocumentId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_document_id: pdfDocumentId,
    name: '',
    field_type: 'composite',
    field: {
      type: 'composite',
      key: '',
      title: 'Field ' + external_id,
      value: '',
      field: null,
      elements: []
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '50'
  };
};

export const newSignatureField = (pdfDocumentId, external_id, type) => {
  return {
    id: null,
    external_id: null,
    pdf_document_id: pdfDocumentId,
    name: 'signer1',
    field_type: type,
    field: {
      type: type,
      title: 'Field ' + external_id,
      signer: '',
      key: 'signer1',
      signer_id: 'signer1',
      required: true,
      signatures: [],
      initials: [],
      hidden: false
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '55'
  };
};

export const getFieldCoords = (field) => {
  let coords = [];

  switch (field.field_type) {
    case 'text_area':
      let fieldCoords = Object.assign([], field.field.coords);
      let textAreaCoords = {
        coords: fieldCoords,
        page: field.field.page,
        lineHeight: field.field.line_height
      };
      coords.push(textAreaCoords);
      break;
    case 'text':
    case 'datetime':
      let result = Object.assign([], field.field.coords);
      let coord = { coords: result, page: field.field.page };
      coords.push(coord);
      break;
    case 'radio':
    case 'checkbox':
      let fieldOptions = Object.assign([], field.field.options);

      fieldOptions.forEach((option) => {
        if (option.coords != null) {
          coords.push({
            coords: Object.assign([], option.coords),
            page: option.page
          });
        }
        if (option.text_field != null) {
          coords.push({
            coords: Object.assign([], option.text_field.coords),
            page: option.text_field.page
          });
        }
      });
      break;
    case 'composite':
      let elements = Object.assign([], field.field.elements);

      elements.forEach((element) => {
        if (element.coords != null) {
          coords.push({
            coords: Object.assign([], element.coords),
            page: element.page
          });
        }
      });
      break;
    case 'signature':
      if (field.field.signatures != null) {
        let signatures = Object.assign([], field.field.signatures);

        signatures.forEach((signature) => {
          coords.push({
            coords: Object.assign([], signature.coords),
            pdf_coords: Object.assign([], signature.pdf_coords),
            page: signature.page
          });
          if (signature.date != null) {
            coords.push({
              coords: Object.assign([], signature.date),
              pdf_date_coords: Object.assign([], signature.pdf_date_coords),
              page: signature.page
            });
          }
        });
      }
      if (field.field.initials != null) {
        let initials = Object.assign([], field.field.initials);

        initials.forEach((initial) => {
          coords.push({
            coords: Object.assign([], initial.coords),
            pdf_coords: Object.assign([], initial.pdf_coords),
            page: initial.page
          });
          if (initial.date != null) {
            coords.push({
              coords: Object.assign([], initial.date),
              pdf_date_coords: Object.assign([], initial.pdf_date_coords),
              page: initial.page
            });
          }
        });
      }
      break;
  }
  return coords;
};

export const getFieldFromCoord = (fields, x, y, page) => {
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    switch (field.field_type) {
      case 'text_area':
        if (
          field.field.page === page.page &&
          fieldContainsCoord(field.field.coords, x, y)
        ) {
          return {
            id: field.id,
            external_id: field.external_id,
            field_type: field.field_type,
            coords: field.field.coords,
            page: field.field.page,
            lineHeight: field.field.line_height,
            coordIndex: null,
            coordKey: null
          };
        }
        break;
      case 'text':
      case 'datetime':
        if (
          field.field.page === page.page &&
          fieldContainsCoord(field.field.coords, x, y)
        ) {
          return {
            id: field.id,
            external_id: field.external_id,
            field_type: field.field_type,
            coords: field.field.coords,
            page: field.field.page,
            coordIndex: null,
            coordKey: null
          };
        }
        break;
      case 'radio':
      case 'checkbox':
        let fieldOptions = field.field.options;

        for (let j = 0; j < fieldOptions.length; j++) {
          let option = fieldOptions[j];
          if (option.text_field != null) {
            let textField = option.text_field;
            if (
              textField.page === page.page &&
              fieldContainsCoord(textField.coords, x, y)
            ) {
              return {
                id: field.id,
                external_id: field.external_id,
                field_type: field.field_type,
                coords: textField.coords,
                page: textField.page,
                coordIndex: j,
                coordKey: 'text'
              };
            }
          }

          if (option.coords != null) {
            if (
              option.page === page.page &&
              fieldContainsCoord(option.coords, x, y)
            ) {
              return {
                id: field.id,
                external_id: field.external_id,
                field_type: field.field_type,
                coords: option.coords,
                page: option.page,
                coordIndex: j,
                coordKey: option.value
              };
            }
          }
        }

        break;
      case 'composite':
        let elements = field.field.elements;

        for (let j = 0; j < elements.length; j++) {
          let element = elements[j];
          if (
            element.page === page.page &&
            fieldContainsCoord(element.coords, x, y)
          ) {
            return {
              id: field.id,
              external_id: field.external_id,
              field_type: 'composite',
              coords: element.coords,
              page: element.page,
              coordIndex: j,
              coordKey: element.pattern
            };
          }
        }
        break;
      case 'signature':
        let signatures = field.field.signatures || [];
        let initials = field.field.initials || [];

        for (let j = 0; j < signatures.length; j++) {
          let signature = field.field.signatures[j];
          if (
            signature.page === page.page &&
            fieldContainsCoord(signature.coords, x, y)
          ) {
            return {
              id: field.id,
              external_id: field.external_id,
              field_type: 'signature',
              coords: signature.coords,
              pdf_coords: signature.pdf_coords,
              page: signature.page,
              coordIndex: j,
              coordKey: 'coords'
            };
          }
          if (
            signature.date != null &&
            signature.page === page.page &&
            fieldContainsCoord(signature.date, x, y)
          ) {
            return {
              id: field.id,
              external_id: field.external_id,
              field_type: 'signature',
              coords: signature.date,
              pdf_date_coords: signature.pdf_date_coords,
              page: signature.page,
              coordIndex: j,
              coordKey: 'date'
            };
          }
        }

        for (let k = 0; k < initials.length; k++) {
          let initial = field.field.initials[k];
          if (
            initial.page === page.page &&
            fieldContainsCoord(initial.coords, x, y)
          ) {
            return {
              id: field.id,
              external_id: field.external_id,
              field_type: 'initial',
              coords: initial.coords,
              pdf_coords: initial.pdf_coords,
              page: initial.page,
              coordIndex: k,
              coordKey: 'coords'
            };
          }
          if (
            initial.date != null &&
            initial.page === page.page &&
            fieldContainsCoord(initial.date, x, y)
          ) {
            return {
              id: field.id,
              external_id: field.external_id,
              field_type: 'initial',
              coords: initial.date,
              pdf_date_coords: initial.pdf_date_coords,
              page: initial.page,
              coordIndex: k,
              coordKey: 'date'
            };
          }
        }

        break;
    }
  }
};

const fieldContainsCoord = (c, x, y) => {
  return c[0] <= x && c[0] + c[2] >= x && c[1] <= y && c[1] + c[3] >= y;
};

export const fieldNameFromKey = (key) => {
  return key.match(/\[(.*)\]/)[1];
};

export const fieldKeyFromName = (fieldName, name) => {
  return fieldName + '[' + name + ']';
};

export const getTextFieldNamesFromFields = (fields) => {
  let fieldNames = [];

  fields.forEach((field) => {
    switch (field.field_type) {
      case 'signature':
        if (field.field.fields != null) {
          fieldNames = fieldNames.concat(field.field.fields.map((f) => f.key));
        }
        break;
      default:
        fieldNames.push(field.field.key);
        break;
    }
  });

  return fieldNames;
};
