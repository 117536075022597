import React from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Button } from 'react-bootstrap';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default class PdfModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.iframeRef = React.createRef();
  }

  base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      'data:application/pdf;base64,'.length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  componentDidUpdate(prevProps) {
    if (!this.props.rawPdf || !this.iframeRef.current) return;
    let pdfBlob = this.base64toBlob(this.props.rawPdf);
    const objUrl = URL.createObjectURL(pdfBlob);
    this.iframeRef.current.setAttribute('src', objUrl);
    URL.revokeObjectURL(objUrl);
  }

  render() {
    return (
      <div show={true} onHide={this.props.close} style={this.props.style}>
        <div>
          <p>Pdf Preview</p>
          <div onClick={this.props.close}>X</div>
        </div>
        <div>
          <iframe
            ref={this.iframeRef}
            style={{ height: '800px', width: '100%' }}
          ></iframe>
        </div>
        <div>
          <Button variant="primary" onClick={this.props.close}>
            Close
          </Button>
        </div>
      </div>
    );
  }
}
