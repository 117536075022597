import React from 'react';

import { createBrowserRouter } from "react-router-dom";
import Root from './pages/root';
import Home from './pages/Home';
import Settings from './pages/Settings';
import Logout from './pages/Logout';
import Document from './pages/Document';
import Section from './pages/Section';
import Review from './pages/Review';

const router = createBrowserRouter([
  {
    path: "/v2/client_apps",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "settings",
        element: <Settings />
      },
      {
        path: "documents/:documentId",
        element: <Document />
      },
      {
        path: "documents/:documentId/review",
        element: <Review />
      },
      {
        path: "documents/:documentId/:sectionId",
        element: <Section />
      },
      {
        path: "logout",
        element: <Logout />
      }
    ]
  }
]);

export default router;