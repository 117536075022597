import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


export const newFieldCopy = (pdfField, curPdfTemplateId) => {
  let externalId = uuidv4();
  let field = {...pdfField, field: {...pdfField.field}}

  field.id = 'u_' + externalId;
  field.external_id = 'u_' + externalId;
  field.pdf_template_id = curPdfTemplateId;
  field.pdf_section_id = null;

  return field;
}

export const newField = (pdfTemplate, type) => {
  let field = null;
  let external_id = uuidv4();
  switch (type) {
    case 'text_area':
      field = newTextField(pdfTemplate.id, external_id);
      field.field_type = type;
      field.id = 'u_' + external_id;
      field.external_id = 'u_' + external_id;
      field.name = 'field' + external_id;
      field.field.key = field.id;
      field.field.type = type;
      field.field.line_height = 18;
      break;
      break;
    case 'text':
      field = newTextField(pdfTemplate.id, external_id);
      field.field_type = type;
      field.id = 'u_' + external_id;
      field.external_id = 'u_' + external_id;
      field.name = 'field' + external_id;
      field.field.key = field.id;
      field.field.type = type;
      break;
    case 'datetime':
      field = newDateTimeField(pdfTemplate.id, external_id);
      field.id = 'u_' + external_id;
      field.external_id = 'u_' + external_id;
      field.name = 'date' + external_id;
      field.field.key = field.id;
      break;
    case 'radio':
      field = newRadioField(pdfTemplate.id, external_id);
      field.id = 'u_' + external_id;
      field.external_id = 'u_' + external_id;
      field.name = 'field' + external_id;
      field.field.key = field.id;
      field.field.options.push({ label: 'option0', value: 'value0' });
      break;
    case 'checkbox':
      field = newCheckboxField(pdfTemplate.id, external_id);
      field.id = 'u_' + external_id;
      field.external_id = 'u_' + external_id;
      field.name = 'field' + external_id;
      field.field.key = field.id;
      field.field.options.push({ label: 'Option0', value: 'option0' });
      break;
    case 'composite':
      field = newCompositeField(pdfTemplate.id, external_id);
      field.id = 'u_' + external_id;
      field.external_id = 'u_' + external_id;
      field.name = 'composite' + external_id;
      field.field.key = field.id;
      field.field.template = 'hello $string';
      field.field.elements.push({ replace: '$var', pattern: '(.*)' });
      break;
    case 'signature':
    case 'initials':
    case 'date_signed':
      field = newSignatureField(pdfTemplate.id, external_id, type);
      field.id = 'u_' + external_id;
      field.external_id = 'u_' + external_id;
      field.name = 'signer1';
      field.key = field.id;
      field.field.signer = 'signer1';
      field.field.title = 'Signer 1';
      break;
    case 'signatures':
      field = newSignaturesField(external_id, type);
      field.id = 'u_' + external_id;
      field.external_id = 'u_' + external_id;
      break;
  }

  return field;
};

export const newTextField = (pdfTemplateId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_template_id: pdfTemplateId,
    name: '',
    field_type: 'text',
    field: {
      type: 'text',
      key: '',
      title: 'Field ' + external_id,
      placeholder: '',
      header_text: '',
      header_icon: '',
      is_detail: false,
      coords: [],
      page: 1,
      value: '',
      context: '',
      mls_field: '',
      hidden: false
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '52'
  };
};

export const newRadioField = (pdfTemplateId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_template_id: pdfTemplateId,
    name: '',
    field_type: 'radio',
    field: {
      type: 'radio',
      key: '',
      title: 'Field ' + external_id,
      options: [],
      value: ''
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '53'
  };
};

export const newCheckboxField = (pdfTemplateId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_template_id: pdfTemplateId,
    name: '',
    field_type: 'checkbox',
    field: {
      type: 'checkbox',
      key: '',
      title: 'Field ' + external_id,
      options: [],
      value: ''
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: ''
  };
};

export const newDateTimeField = (pdfTemplateId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_template_id: pdfTemplateId,
    name: '',
    field_type: 'datetime',
    field: {
      type: 'datetime',
      key: '',
      title: 'Field ' + external_id,
      mode: 'date',
      header_text: '',
      header_icon: '',
      is_detail: false,
      format: '',
      coords: [],
      page: 1,
      context: ''
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '54'
  };
};

export const newCompositeField = (pdfTemplateId, external_id) => {
  return {
    id: null,
    external_id: null,
    pdf_template_id: pdfTemplateId,
    name: '',
    field_type: 'composite',
    field: {
      type: 'composite',
      key: '',
      title: 'Field ' + external_id,
      value: '',
      field: null,
      elements: []
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '50'
  };
};

export const newSignatureField = (pdfTemplateId, external_id, type) => {
  return {
    id: null,
    external_id: null,
    pdf_template_id: pdfTemplateId,
    name: 'signer1',
    field_type: type,
    field: {
      type: type,
      title: 'Field ' + external_id,
      signer: '',
      key: 'signer1',
      signer_id: 'signer1',
      required: true,
      signatures: [],
      initials: [],
      hidden: false
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '55'
  };
};

export const newSignaturesField = (external_id, type) => {
  return {
    id: null,
    external_id: null,
    name: 'signers',
    field_type: type,
    field: {
      type: type,
      title: 'Signatures ' + external_id,
      signature_type: 'signature',
      signers: [],
      repeat_direction: 'up',
      repeat_all_pages: false,
      height: '20',
      key: 'signers'
    },
    pdf_section_id: null,
    related_pdf_document_id: '',
    order: '60'
  };
};

export const downloadPdfJson = (pdfTemplate, pdfFields, empty) => {
  let fields = {};

  if (!empty) {
    pdfFields.forEach((field) => {
      switch (field.field_type) {
        case 'text':
        case 'textarea':
          fields[field.external_id] = btoa(Math.random().toString()).substr(
            10,
            20
          );
          break;
        case 'datetime':
          fields[field.external_id] = moment().format('L');
          break;
        case 'checkbox':
        case 'radio':
          fields[field.external_id] = {};

          field.field.options.forEach((option) => {
            if (option.text_field) {
              let checked = option.text_field.checkbox;
              let str = btoa(Math.random().toString()).substr(10, 10);
              fields[field.external_id][option.value] = {
                value: str,
                checked: checked
              };
            } else {
              fields[field.external_id][option.value] = {
                value: option.value,
                checked: true
              };
            }
          });
          break;
        case 'composite':
          fields[field.external_id] = {};

          field.field.elements.forEach((element) => {
            let str = '';
            if (element.replace === '$month_day') {
              str = moment().format('MMMM DD');
            } else if (element.replace === '$month') {
              str = moment().format('MMMM');
            } else if (element.replace === '$day') {
              str = moment().format('DD');
            } else if (element.replace === '$year') {
              str = moment().format('YYYY');
            } else if (element.replace === '$time') {
              str = moment().format('HH:mm');
            } else if (element.replace === '$am_pm') {
              str = moment().format('A');
            }

            fields[field.external_id][element.replace] = {
              value: str,
              checked: false
            };
          });
          break;
        default:
          console.log('Not pre-filling data for: ' + field.field_type);
          break;
      }
    });
  }

  return {
    name: pdfTemplate.document,
    pdf_document_id: pdfTemplate.id,
    user_id: 'test',
    document_id: pdfTemplate.id,
    signature_ids: Object.keys(pdfTemplate.signatures),
    field_values: fields,
    html_template: btoa(unescape(encodeURIComponent(pdfTemplate.html_template)))
  };
};
