import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  download(event) {
    event.preventDefault();

    const url = this.element.dataset.url;
    const filename = this.element.dataset.filename;

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  }
}