import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/root';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/root';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware)
});

sagaMiddleware.run(rootSaga);
