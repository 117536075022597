import React from 'react';
import pretty from 'pretty';

import { useState, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import FieldModal from './field_modal';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';
import {
  downloadPdf,
  updatePdfTemplateRequest,
  pdfTemplateFieldRequested,
  clearPdfField
} from './redux/actions/pdfTemplateActions';
import { downloadPdfJson } from '../shared/pdf_field_utils';
import PdfModal from './pdf_modal';

export default function HtmlPage(props) {
  const [html, setHtml] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [element, setElement] = useState(null);
  const [field, setField] = useState(null);
  const [fieldWidth, setFieldWidth] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const pdfTemplate = useSelector((state) => state.pdfTemplates.pdfTemplate);
  const pdfFields = useSelector((state) => state.pdfTemplates.pdfFields);
  const pdfField = useSelector((state) => state.pdfTemplates.pdfField);
  const rawPdf = useSelector((state) => state.pdfTemplates.rawPdf);
  const htmlContainer = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setHtml(parseHtmlTemplate(props.htmlTemplate));
  }, []);

  useEffect(() => {
    if (pdfField) {
      setShowModal(true);
    }
  }, [pdfField]);

  const prevElement = useRef();

  useEffect(() => {
    prevElement.current = element;

    if (element) {
      element.classList.add('active_field');
    }
  }, [element]);

  function findFieldOrContainer(element) {
    while (element) {
      if (
        element.classList.contains('new_field') ||
        element.classList.contains('field') ||
        element.classList.contains('field_container') ||
        element.classList.contains('ignored')
      ) {
        return element;
      }
      element = element.parentElement;
    }
    return null;
  }

  const handleClick = (e) => {
    let htmlElement = findFieldOrContainer(e.target);
    console.log(e.target)

    if (htmlElement === null || htmlElement === undefined) {
      resetField();
      if (prevElement.current) {
        prevElement.current.classList.remove('active_field');
      }
      console.log('Nothing clicked');
      return;
    }

    if (htmlElement.className === 'new_field') {
      if (htmlElement.size) {
        setFieldWidth(htmlElement.size);
      } else {
        setFieldWidth(htmlElement.style['min-width']);
      }

      setElement(htmlElement);
      setShowModal(true);
      setField(null);
      // dispatch(clearPdfField()); // Can we remove this?
      if (prevElement.current) {
        prevElement.current.classList.remove('active_field');
      }

      console.log('clicked new_field');
    } else if (
      htmlElement.classList.contains('field') ||
      htmlElement.classList.contains('field_container')
    ) {
      let pdfFieldId = null;
      let htmlField = null;
      let width = htmlElement.size
        ? htmlElement.size
        : htmlElement.style['min-width'];

      console.log('clicked field');

      // Find the id in the element, parent or grandparent

      if (htmlElement.tagName === 'INPUT' && htmlElement.name) {
        htmlField = htmlElement;
        pdfFieldId = htmlElement.name;
      } else if (htmlElement.id) {
        htmlField = htmlElement;
        pdfFieldId = htmlElement.id;
      } else if (htmlElement.parentNode.id) {
        htmlField = htmlElement.parentNode;
        pdfFieldId = htmlElement.parentNode.id;
      } else if (htmlElement.parentNode.parentNode.id) {
        htmlField = htmlElement.parentNode.parentNode;
        pdfFieldId = htmlElement.parentNode.parentNode.id;
      }

      if (pdfFieldId) {
        setElement(htmlField);
        setField(htmlField);
        setFieldWidth(width);

        //lookup field id by the external_id
        let foundField = pdfFields.find(
          (field) => field.external_id == pdfFieldId
        );

        dispatch(pdfTemplateFieldRequested(pdfTemplate.id, foundField.id));
        if (prevElement.current) {
          prevElement.current.classList.remove('active_field');
        }
      }
    } else if (e.target.className === 'ignored') {
      if (prevElement.current) {
        prevElement.current.classList.remove('active_field');
      }
      resetField();
      console.log('clicked ignored');
    }
  };

  const parseHtmlTemplate = (htmlTemplate) => {
    let parsedHtml = ReactHtmlParser(htmlTemplate, {
      transform: (node, index) => {
        // find fields not annotated yet
        if (
          node.name === 'span' &&
          node.attribs.style &&
          node.attribs.style.includes('min-width')
        ) {
          // check parent nodes if this is an existing field (span.field_container) or new field
          if (
            (node.parent.attribs.class &&
              node.parent.attribs.class.includes('field_container')) ||
            (node.parent.parent.attribs.class &&
              node.parent.parent.attribs.class.includes('field_container'))
          ) {
            console.log('existing field found');
          } else {
            node.attribs.class = 'new_field';
            console.log('Found field: ');
            console.log(node);
          }
        }

        if (node.name === 'input') {
          if (node.attribs.name) {
            console.log('Found signature field');
          } else {
            node.attribs.class = 'new_field';
          }
        }
      }
    });
    return parsedHtml;
  };

  const toolbar = () => {
    return (
      <div id="top-box">
        <button
          onClick={() => {
            // convert html to string
            // let htmlString = ReactDOMServer.renderToString(html);

            // Remove active_field class if present
            let innerHTML = htmlContainer.current.innerHTML;
            innerHTML = innerHTML.replace(/\s*active_field\s*/g, '');

            let htmlString = pretty(innerHTML, {
              ocd: true
            });
            dispatch(
              updatePdfTemplateRequest(pdfTemplate.id, htmlString, () => {
                setUnsavedChanges(false);
              })
            );
          }}
          disabled={!unsavedChanges}
        >
          Save PdfTemplate
        </button>
        <button
          onClick={() => {
            setShowPdfModal(true);
            dispatch(
              downloadPdf(downloadPdfJson(pdfTemplate, pdfFields, true))
            );
          }}
        >
          Generate Test PDF
        </button>
        <button
          onClick={() => {
            setShowPdfModal(true);
            dispatch(
              downloadPdf(downloadPdfJson(pdfTemplate, pdfFields, false))
            );
          }}
        >
          Generate Test PDF (With Test Data)
        </button>
        <button
          onClick={(e) => {
            props.close();
            e.preventDefault();
          }}
        >
          Close
        </button>
      </div>
    );
  };

  const resetField = () => {
    setElement(null);
    setField(null);
    setShowModal(false);
  };

  const replaceElement = (replace) => {
    let template = document.createElement('template');
    replace = replace.trim();
    template.innerHTML = replace;
    element.parentElement.replaceChild(template.content.firstChild, element);
    setUnsavedChanges(true);
  };

  return (
    <div style={{ display: 'flex'}}>
      {showPdfModal ? (
        <PdfModal
          close={() => {
            setShowPdfModal(false);
          }}
          rawPdf={rawPdf}
          style={{
            position: 'absolute',
            zIndex: 10001,
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            backgroundColor: '#FFF'
          }}
        />
      ) : null}
      <FieldModal
        close={resetField}
        replace={replaceElement}
        field={field}
        fieldWidth={fieldWidth}
        style={{
          zIndex: 10000,
          height: '100%',
          width: '30%',
          overflow: 'scroll'
        }}
      />
      <div id="right-column">
        {toolbar()}
        <div
          onClick={handleClick}
          ref={htmlContainer}>
          {html}
        </div>
      </div>
    </div>
  );
}
