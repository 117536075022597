import { Box, Button, Card, Dialog, Grid, Skeleton, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import useContactsStore from '../../shared/stores/ContactsStore';
import ContactCard from '../components/ContactCard';
import DrawIcon from '@mui/icons-material/Draw';
import SendIcon from '@mui/icons-material/Send';
import { formatDate, formatDateTime } from '../../shared/services/dates';
import OfferTable from '../../shared/components/OfferTable';
import TransactionTypeTag from '../../shared/components/TransactionTypeTag';
import TransactionStatusTag from '../../shared/components/TransactionStatusTag';
import DocumentsList from '../components/DocumentsList';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TimelineItem from '@mui/lab/TimelineItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useFeatureFlagEnabled } from 'posthog-js/react'


import {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Timeline from '@mui/lab/Timeline';
import Touchpoint from '../components/Touchpoint';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ICON_MAPPING = {
  'instagram': <InstagramIcon />,
  'email': <EmailIcon />,
  'sms': <SmsIcon />,
}

export default function ViewContact() {
  const [ activeTab, setActiveTab ] = React.useState(0);
  const [ showTouchpoint, setShowTouchpoint ] = React.useState(null);
  const [ contact, setContact ] = useState(null);
  const [ transactions, setTransactions ] = useState(null);
  const { updateContact } = useContactsStore();
  const { id } = useParams();
  const navigate = useNavigate();

  const flagEnabled = useFeatureFlagEnabled('contact-touchpoints')

  const touchpoints = [
    {
      readable_name: "Email",
      name: "Email outreach to contact",
      scheduled_at: "2024-11-04T10:00:00Z",
      key: "email",
      subject: "How's the new house?",
      body: `${contact?.full_name}, \n I hope you're enjoying your new home. I wanted to check in and see how everything is going. Let me know if you need anything!`,
      automatic: true
    },
    {
      readable_name: "Instagram",
      name: "Document was shared with the contact",
      action: "Comment on an Instagram post",
      scheduled_at: "2024-12-04T10:00:00Z",
      key: "instagram",
    },
    {
      readable_name: "SMS Message",
      name: `Hey, it’s about the cold outside. I thought I’d check in and remind you to get your sprinklers cleared out. Let me know if you need a contact to flush them. I have a few sprinkler experts that I like.`,
      scheduled_at: "2025-01-04T10:00:00Z",
      body: `Hey, it’s about the cold outside. I thought I’d check in and remind you to get your sprinklers cleared out. Let me know if you need a contact to flush them. I have a few sprinkler experts that I like.`,
      key: "sms",
      automatic: true
    }
  ]

  useEffect(() => {
    Request.get(getApiUrl() + '/v2/contacts/' + id, (data) => {
      updateContact(data);
      setContact(data);
    })
  }, [])

  useEffect(() => {
    Request.get(getApiUrl() + '/v2/contacts/' + id + '/transactions', (data) => {
      setTransactions(data);
      console.log(data);
    })
  }, [])

  const handleSave = () => {
    Request.patch(getApiUrl() + `/v2/contacts/${contact.id}`, { contact }, (response) => {
      updateContact(response);
      setContact(response);
    })
  }

  return (
    <Stack>
      <Stack mb={1}>
        <Button onClick={() => navigate("/v2/contacts")}>Back to Contacts</Button>
      </Stack>
      <Grid container spacing={2}>
        <Grid item sm={8} spacing={2}>
          <Stack spacing={2}>
            <Card>
              <Stack p={2}>
                <DocumentsList contact={contact} />
              </Stack>
            </Card>
            <Card>
              <Stack>
                <Stack p={2}>
                  <Typography variant="h6">Recent Transactions</Typography>
                </Stack>
                {transactions?.length > 0 ? <OfferTable
                  rows={transactions || []}
                  defaultRowsPerPage={10}
                  defaultOrderBy="company_transaction_id"
                  defaultOrder="desc"
                  headers={[
                    { key: 'company_transaction_id', label: 'ID' },
                    {
                      key: 'name',
                      label: 'Name',
                      customValue: (row) => <Button onClick={() => window.location = `/transactions/${row.id}/documents`}>{row.name}</Button>
                    },
                    {
                      key: 'closing_date',
                      label: 'Closing Date',
                      customValue: (row) => row.closing_date ? formatDate(row.closing_date) : "Unknown"
                    },
                    {
                      key: 'transaction_type',
                      label: 'Type',
                      customValue: (row) => <Stack direction="row">
                        <TransactionTypeTag transactionType={row.transaction_type} />
                      </Stack>
                    },
                    {
                      key: 'transaction_status',
                      label: 'Status',
                      customValue: (row) => <Stack direction="row">
                        <TransactionStatusTag hideLabel transactionStatus={row.transaction_status} />
                      </Stack>
                    }
                  ]}
                /> :
                <Skeleton variant="rectangular" height={300} />}
              </Stack>
            </Card>
          </Stack>
        </Grid>
        <Grid item sm={4}>
          <Stack spacing={2}>
            {contact ? <Stack>
              <Card>
                <Stack p={1}>
                  <ContactCard contact={contact} afterUpdate={(contact) => setContact(contact)} maxWidth={"100%"} />
                </Stack>
                <Stack p={1}>
                  <TextField
                    label="Notes"
                    multiline
                    fullWidth
                    value={contact.manual_bio}
                    onChange={(e) => setContact({ ...contact, manual_bio: e.target.value })}
                    InputProps={{
                      endAdornment: (
                        <Button size="small" onClick={handleSave} variant="contained" color="primary">
                          Save
                        </Button>
                      )
                    }}
                  />
                </Stack>
              </Card>
            </Stack>
            :
            <Skeleton variant="rectangular" height={300} />}
            {flagEnabled && <Stack>
              <Card>
                <Stack p={2}>
                  <Stack>
                    <Typography variant="h6">Upcoming touchpoints</Typography>
                  </Stack>
                  <Timeline
                    sx={{
                      [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.4,
                      },
                    }}
                  >
                    {
                      touchpoints.map((touchpoint) => (
                        <TimelineItem key={touchpoint.id}>
                          <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                          >
                            {formatDate(touchpoint.scheduled_at)}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              color="primary"
                              onClick={() => setShowTouchpoint(touchpoint)}
                              sx={{
                                cursor: "pointer"
                              }}
                            >
                              {ICON_MAPPING[touchpoint.key]}
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Stack direction="row" spacing={1}>
                              <Typography variant="h6" component="span">
                                {touchpoint.readable_name}
                              </Typography>
                              {touchpoint.automatic && <RefreshIcon sx={{ my: 'auto !important' }} />}
                            </Stack>
                            <Typography>{touchpoint.action || touchpoint.name}</Typography>
                          </TimelineContent>
                        </TimelineItem>
                      ))
                    }
                  </Timeline>
                </Stack>
              </Card>
            </Stack>}
          </Stack>
        </Grid>
      </Grid>
      <Dialog open={showTouchpoint} onClose={() => setShowTouchpoint(null)}>
        <Stack p={2}>
          <Touchpoint contact={contact} touchpoint={showTouchpoint} />
        </Stack>
      </Dialog>
    </Stack>
  )
}