import { create } from "zustand";
import { persist } from 'zustand/middleware';

const useTransactionStore = create(
  persist(
    (set) => ({
      transactions: [],
      setTransactions: (transactions) => set({ transactions }),
    }),
    {
      name: 'transactions-storage', // unique name
    }
  )
);

export default useTransactionStore;