import { createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_PDF_DOCUMENT_STATE,
  PDF_DOCUMENT_FETCHED
} from '../actions/pdfDocumentActions';

const initialState = {
  pdfDocument: null,
  pdfDocumentReady: false
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CLEAR_PDF_DOCUMENT_STATE, (state, action) => initialState)
    .addCase(PDF_DOCUMENT_FETCHED, (state, action) => {
      state.pdfDocument = action.payload.pdfDocument;
      state.pdfDocumentReady = true;
    });
});

export default reducer;
