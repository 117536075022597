export const LOG_REQUESTED = 'logs/log_requested';

// Used for single log
export const logRequest = (log) => ({
  type: LOG_REQUESTED,
  payload: {
    logs: log
  }
});

// Used for batch logs, logs is an array of log
export const logsRequest = (logs) => ({
  type: LOG_REQUESTED,
  payload: {
    logs: logs
  }
});
