import React from 'react';
import { getPdfDocuments } from '../shared/Network';

export default class PdfDocument extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfDocuments: [],
      name: '',
      url: '',
      primary: true,
      allowSearch: false
    };
  }

  componentDidMount() {
    getPdfDocuments().then((response) => {
      response.json().then((json) => {
        this.setState({ pdfDocuments: json });
      });
    });
  }

  getDocuments = () => {
    let elements = [];
    this.state.pdfDocuments.forEach((doc) => {
      elements.push(
        <div>
          <a
            key={doc.name}
            onClick={() => {
              this.props.setDocument(doc);
            }}
            href="#"
          >
            {doc.name}
          </a>
        </div>
      );
    });
    return elements;
  };

  saveDocument = () => {
    console.log('Saving document: ' + JSON.stringify(this.state));
  };

  render() {
    return (
      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            placeholder="Pdf Document Name"
            key="name"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
          <input
            type="text"
            placeholder="URL"
            key="url"
            value={this.state.url}
            onChange={(e) => this.setState({ url: e.target.value })}
          />
          <input
            type="checkbox"
            key="primary_doc"
            checked={this.state.primary}
            onChange={(e) => this.setState({ primary: e.target.checked })}
          />
          <input
            type="checkbox"
            key="allow_search"
            checked={this.state.allowSearch}
            onChange={(e) => this.setState({ allowSearch: e.target.checked })}
          />
          <button onClick={this.saveDocument}>Create</button>
        </form>
        <div>{this.getDocuments()}</div>
      </div>
    );
  }
}
