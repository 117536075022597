import React from 'react';

import { createBrowserRouter } from "react-router-dom";
import Transactions from "./pages/Transactions";
import Root from './pages/root';
import Review from './pages/Review';

const router = createBrowserRouter([
  {
    path: "/v2/compliances",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Transactions />
      },
      {
        path: "review",
        element: <Review />
      }
    ]
  }
]);

export default router;