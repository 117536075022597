import React from 'react';
import Login from '../shared/Login';
import Editor from './editor';
import { Provider } from 'react-redux';
import { store } from './redux/store';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false
    };
  }

  componentDidMount() {
    this.setState({ loggedIn: window.sessionStorage.getItem('auth') != null });
  }

  render() {
    if (this.state.loggedIn) {
      return (
        <Provider store={store}>
          <Editor />
        </Provider>
      );
    } else {
      return <Login setLoggedIn={() => this.setState({ loggedIn: true })} />;
    }
  }
}
