import { Controller } from 'stimulus';

/**
 * filterable{data_searchFilter_keyword: 'keyword'}: What a filterable is filtered by
 */
export default class extends Controller {
  static targets = ['input', 'filterable', 'noResultMessage'];

  connect() {
    this.inputTarget.addEventListener('input', this.filter.bind(this));
    this.noResultMessageTarget.classList.add('hide');
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.filter.bind(this));
  }

  filter(event) {
    const query = this.inputTarget.value
      .toLowerCase()
      .trim()
      .replace(/\s+/g, ' ');

    if (query === '') {
      this.filterableTargets.forEach((element) => {
        element.classList.remove('hide');
      });
      this.noResultMessageTarget.classList.add('hide');
      return;
    }

    // By default, assume we have matches.
    let hasMatches = false;

    this.filterableTargets.forEach((element) => {
      const keyword = element.dataset.searchfilterKeyword;
      if (keyword && keyword.toLowerCase().includes(query)) {
        element.classList.remove('hide');
        hasMatches = true;
      } else {
        element.classList.add('hide');
      }
    });

    // Toggle the "No items found" message based on the value of hasMatches
    if (hasMatches) {
      this.noResultMessageTarget.classList.add('hide');
    } else {
      this.noResultMessageTarget.classList.remove('hide');
    }
  }
}
