import { Controller } from '@hotwired/stimulus';
import autoScroll from 'dom-autoscroller';

export default class extends Controller {
  static targets = ['draggable'];
  dragging = false;

  connect() {
    this.setupAutoScroll();
    this.setupDragEventListeners();
  }

  disconnect() {
    if (this.autoScroller) {
      this.autoScroller.destroy();
    }
    this.removeDragEventListeners();
  }

  setupAutoScroll() {
    this.autoScroller = autoScroll(this.element, {
      margin: 20,
      maxSpeed: 10,
      scrollWhenOutside: true,
      autoScroll: () => {
        return this.dragging;
      }
    });
  }

  setupDragEventListeners() {
    document.addEventListener('mouseup', this.stopDrag.bind(this));
  }

  removeDragEventListeners() {
    document.removeEventListener('mouseup', this.stopDrag.bind(this));
  }

  startDrag() {
    this.dragging = true;
  }

  stopDrag() {
    this.dragging = false;
  }
}
