import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { emailSourceFetchRequested } from './redux/actions/emailSourceActions';
import '@nylas/components-email';

export default function MessageViewer(props) {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      emailSourceFetchRequested(props.emailSourceId, (json) => {
        console.log('TOKEN: ' + json.token);
        setToken(json.token);
        setLoading(false);
      })
    );
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  } else {
    return (
      <nylas-email
        id={props.emailComponentId}
        access_token={token}
        thread_id={props.threadId}
        show_expanded_email_view_onload={true}
      >
        {' '}
      </nylas-email>
    );
  }
}
