import { combineReducers } from 'redux';

import pdfDocument from './pdfDocumentReducer';
import pdfOverlay from './pdfOverlayReducer';
import contact from './contactReducer';
import signature from './signaturesReducer';

const appReducer = combineReducers({
  pdfDocument,
  pdfOverlay,
  contact,
  signature
});

export default appReducer;
